import {
  ADD_BOX_EVENT_TRIGGER,
  BOX_EVENT_TRIGGERS_REQUEST_FAILURE,
  BOX_EVENT_TRIGGERS_REQUEST_SUCCESS,
  DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
  RESET_BOX_EVENT_TRIGGERS,
  SAVE_BOX_EVENT_TRIGGER_REQUEST,
  SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE,
  SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
  SET_HIGHLIGHTED_BOX_EVENT_TRIGGER,
  SET_SELECTED_BOX_EVENT_TRIGGER,
  UPDATE_BOX_EVENT_TRIGGER
} from '../actions/actionTypes'

import { CrossingLineEventTrigger } from '../../types/crossingLine'
import { HeatMapEventTrigger } from '../../types/heatMap'
import { RegionOfInterestEventTrigger } from '../../types/regionOfInterest'
import { trimObjectValues } from '../../helpers'
import { union } from 'lodash'
import { VirtualDoorEventTrigger } from '../../types/virtualDoor'
import { OriginDestinationZone } from '../../types/originDestinationZone'
import { AnprEventTrigger } from '../../types/anpr'

const initialState = {
  allIds: [],
  byIds: {},
  isSubmitting: false,
  selectedEventTrigger: null,
  highlightedEventTrigger: null
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case RESET_BOX_EVENT_TRIGGERS:
      return { ...initialState }

    case ADD_BOX_EVENT_TRIGGER:
      const newEventTrigger = action.payload
      const obj = { [newEventTrigger.localId]: newEventTrigger }

      return {
        ...state,
        allIds: union(state.allIds, [newEventTrigger.localId]),
        byIds: Object.assign({}, state.byIds, obj),
        selectedEventTrigger: newEventTrigger.localId,
        highlightedEventTrigger: newEventTrigger.localId
      }

    case UPDATE_BOX_EVENT_TRIGGER:
      const allEventTriggers = Object.assign({}, state.byIds)
      const eventTriggerToUpdate =
        allEventTriggers[action.payload.eventTrigger.localId]

      allEventTriggers[eventTriggerToUpdate.localId] = Object.assign(
        eventTriggerToUpdate,
        trimObjectValues(action.payload.updates),
        {
          hasChanged: true
        }
      )

      return {
        ...state,
        byIds: allEventTriggers
      }

    case SET_SELECTED_BOX_EVENT_TRIGGER:
      return {
        ...state,
        highlightedEventTrigger: action.payload ? action.payload.localId : null,
        selectedEventTrigger: action.payload ? action.payload.localId : null
      }

    case SET_HIGHLIGHTED_BOX_EVENT_TRIGGER:
      return {
        ...state,
        highlightedEventTrigger: action.payload ? action.payload.localId : null
      }

    case BOX_EVENT_TRIGGERS_REQUEST_SUCCESS:
      const fetchedTriggers =
        action.response.entities.eventTriggers[action.response.result]
      let eventTriggers = {}

      if (!fetchedTriggers) {
        return { ...initialState }
      }

      if (fetchedTriggers.crossingLines) {
        fetchedTriggers.crossingLines.forEach((cl) => {
          eventTriggers[cl.id] = new CrossingLineEventTrigger(cl)
        })
      }

      if (fetchedTriggers.regionOfInterests) {
        fetchedTriggers.regionOfInterests.forEach((roi) => {
          eventTriggers[roi.id] = new RegionOfInterestEventTrigger(roi)
        })
      }

      if (fetchedTriggers.originDestinationZones) {
        fetchedTriggers.originDestinationZones.forEach((od) => {
          eventTriggers[od.id] = new OriginDestinationZone(od)
        })
      }

      if (fetchedTriggers.virtualDoors) {
        fetchedTriggers.virtualDoors.forEach((vd) => {
          eventTriggers[vd.id] = new VirtualDoorEventTrigger(vd)
        })
      }

      if (fetchedTriggers.heatMap) {
        eventTriggers['heatMap'] = new HeatMapEventTrigger(
          fetchedTriggers.heatMap
        )
      }

      if (fetchedTriggers.anpr) {
        eventTriggers['anpr'] = new AnprEventTrigger(fetchedTriggers.anpr)
      }

      return {
        ...state,
        allIds: Object.keys(eventTriggers),
        byIds: Object.assign({}, eventTriggers)
      }

    case BOX_EVENT_TRIGGERS_REQUEST_FAILURE:
      return { ...initialState }

    case SAVE_BOX_EVENT_TRIGGER_REQUEST:
      return {
        ...state,
        isSubmitting: true
      }

    case SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS:
      const trigger = action.response
      const triggers = Object.assign({}, state.byIds)
      triggers[trigger.localId] = trigger

      return {
        ...state,
        allIds: union(state.allIds, [trigger.localId]),
        byIds: triggers,
        isSubmitting: false
      }

    case SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE:
      return {
        ...state,
        isSubmitting: false
      }

    case DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS:
      const deletedEventTriggerId = action.response.id
      const newState = {
        ...state,
        allIds: state.allIds.filter((id) => deletedEventTriggerId !== id)
      }

      delete newState.byIds[deletedEventTriggerId]
      return newState

    default:
      return state
  }
}
export default fn
