import {
  DELETE_SCENE_REQUEST_SUCCESS,
  LIST_SCENES_CLINES_REQUEST_SUCCESS,
  LIST_SCENES_REGIONS_REQUEST_SUCCESS,
  LIST_SCENES_REQUEST_SUCCESS,
  LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS,
  SAVE_SCENE_REQUEST_SUCCESS
} from '../actions/actionTypes'
import {
  ICrossinglineInfo,
  IRegionOfInterestInfo,
  IScene
} from '../../types/scene'

const initialState = {
  all: [],
  byId: {},
  byGroup: {},
  clinesPerScene: {},
  speedlinesPerScene: {},
  regionsPerScene: {},
  loading: true
}

const getByGroup = (all) => {
  const byGroup = {}
  all.forEach((scene) => {
    let groupId = scene.group ? scene.group.id : 'default'
    let current = byGroup[groupId]
    if (current) {
      current.push(scene)
    } else {
      current = [scene]
    }
    byGroup[groupId] = current
  })
  return byGroup
}
const fn = function (state = initialState, action) {
  let newState
  switch (action.type) {
    case LIST_SCENES_REQUEST_SUCCESS:
      const all: IScene[] = []
      const byId = {}
      action.response.forEach((item) => {
        let scene: IScene = item
        all.push(scene)
        byId[scene.id] = scene
      })
      newState = {
        ...state,
        all: all,
        byId: byId,
        byGroup: getByGroup(all),
        loading: false
      }
      return Object.assign({}, state, newState)

    case SAVE_SCENE_REQUEST_SUCCESS:
      let existingAll = { ...state.all }
      let updatedById = { ...state.byId }

      const updatedAll: IScene[] = []
      let scene: IScene = action.response.entities.scene

      let id = Object.keys(scene)[0]
      let added = false
      for (var key in Object.keys(existingAll)) {
        let existingScene: IScene = existingAll[key]
        if (existingScene.id in scene) {
          updatedAll.push(scene[id])
          updatedById[id] = scene[id]
          added = true
        } else {
          updatedAll.push(existingScene)
        }
      }
      if (!added) {
        updatedAll.push(scene[id])
        updatedById[id] = scene[id]
      }

      return {
        ...state,
        all: updatedAll,
        byId: updatedById,
        byGroup: getByGroup(updatedAll)
      }

    case DELETE_SCENE_REQUEST_SUCCESS:
      const deletedSceneId = action.response.id
      let filteredById = { ...state.byId }
      delete filteredById[deletedSceneId]

      const filteredAll: IScene[] = []
      for (var sceneKey in Object.keys(state.all)) {
        let existingScene: IScene = state.all[sceneKey]
        if (existingScene.id !== deletedSceneId) {
          filteredAll.push(existingScene)
        }
      }

      newState = {
        ...state,
        all: filteredAll,
        byId: filteredById,
        byGroup: getByGroup(filteredAll)
      }
      return Object.assign({}, state, newState)

    case LIST_SCENES_CLINES_REQUEST_SUCCESS:
      let clinesPerScene = { ...state.clinesPerScene }
      let result: ICrossinglineInfo[] = []
      for (let key in Object.keys(action.response)) {
        let item = action.response[key]
        if (item && typeof item !== 'string') {
          result.push(item)
        }
      }
      clinesPerScene[action.response.id] = result
      newState = {
        ...state,
        clinesPerScene: clinesPerScene
      }
      return Object.assign({}, state, newState)

    case LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS:
      let speedlinesPerScene = { ...state.speedlinesPerScene }
      let speedlinesResult: ICrossinglineInfo[] = []
      for (let key in Object.keys(action.response)) {
        let item = action.response[key]
        if (item && typeof item !== 'string') {
          speedlinesResult.push(item)
        }
      }
      speedlinesPerScene[action.response.id] = speedlinesResult
      newState = {
        ...state,
        speedlinesPerScene: speedlinesPerScene
      }
      return Object.assign({}, state, newState)

    case LIST_SCENES_REGIONS_REQUEST_SUCCESS:
      let regionsPerScene = { ...state.regionsPerScene }
      let regionResult: IRegionOfInterestInfo[] = []
      for (let key in Object.keys(action.response)) {
        let item = action.response[key]
        if (item && typeof item !== 'string') {
          regionResult.push(item)
        }
      }
      regionsPerScene[action.response.id] = regionResult
      newState = {
        ...state,
        regionsPerScene: regionsPerScene
      }
      return Object.assign({}, state, newState)

    default:
      return state
  }
}
export default fn
