import { FormLabel, Tooltip } from 'carbon-components-react'

import React from 'react'
import { useTranslation } from 'react-i18next'

interface IFormLabelWithTooltipProps {
  id: string
}

const FormLabelWithTooltip: React.FC<IFormLabelWithTooltipProps> = ({ id }) => {
  const { t } = useTranslation()
  const labelKey = `${id}.labelText`
  const tooltipTitleKey = `${id}.tooltipTitle`
  const tooltipTextKey = `${id}.tooltipText`

  const tooltipText = t(tooltipTextKey)

  return (
    <FormLabel>
      <Tooltip
        triggerText={t(labelKey)}
        direction="bottom"
        menuOffset={{ left: -133, top: 8 }}
        tabIndex={-1}
      >
        <h4 className="scc--tooltip--title">{t(tooltipTitleKey)}</h4>
        <p
          className="scc--tooltip--text"
          dangerouslySetInnerHTML={{
            __html: tooltipText
          }}
        ></p>
      </Tooltip>
    </FormLabel>
  )
}

export default FormLabelWithTooltip
