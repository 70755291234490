import React, { useEffect, useState } from 'react'
import { Card, Space } from 'antd'
import { EWidgetType } from '../../../../types/solution'
import SelectChartType from '../QueryBuilderComponents/SelectChartType'
import { ICrossinglineInfo, IScene } from '../../../../types/scene'
import { useTranslation, withTranslation } from 'react-i18next'
import { TCubeDimension, TCubeMeasure } from '@cubejs-client/core'
import { connect } from 'react-redux'
import { listCrossinglinesInScene } from '../../../../redux/actions/scenes'
import MemberGroup from '../../../../datadiscovery/components/QueryBuilder/MemberGroup'
import ChartRenderer from '../../../../datadiscovery/components/ChartRenderer'
import GranularityFilter from '../QueryBuilderComponents/GranularityFilter'
import SegmentationFilter from '../QueryBuilderComponents/SegmentationFilter'
import FilterData from '../QueryBuilderComponents/FilterData'
import { compareElements, handleElementChange } from './QueryBuilderUtility'

interface IGenericQueryBuilder {
  crossingLinesInScene: ICrossinglineInfo[]
  listCrossinglinesInScene: Function
  scene: IScene
  setValidQuery: Function
  widgetType: EWidgetType | undefined
  setWidgetType: Function
  isQueryPresent: any
  validatedQuery: any
  timeDimensions: any
  availableTimeDimensions: any
  updateTimeDimensions: any
  dimensions: any
  availableDimensions: any
  updateDimensions: any
  measures: any
  availableMeasures: any
  updateMeasures: any
  chartType: any
  updateChartType: any
  timeFilter: string
  segments: any
  availableSegments: any
  updateSegments: any
  filters: any
  updateFilters: any
  updateQuery: any
}

const GenericQueryBuilder: React.FC<IGenericQueryBuilder> = (props) => {
  const { t } = useTranslation()
  let [newDimensions, setNewDimensions] = useState<string[]>([])

  const {
    chartType,
    measures,
    availableMeasures,
    updateMeasures,
    dimensions,
    availableDimensions,
    updateDimensions,
    filters,
    updateFilters,
    timeDimensions,
    updateTimeDimensions,
    setValidQuery,
    isQueryPresent,
    validatedQuery
  } = props

  useEffect(() => {
    setValidQuery(isQueryPresent && Object.keys(validatedQuery).length > 0)
  }, [isQueryPresent, validatedQuery, setValidQuery])

  useEffect(() => {
    // set current dimensions if widget is edited
    if (newDimensions && !compareElements(dimensions, newDimensions)) {
      setNewDimensions(
        dimensions.map((item) => {
          return item.name
        })
      )
    }
  }, [filters, measures, dimensions, newDimensions])

  const measuresGroups: string[] = measures.map((m) =>
    m.name.substr(0, m.name.indexOf('.'))
  )

  const measureFilterPredicate = (value: TCubeMeasure) => {
    if (measures.map((m) => m.name).includes(value.name)) {
      return false
    }
    // do not show parking measures
    if (
      value.name.includes('ParkingAnalyticsEvents') ||
      value.name.includes('ParkingUtilization')
    ) {
      return false
    }
    //if some measure has been selected already, only allow other roi measures for now
    return !(
      measuresGroups.length > 0 &&
      !measuresGroups.includes(value.name.substr(0, value.name.indexOf('.')))
    )
  }

  const filteredMeasures: TCubeMeasure[] = availableMeasures.filter(
    measureFilterPredicate
  )

  const filteredDimensions: TCubeDimension[] = availableDimensions.filter(
    (dim) => {
      return (
        measuresGroups.includes(dim.name.substr(0, dim.name.indexOf('.'))) &&
        !dim.name.endsWith('Id')
      )
    }
  )

  const handleNewDimensionChange = (data) => {
    setNewDimensions(data)
    handleElementChange(availableDimensions, dimensions, updateDimensions, data)
  }

  const typeFilteredFilters = props.filters.filter(
    (filter) => filter.member.split('.')[1] !== 'streamId'
  )

  let previewQuery =
    props.isQueryPresent &&
    Object.keys(props.validatedQuery).length &&
    Object.assign({}, props.validatedQuery)

  // add scene stream filters to preview query
  if (previewQuery) {
    const streamFilters: any = previewQuery.filters || []
    measures.forEach((measure) => {
      let filterId = `${measure['name'].split('.')[0]}.streamId`
      if (
        streamFilters.find((item) => {
          return item.member === filterId
        })
      ) {
        return
      }
      streamFilters.push({
        member: filterId,
        operator: 'equals',
        values: props.scene.boxStreams.map((stream) => stream.streamId)
      })
    })
    previewQuery.filters = streamFilters
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card title={t('solutions.scenes.overview.add.measure.title')}>
        <MemberGroup
          members={measures}
          availableMembers={filteredMeasures}
          addMemberName={t('solutions.scenes.overview.add.measure.title')}
          updateMethods={updateMeasures}
        />
      </Card>

      {measures.length > 0 && (
        <Card title={t('solutions.scenes.overview.add.data.title')}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <p>
              {t('solutions.scenes.overview.add.data.dimensionexplanation')}
            </p>

            <GranularityFilter
              timeDimensions={timeDimensions}
              updateTimeDimension={updateTimeDimensions}
            />
            <SegmentationFilter
              key={dimensions}
              segmentationDimensions={filteredDimensions}
              dimensions={newDimensions}
              handleDimensionChange={handleNewDimensionChange}
            />
            <FilterData
              filters={typeFilteredFilters}
              updateFilters={updateFilters}
              filteredDimensions={filteredDimensions}
            />
          </Space>
        </Card>
      )}

      {measures.length > 0 && (
        <SelectChartType
          chartType={chartType}
          updateChartType={props.updateChartType}
        />
      )}

      {previewQuery && (
        <Card className="scc--solutions--add-widget-preview">
          <ChartRenderer
            vizState={{
              query: previewQuery,
              chartType
            }}
            overrideTimeZone={props.scene?.location?.timezone}
          />
        </Card>
      )}
    </Space>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    crossingLinesInScene: state.scenes.clinesPerScene[ownProps.scene.id]
  }
}

export default connect(mapStateToProps, { listCrossinglinesInScene })(
  withTranslation()(GenericQueryBuilder)
)
