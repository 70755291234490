import React, { useState } from 'react'
import { Button, Col, Form, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ESolutionType, EWidgetType } from '../../../types/solution'
import TrafficQueryBuilder from './QueryBuilder/TrafficQueryBuilder'
import ParkingQueryBuilder from './QueryBuilder/ParkingQueryBuilder'
import { VizState } from '@cubejs-client/react'
import * as PropTypes from 'prop-types'
import StateChangeHeuristics from '../../../datadiscovery/components/QueryBuilder/StateChangeHeuristics'
import { useMutation } from '@apollo/react-hooks'
import {
  CREATE_DASHBOARD_ITEM,
  UPDATE_DASHBOARD_ITEM
} from '../../../datadiscovery/graphql/mutations'
import { GET_DASHBOARD_ITEMS_SCENE } from '../../../datadiscovery/graphql/queries'
import QueryBuilderBase from '../../../datadiscovery/components/QueryBuilder/QueryBuilderBase'
import GenericQueryBuilder from './QueryBuilder/GenericQueryBuilder'

const CreateWidgetModal = ({
  scene,
  show,
  setShow,
  widget,
  setWidget,
  cubejsApi,
  timeFilter,
  rerenderCallback
}) => {
  const { t } = useTranslation()
  let [widgetType, setWidgetType] = useState<EWidgetType | undefined>(
    widget ? EWidgetType[widget.widgetType] : undefined
  )
  const [vizState, setVizState] = useState<VizState | undefined>(
    widget ? widget.vizState : undefined
  )
  const [validQuery, setValidQuery] = useState<boolean>(false)

  let widgetNameInput
  let [newWidgetName, setNewWidgetName] = useState<string>(
    widget ? widget.name : ''
  )
  const [invalidWidgetName, setInvalidWidgetName] = useState(false)

  const [addDashboardItem] = useMutation(CREATE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS_SCENE,
        variables: {
          sceneId: scene.id
        }
      }
    ]
  })
  const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS_SCENE,
        variables: {
          sceneId: scene.id
        }
      }
    ]
  })

  const handleCancel = () => {
    setShow(false)
    setWidget(null)
  }

  const handleSave = async () => {
    if (!newWidgetName || !newWidgetName.length) {
      widgetNameInput.focus()
      setInvalidWidgetName(true)
      return
    }
    setInvalidWidgetName(false)
    await (widget ? updateDashboardItem : addDashboardItem)({
      variables: {
        id: widget ? widget.id : null,
        input: {
          vizState: JSON.stringify(vizState),
          name: newWidgetName,
          sceneId: scene.id,
          widgetType: widgetType ? widgetType : EWidgetType.Generic
        }
      }
    })
    setShow(false)
    setWidget(null)
    rerenderCallback()
  }

  const handleWidgetNameChange = (event) => {
    setNewWidgetName(event.target.value)
    if (!event.target.value || !event.target.value.length) {
      setInvalidWidgetName(true)
    } else {
      setInvalidWidgetName(false)
    }
  }
  return (
    <Modal
      title={
        <Col span={12}>
          <Form.Item validateStatus={invalidWidgetName ? 'error' : ''}>
            <Input
              size="large"
              placeholder={t('solutions.scenes.overview.add.name')}
              value={newWidgetName}
              onChange={handleWidgetNameChange}
              ref={(input) => {
                widgetNameInput = input
              }}
            />
          </Form.Item>
        </Col>
      }
      visible={show}
      onCancel={handleCancel}
      width={1000}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('solutions.scenes.overview.add.cancel')}
        </Button>,
        <Button
          type="primary"
          key="save"
          onClick={handleSave}
          disabled={!validQuery}
        >
          {t('solutions.scenes.overview.add.save')}
        </Button>
      ]}
    >
      <p>{t('solutions.scenes.overview.add.headline')}</p>
      <QueryBuilderBase
        vizState={vizState}
        setVizState={setVizState}
        stateChangeHeuristics={StateChangeHeuristics}
        cubejsApi={cubejsApi}
        render={({
          isQueryPresent,
          measures,
          availableMeasures,
          updateMeasures,
          dimensions,
          updateDimensions,
          availableDimensions,
          timeDimensions,
          availableTimeDimensions,
          updateTimeDimensions,
          chartType,
          updateChartType,
          validatedQuery,
          segments,
          availableSegments,
          updateSegments,
          filters,
          updateFilters,
          updateQuery
        }) => {
          return (
            <>
              {scene.solutionType === ESolutionType.TRAFFIC && (
                <TrafficQueryBuilder
                  isQueryPresent={isQueryPresent}
                  validatedQuery={validatedQuery}
                  timeDimensions={timeDimensions}
                  availableTimeDimensions={availableTimeDimensions}
                  updateTimeDimensions={updateTimeDimensions}
                  dimensions={dimensions}
                  updateDimensions={updateDimensions}
                  availableDimensions={availableDimensions}
                  measures={measures}
                  availableMeasures={availableMeasures}
                  updateMeasures={updateMeasures}
                  chartType={chartType}
                  updateChartType={updateChartType}
                  timeFilter={timeFilter}
                  filters={filters}
                  updateFilters={updateFilters}
                  scene={scene}
                  setValidQuery={setValidQuery}
                  widgetType={widgetType}
                  setWidgetType={setWidgetType}
                  updateQuery={updateQuery}
                />
              )}
              {scene.solutionType === ESolutionType.PARKING && (
                <ParkingQueryBuilder
                  isQueryPresent={isQueryPresent}
                  validatedQuery={validatedQuery}
                  timeDimensions={timeDimensions}
                  availableTimeDimensions={availableTimeDimensions}
                  updateTimeDimensions={updateTimeDimensions}
                  dimensions={dimensions}
                  updateDimensions={updateDimensions}
                  availableDimensions={availableDimensions}
                  measures={measures}
                  availableMeasures={availableMeasures}
                  updateMeasures={updateMeasures}
                  chartType={chartType}
                  updateChartType={updateChartType}
                  segments={segments}
                  availableSegments={availableSegments}
                  updateSegments={updateSegments}
                  timeFilter={timeFilter}
                  filters={filters}
                  updateFilters={updateFilters}
                  scene={scene}
                  setValidQuery={setValidQuery}
                  widgetType={widgetType}
                  setWidgetType={setWidgetType}
                  updateQuery={updateQuery}
                />
              )}
              {scene.solutionType === ESolutionType.GENERIC && (
                <GenericQueryBuilder
                  isQueryPresent={isQueryPresent}
                  validatedQuery={validatedQuery}
                  timeDimensions={timeDimensions}
                  availableTimeDimensions={availableTimeDimensions}
                  updateTimeDimensions={updateTimeDimensions}
                  dimensions={dimensions}
                  updateDimensions={updateDimensions}
                  availableDimensions={availableDimensions}
                  measures={measures}
                  availableMeasures={availableMeasures}
                  updateMeasures={updateMeasures}
                  chartType={chartType}
                  updateChartType={updateChartType}
                  segments={segments}
                  availableSegments={availableSegments}
                  updateSegments={updateSegments}
                  timeFilter={timeFilter}
                  filters={filters}
                  updateFilters={updateFilters}
                  scene={scene}
                  setValidQuery={setValidQuery}
                  widgetType={widgetType}
                  setWidgetType={setWidgetType}
                  updateQuery={updateQuery}
                />
              )}
            </>
          )
        }}
      />
    </Modal>
  )
}

CreateWidgetModal.propTypes = {
  cubejsApi: PropTypes.object
}

CreateWidgetModal.defaultProps = {
  cubejsApi: null
}

export default CreateWidgetModal
