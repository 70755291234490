import './BoxDetailsHeader.scss'

import { IBox } from '../../types/box'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  PoweroffOutlined
} from '@ant-design/icons'
import { Button, Input } from 'antd'
import { Col } from 'antd/lib/grid'
import { saveBoxDetails } from '../../redux/actions/boxDetails'
import { useDispatch } from 'react-redux'
import { notify } from '../../services/notify'
import { EConnectionState } from '../../types/boxStatus'
import DeviceLogViewerModal from '../DeviceLogViewer/DeviceLogViewerModal'
import copy from 'copy-to-clipboard'
import { RebootConfirmationDialog } from '../StreamConfigurationConfirmationDialog/RebootConfirmationDialog'
import { rebootBox } from '../../redux/actions/boxes'
import { useHistory } from 'react-router-dom'

interface IBoxDetailsHeaderProps {
  box: IBox
}

const BoxDetailsHeader: React.FC<IBoxDetailsHeaderProps> = ({ box }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  let boxConnected: boolean = false
  if (
    box.boxStatus &&
    box.boxStatus.connectionState === EConnectionState.connected
  ) {
    boxConnected = true
  }

  let [editMode, setEditMode] = useState(false)
  let [isModalVisible, setModalVisible] = useState(false)
  let [isRebootDialogVisible, setRebootDialogVisible] = useState(false)
  let [newDeviceName, setNewDeviceName] = useState<string>(
    box.name ? box.name : ''
  )
  const history = useHistory()

  const handleDeviceNameChange = (event) => {
    setNewDeviceName(event.target.value)
  }
  const handleDeviceNameKey = (event) => {
    if (event.key === 'Enter') {
      saveDeviceName()
    }
  }
  const editDeviceName = (event) => {
    setEditMode(true)
  }
  const cancelDeviceName = (event) => {
    setEditMode(false)
  }
  const saveDeviceName = async () => {
    box.name = newDeviceName
    await dispatch(
      saveBoxDetails(box.id, {
        boxConfig: box
      })
    )
    setEditMode(false)
    notify({
      title: t('notification.configuration.saved.title'),
      message: t('notification.configuration.saved.message')
    })
  }

  const cssHeadlineClasses = box.name
    ? 'scc--boxdetail__header--boxname'
    : 'scc--boxdetail__header--boxname scc--boxdetail__header--boxname__notset'

  const onCloseDialog = (event) => {
    setRebootDialogVisible(false)
  }
  const onRebootDevice = async () => {
    dispatch(rebootBox(box))
    notify({
      title: t('notification.reboot.success.title'),
      message: t('notification.reboot.success.message')
    })
    history.push('/boxes')
  }

  return (
    <div className="scc--boxdetail__header">
      {editMode ? (
        <>
          <Col span={15}>
            <Input
              autoFocus
              size="large"
              placeholder={t(
                'configuration.group.device.deviceNamePlaceholder'
              )}
              value={newDeviceName}
              onChange={handleDeviceNameChange}
              onKeyPress={handleDeviceNameKey}
            />
            <Button
              onClick={cancelDeviceName}
              className="scc--solutions--button"
            >
              {t('solutions.groups.cancelGroupButton')}
            </Button>
            <Button
              onClick={saveDeviceName}
              className="scc--solutions--button scc--solutions--save-group-button"
              type="primary"
              style={{
                width: 160
              }}
            >
              {t('configuration.group.device.saveDeviceNameButton')}
            </Button>
          </Col>
        </>
      ) : (
        <h2 className={cssHeadlineClasses}>
          {box.name || t('boxList.dataTable.empty.deviceName')}
          <Button
            icon={<EditOutlined />}
            className="scc--solutions--edit-group-button"
            onClick={editDeviceName}
          />
          <Button
            icon={<DownloadOutlined />}
            className="scc--boxdetail__header--retrieveLogs-button"
            disabled={!boxConnected}
            onClick={() => {
              setModalVisible(true)
            }}
          >
            {t('configuration.deviceLogs.retrieveLogs')}
          </Button>
          <Button
            icon={<PoweroffOutlined />}
            className="scc--boxdetail__header--rebootDevice-button"
            disabled={!boxConnected}
            onClick={() => {
              setRebootDialogVisible(true)
            }}
          >
            {t('configuration.rebootDevice.buttonText')}
          </Button>
        </h2>
      )}
      <DeviceLogViewerModal
        visibleState={[isModalVisible, setModalVisible]}
        boxId={box.id}
      />
      <div className="scc--boxdetail__header--boxId">
        <span>{t('configuration.group.device.deviceId', { id: box.id })}</span>
        <Button
          icon={<CopyOutlined />}
          className="scc--boxdetail--copyid-button"
          onClick={() => {
            copy(box.id)
          }}
        />
      </div>
      <RebootConfirmationDialog
        onRequestClose={onCloseDialog}
        onRequestSubmit={onRebootDevice}
        open={isRebootDialogVisible}
      />
    </div>
  )
}

export default BoxDetailsHeader
