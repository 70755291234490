import {
  FormGroup,
  FormLabel,
  NumberInput,
  Select,
  SelectItem,
  TextInput,
  Tile
} from 'carbon-components-react'
import { CaretLeftGlyph, CaretRightGlyph } from '@carbon/icons-react'

import { CrossingLineEventTrigger } from '../../types/crossingLine'
import { EBoxModel } from '../../types/boxModel'
import { EEventTriggerType } from '../../types/eventTrigger'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ERegionOfInterestTriggerType,
  ERegionOfInterestType
} from '../../types/regionOfInterest'
import { Menu, Button, Space, Radio, Switch } from 'antd'
import {
  CarOutlined,
  ClockCircleOutlined,
  SettingOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { Config } from '../../services/config'

const { SubMenu } = Menu

const excludeSupport = Config.EXCLUDE_SUPPORT

const TriggerSettings = ({
  eventTrigger,
  onToggleSpeedEstimate,
  onValueChanged,
  personModelSelected,
  onToggleRoiType,
  onToggleRoiTrigger,
  onChangeRoiCapacity,
  onToggleDirection,
  heatMap,
  onToggleHeatmap,
  shouldShowAnpr,
  anpr,
  onToggleAnpr,
  anprStorageDurationChanged,
  errors,
  register
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <Space direction={'vertical'} size="large" style={{ width: '100%' }}>
        {eventTrigger &&
          eventTrigger.objectType === EEventTriggerType.regionOfInterest && (
            <>
              <div>
                <FormLabelWithTooltip id="draw.form.roi.name" />
                <TextInput
                  id="draw.form.roi.name"
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalid={false}
                  invalidText={t('draw.form.invalidText')}
                  labelText={false}
                  placeholder={t('draw.form.roi.name.placeholder')}
                  name="eventTrigger.name"
                  defaultValue={eventTrigger ? eventTrigger.name : ''}
                  onChange={onValueChanged}
                  ref={register}
                />
              </div>

              {!personModelSelected && (
                <div>
                  <FormLabelWithTooltip id="draw.form.roi.type" />
                  <Radio.Group
                    value={eventTrigger.roiType}
                    onChange={onToggleRoiType}
                    aria-label="RoI Type Toggle"
                    id="draw.form.roi.type"
                  >
                    <Radio.Button value={ERegionOfInterestType.generic}>
                      {t('draw.form.roi.type.values.generic')}
                    </Radio.Button>
                    <Radio.Button value={ERegionOfInterestType.parking}>
                      {t('draw.form.roi.type.values.parking')}
                    </Radio.Button>
                  </Radio.Group>
                </div>
              )}

              <div>
                <FormLabelWithTooltip id="draw.form.roi.triggerAction" />
                <Radio.Group
                  value={eventTrigger.trigger}
                  onChange={onToggleRoiTrigger}
                  aria-label="RoI Trigger Toggle"
                  id="draw.form.roi.triggerAction"
                  disabled={
                    eventTrigger &&
                    eventTrigger.roiType === ERegionOfInterestType.parking
                  }
                >
                  <Radio.Button value={ERegionOfInterestTriggerType.time}>
                    {t('draw.form.roi.triggerAction.values.time')}
                  </Radio.Button>
                  <Radio.Button value={ERegionOfInterestTriggerType.change}>
                    {t('draw.form.roi.triggerAction.values.change')}
                  </Radio.Button>
                </Radio.Group>
              </div>

              <div>
                <div>
                  <FormLabelWithTooltip id="draw.form.roi.capacity" />
                  <NumberInput
                    id={'draw.form.roi.capacity'}
                    invalidText=""
                    className="bx--number-input"
                    allowEmpty={false}
                    min={1}
                    max={100}
                    placeholder={t('draw.form.roi.capacity.placeholder')}
                    value={eventTrigger && eventTrigger.capacity}
                    onChange={onChangeRoiCapacity}
                  />
                </div>
              </div>
            </>
          )}

        {eventTrigger &&
          eventTrigger.objectType === EEventTriggerType.crossingLine && (
            <>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.name" />
                <TextInput
                  id="draw.form.cl.name"
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalid={false}
                  invalidText={t('draw.form.invalidText')}
                  labelText={false}
                  placeholder={t('draw.form.crossingLine.name.placeholder')}
                  name="eventTrigger.name"
                  defaultValue={eventTrigger ? eventTrigger.name : ''}
                  ref={register}
                  onChange={onValueChanged}
                />
              </div>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.direction" />
                {directionToggle(eventTrigger, onToggleDirection, t)}
              </div>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.speed" />
                <Switch
                  checkedChildren={t('draw.form.crossingLine.speed.on')}
                  unCheckedChildren={t('draw.form.crossingLine.speed.off')}
                  checked={
                    eventTrigger.speedEstimation &&
                    eventTrigger.speedEstimation.enabled
                  }
                  onChange={onToggleSpeedEstimate}
                />
                {eventTrigger.speedEstimation &&
                  eventTrigger.speedEstimation.enabled && (
                    <div>
                      <FormLabelWithTooltip id="draw.form.crossingLine.speed.distance" />
                      <TextInput
                        id={'draw.form.crossingLine.speed.distance'}
                        invalidText={t(
                          'draw.form.crossingLine.speed.distance.invalidText'
                        )}
                        className="bx--number-input"
                        name="eventTrigger.speedEstimation.distance"
                        defaultValue={
                          eventTrigger.speedEstimation
                            ? eventTrigger.speedEstimation.distance
                            : 1
                        }
                        invalid={
                          errors['eventTrigger'] &&
                          errors['eventTrigger']['speedEstimation'] &&
                          errors['eventTrigger']['speedEstimation']['distance']
                        }
                        onChange={onValueChanged}
                        ref={register({
                          required: true,
                          pattern: /^[+]?(\d+)?[0-9](\d+)?(\.\d+)?$/
                        })}
                      />
                    </div>
                  )}
              </div>

              {!personModelSelected && (
                <ANPRSelection
                  shouldShowAnpr={shouldShowAnpr}
                  anpr={anpr}
                  onToggleAnpr={onToggleAnpr}
                  anprStorageDurationChanged={anprStorageDurationChanged}
                  register={register}
                />
              )}
            </>
          )}

        {eventTrigger &&
          eventTrigger.objectType === EEventTriggerType.virtualDoor && (
            <div>
              <FormLabelWithTooltip id="draw.form.door.name" />
              <TextInput
                id="draw.form.door.name"
                className="scc--formfield__value-set"
                disabled={false}
                invalid={false}
                labelText={false}
                placeholder={t('draw.form.door.name.placeholder')}
                name="eventTrigger.name"
                defaultValue={eventTrigger ? eventTrigger.name : ''}
                onChange={onValueChanged}
                ref={register}
              />
            </div>
          )}

        {eventTrigger &&
          eventTrigger.objectType ===
            EEventTriggerType.originDestinationZone && (
            <div>
              <FormLabelWithTooltip id="draw.form.zone.name" />
              <TextInput
                id="draw.form.zone.name"
                className="scc--formfield__value-set"
                disabled={false}
                invalid={false}
                labelText={false}
                placeholder={t('draw.form.zone.name.placeholder')}
                name="eventTrigger.name"
                defaultValue={eventTrigger ? eventTrigger.name : ''}
                onChange={onValueChanged}
                ref={register}
              />
            </div>
          )}
        <FormGroup className="scc--device-config--title">
          <FormLabelWithTooltip id="draw.form.heatMap.tooltip" />
          <Switch
            checkedChildren={t('draw.form.heatMap.toggle.on')}
            unCheckedChildren={t('draw.form.heatMap.toggle.off')}
            checked={heatMap && heatMap.enabled}
            onChange={onToggleHeatmap}
          />
        </FormGroup>
      </Space>
    </div>
  )
}

const ANPRSelection = ({
  shouldShowAnpr,
  anpr,
  onToggleAnpr,
  anprStorageDurationChanged,
  register
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {shouldShowAnpr && (
        <>
          <FormLabelWithTooltip id="draw.form.anpr.tooltip" />
          <Switch
            checkedChildren={t('draw.form.anpr.toggle.on')}
            unCheckedChildren={t('draw.form.anpr.toggle.off')}
            checked={anpr && anpr.enabled}
            onChange={onToggleAnpr}
          />
        </>
      )}
      {anpr && anpr.enabled && (
        <div>
          <FormLabelWithTooltip id="draw.form.anpr.anprStorageTime.tooltip" />
          <NumberInput
            id={'draw.form.anpr.anprStorageTime'}
            invalidText=""
            className="bx--number-input"
            allowEmpty={true}
            min={1}
            placeholder={t('draw.form.anpr.anprStorageTime.placeholder')}
            name="anpr.anprStorageTime"
            value={anpr?.anprStorageTime ? anpr.anprStorageTime : ''}
            onChange={anprStorageDurationChanged}
            ref={register({ required: false })}
          />
        </div>
      )}
    </div>
  )
}

const SceneSetup = ({
  heatMap,
  anpr,
  shouldShowAnpr,
  onButtonClick,
  eventTrigger,
  register,
  onValueChanged,
  anprStorageDurationChanged,
  onToggleHeatmap,
  onToggleAnpr,
  onToggleDirection,
  onToggleSpeedEstimate,
  onToggleRoiTrigger,
  onToggleRoiType,
  onChangeRoiCapacity,
  model,
  errors
}) => {
  const { t } = useTranslation()
  const personModelSelected =
    model &&
    (model === EBoxModel.personDetectorDemoStandardFast ||
      model === EBoxModel.headDetectorRetailStandard)

  function TrafficTriggers() {
    return (
      <>
        <FormLabel id="draw.form.buttonAddCl" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.crossingLine)
            }}
          >
            {t('draw.form.buttonNewCrossingLine')}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddZone" />
        <div className="draw-config-form__buttons">
          <Button
            className="scc--full-width"
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.originDestinationZone)
            }}
          >
            {t('draw.form.buttonNewZone')}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddRoi" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.regionOfInterest)
            }}
          >
            {t('draw.form.buttonNewRegionOfInterest')}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddDoor" />
        <div className="draw-config-form__buttons">
          <Button
            className="scc--full-width"
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.virtualDoor)
            }}
          >
            {t('draw.form.buttonAddDoor')}
          </Button>
        </div>
      </>
    )
  }

  function ParkingTriggers() {
    return (
      <>
        <FormLabel id="draw.form.buttonAddCl" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.crossingLine)
            }}
          >
            {t(
              shouldShowAnpr
                ? 'draw.form.buttonNewBarrierAnpr'
                : 'draw.form.buttonNewBarrier'
            )}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddRoi" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(
                event,
                EEventTriggerType.regionOfInterest,
                ERegionOfInterestType.parking,
                1
              )
            }}
          >
            {t('draw.form.buttonNewSingleSpace')}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddRoi" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(
                event,
                EEventTriggerType.regionOfInterest,
                ERegionOfInterestType.parking,
                5
              )
            }}
          >
            {t('draw.form.buttonNewMultiSpace')}
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <FormGroup
        legendText={t('draw.form.title')}
        className="scc--device-config--title"
      >
        <Space direction="vertical">
          <em className="scc--required--hint">{t('draw.form.hint')}</em>
          <FormLabelWithTooltip
            id={
              excludeSupport
                ? 'draw.form.box.modelTooltipNoSupport'
                : 'draw.form.box.model'
            }
          />
          <Select
            id="draw.form.box.model"
            className="scc--formfield__value-set"
            inline={false}
            invalid={errors['box.model'] !== undefined}
            invalidText={t('draw.form.box.model.invalidText')}
            labelText={false}
            name="box.model"
            value={model || ''}
            onChange={onValueChanged}
            ref={register({ required: true })}
          >
            <SelectItem
              key="empty"
              value=""
              text={t('draw.form.box.model.placeholder')}
            />
            {Object.values(EBoxModel).map((item) => (
              <SelectItem
                key={item}
                value={item}
                text={t(`draw.form.box.model.models.${item}.name`)}
              />
            ))}
          </Select>

          {model && (
            <>
              <Tile id="draw.form.use-case-description">
                <div className="bx--model-description">
                  {t(`draw.form.box.model.models.${model}.description`)}
                </div>

                {!excludeSupport && (
                  <a
                    href={t(`draw.form.box.model.models.${model}.doculink`)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(`draw.form.box.model.linkdescription`)}
                  </a>
                )}
              </Tile>
            </>
          )}
        </Space>
      </FormGroup>
      <FormGroup
        legendText={t('draw.form.eventtypes.title')}
        className="scc--device-config--title"
      >
        <em className="scc--required--hint">
          {t('draw.form.eventtypes.hint')}
        </em>

        <Menu
          style={{ width: 256 }}
          defaultOpenKeys={['parameter']}
          mode={'inline'}
          className="scc--device-config--eventtypes--menu"
        >
          {!excludeSupport && (
            <FormLabelWithTooltip id="draw.form.eventtypes" />
          )}
          {!personModelSelected && (
            <>
              <SubMenu
                key="parking"
                icon={<ClockCircleOutlined />}
                title={t('draw.form.eventtypes.parking')}
                className="scc--device-config--eventtypes--menu"
              >
                <ParkingTriggers />
              </SubMenu>
              <SubMenu
                key="traffic"
                icon={<CarOutlined />}
                title={t('draw.form.eventtypes.traffic')}
                className="scc--device-config--eventtypes--menu"
              >
                <TrafficTriggers />
              </SubMenu>
            </>
          )}
          {personModelSelected && (
            <SubMenu
              key="people"
              icon={<TeamOutlined />}
              title={t('draw.form.eventtypes.people')}
              className="scc--device-config--eventtypes--menu"
            >
              <TrafficTriggers />
            </SubMenu>
          )}
          <SubMenu
            key="parameter"
            icon={<SettingOutlined />}
            title={t('draw.form.eventtypes.settings')}
            className="scc--device-config--eventtypes--menu"
          >
            <TriggerSettings
              eventTrigger={eventTrigger}
              onToggleSpeedEstimate={onToggleSpeedEstimate}
              onValueChanged={onValueChanged}
              personModelSelected={personModelSelected}
              onToggleRoiType={onToggleRoiType}
              onToggleRoiTrigger={onToggleRoiTrigger}
              onChangeRoiCapacity={onChangeRoiCapacity}
              onToggleDirection={onToggleDirection}
              heatMap={heatMap}
              onToggleHeatmap={onToggleHeatmap}
              shouldShowAnpr={shouldShowAnpr}
              anpr={anpr}
              onToggleAnpr={onToggleAnpr}
              anprStorageDurationChanged={anprStorageDurationChanged}
              errors={errors}
              register={register}
            />
          </SubMenu>
        </Menu>
      </FormGroup>
    </>
  )
}

const directionToggle = (eventTrigger, onClick, t) => {
  const direction = (eventTrigger as CrossingLineEventTrigger).direction

  const leftGlyphClass =
    direction === 'in'
      ? 'scc--crossingline--direction--caret on'
      : 'scc--crossingline--direction--caret'

  const rightGlyphClass =
    direction === 'out'
      ? 'scc--crossingline--direction--caret on'
      : 'scc--crossingline--direction--caret'

  return (
    <div
      className="scc--crossingline--direction--wrapper"
      onClick={(event) => {
        onClick(event, eventTrigger)
      }}
    >
      <div className="scc--crossingline--direction">
        <CaretLeftGlyph className={leftGlyphClass} />
        <CaretRightGlyph className={rightGlyphClass} />
      </div>
      {direction === 'in' && (
        <label className="bx--label">
          {t('draw.form.crossingLine.direction.in')}&nbsp;|&nbsp;
          {t('draw.form.crossingLine.direction.out')}
        </label>
      )}
      {direction === 'out' && (
        <label className="bx--label">
          {t('draw.form.crossingLine.direction.out')}&nbsp;|&nbsp;
          {t('draw.form.crossingLine.direction.in')}
        </label>
      )}
    </div>
  )
}

export default SceneSetup
