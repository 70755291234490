import React from 'react'
import * as PropTypes from 'prop-types'
import MemberDropdown from './MemberDropdown'
import RemoveButtonGroup from './RemoveButtonGroup'
import { PlusCircleOutlined } from '@ant-design/icons'

const MemberGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods
}) => (
  <span>
    {members.map((m) => (
      <RemoveButtonGroup
        key={m.index || m.name}
        onRemoveClick={() => updateMethods.remove(m)}
      >
        <MemberDropdown
          availableMembers={availableMembers}
          onClick={(updateWith) => updateMethods.update(m, updateWith)}
        >
          {m.title}
        </MemberDropdown>
      </RemoveButtonGroup>
    ))}
    {availableMembers && availableMembers.length > 0 && (
      <MemberDropdown
        onClick={(m) => updateMethods.add(m)}
        availableMembers={availableMembers}
        type="dashed"
        icon={<PlusCircleOutlined />}
      >
        {addMemberName}
      </MemberDropdown>
    )}
  </span>
)

MemberGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired
}
export default MemberGroup
