import {
  SAVE_STREAMS_DETAILS_REQUEST_FAILURE,
  SAVE_STREAMS_DETAILS_REQUEST_SUCCESS,
  STREAMS_DETAILS_REQUEST_FAILURE,
  STREAMS_DETAILS_REQUEST_SUCCESS
} from './actionTypes'
import { loadCameraConfig, saveCameraConfig } from './cameraConfigurations'
import { loadMqttConfig, saveMqttConfig } from './mqttConfigurations'

import axios from 'axios'
import { DefaultStream } from '../../types/stream'
import { trimObjectValues } from '../../helpers'
import { DefaultCameraConfiguration } from '../../types/camera_configuration'
import { DefaultMqttConfiguration } from '../../types/mqtt_configuration'
import { saveStream } from './streams'

/**
 * ==================
 * Stream DETAIL ACTIONS
 * ==================
 */

export const loadStreamDetails = (boxId, streamId) => async (
  dispatch,
  getState
) => {
  return axios
    .all([
      dispatch(loadMqttConfig(boxId, streamId)),
      dispatch(loadCameraConfig(boxId, streamId))
    ])
    .then((data) => {
      // Notify UI with configuration data
      return dispatch({
        type: STREAMS_DETAILS_REQUEST_SUCCESS,
        payload: {
          streamId: streamId,
          mqttConfig: data[0],
          cameraConfig: data[1]
        }
      })
    })
    .catch((error) => {
      return dispatch({
        type: STREAMS_DETAILS_REQUEST_FAILURE,
        error
      })
    })
}

/**
 * Saves all box configurations.
 * @param boxId: string The id of the box to save the configuration for.
 * @param streamId
 * @param data
 */
export const saveStreamDetails = (boxId: string, streamId: string, data) => (
  dispatch,
  getState
) => {
  const state = getState()

  const streamMeta = state.streams.byIds[streamId] || DefaultStream
  const updatedStreamMeta = Object.assign(
    {},
    streamMeta,
    trimObjectValues(data.streamMeta)
  )

  const cameraConfig =
    state.cameraConfigurations.byIds[streamId] || DefaultCameraConfiguration
  const updatedCameraConfig = Object.assign(
    {},
    cameraConfig,
    trimObjectValues(data.cameraConfig)
  )

  const mqttConfig =
    state.mqttConfigurations.byIds[streamId] || DefaultMqttConfiguration
  const updatedMqttConfig = Object.assign(
    {},
    mqttConfig,
    trimObjectValues(data.mqttConfig)
  )

  if (!state.streams.byIds[streamId]) {
    // it's a new stream
    updatedStreamMeta.id = streamId
    updatedCameraConfig.streamId = streamId
    updatedCameraConfig.boxId = boxId
    updatedMqttConfig.streamId = streamId
    updatedMqttConfig.boxId = boxId
  }

  updatedCameraConfig.port = parseInt(updatedCameraConfig.port) || null
  updatedMqttConfig.port = parseInt(updatedMqttConfig.port) || null

  return axios
    .all([
      dispatch(saveStream(boxId, streamId, updatedStreamMeta)),
      dispatch(saveCameraConfig(updatedCameraConfig)),
      dispatch(saveMqttConfig(updatedMqttConfig))
    ])
    .then((response) => {
      return dispatch({
        type: SAVE_STREAMS_DETAILS_REQUEST_SUCCESS,
        payload: {
          streamId: streamId,
          mqttConfig: Object.values(
            response[2].response.entities.configurations
          )[0],
          cameraConfig: Object.values(
            response[1].response.entities.configurations
          )[0]
        }
      })
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_STREAMS_DETAILS_REQUEST_FAILURE,
        error
      })
    })
}
