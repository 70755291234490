import {
  STREAMS_DETAILS_REQUEST_SUCCESS,
  RESET_STREAM_DETAILS,
  SAVE_STREAMS_DETAILS_REQUEST,
  SAVE_STREAMS_DETAILS_REQUEST_FAILURE,
  SAVE_STREAMS_DETAILS_REQUEST_SUCCESS
} from '../actions/actionTypes'

const initialState = {
  byIds: {},
  isSubmitting: false
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case RESET_STREAM_DETAILS:
      return { ...initialState }

    case STREAMS_DETAILS_REQUEST_SUCCESS:
      let fetchedStreamDetails = { ...state.byIds }
      fetchedStreamDetails[action.payload.streamId] = action.payload
      return {
        ...state,
        byIds: fetchedStreamDetails
      }

    case SAVE_STREAMS_DETAILS_REQUEST:
      return {
        ...state,
        isSubmitting: true
      }

    case SAVE_STREAMS_DETAILS_REQUEST_SUCCESS:
      let updatedStreamDetails = { ...state.byIds }
      updatedStreamDetails[action.payload.streamId] = action.payload
      return {
        ...state,
        byIds: updatedStreamDetails,
        isSubmitting: false
      }

    case SAVE_STREAMS_DETAILS_REQUEST_FAILURE:
      return {
        ...initialState,
        isSubmitting: false
      }

    default:
      return state
  }
}
export default fn
