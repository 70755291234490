import { EBoxModel } from './boxModel'
import {
  DefaultCameraConfiguration,
  ICameraConfiguration
} from './camera_configuration'
import {
  DefaultMqttConfiguration,
  IMqttConfiguration
} from './mqtt_configuration'
import { IStreamStatus } from './streamStatus'

export interface IStream {
  id: string // stream UUID
  name?: string // Friendly name of the stream
  model?: EBoxModel
  streamStatus?: IStreamStatus
}

export interface IStreamInfo {
  boxName: string
  boxId: string
  streamName?: string
  streamId: string
  readOnly: boolean
  hasAnpr: boolean
  streamStatus?: IStreamStatus
}

export const DefaultStream: IStream = {
  id: '',
  name: undefined,
  model: undefined,
  streamStatus: undefined
}

export interface IStreamDetails {
  streamId: string
  cameraConfig: ICameraConfiguration
  mqttConfig: IMqttConfiguration
}

export const DefaultStreamDetails: IStreamDetails = {
  streamId: '',
  cameraConfig: DefaultCameraConfiguration,
  mqttConfig: DefaultMqttConfiguration
}
