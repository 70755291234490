import './BoxList.scss'

import { ConnectionStatusCell, NameIdCell } from './cells'
import { useHistory, useLocation } from 'react-router-dom'

import EmptyBoxListText from './EmptyBoxListText'
import { IBox } from '../../types/box'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars-2'
import OperationalStatusCell from './cells/OperationalStatusCell'
import { EConnectionState } from '../../types/boxStatus'
import { Button, Input, Space, Switch, Table } from 'antd'
import {
  FilterOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { ERuntimeState } from '../../types/runtimeState'
import { Config } from '../../services/config'

const LOCAL_STORAGE_FILTERS = 'deviceListFilter'

interface IBoxListProps {
  boxes: IBox[]
  boxesFetched: boolean
  autoRefresh: boolean
  setAutoRefresh: Function
}

const BoxList: React.FC<IBoxListProps> = ({
  boxes,
  boxesFetched,
  autoRefresh,
  setAutoRefresh
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [selectedRow, setSelectedRow] = useState<string | null>(null)
  const [filteredInfo, setFilteredInfo] = useState<any>(null)
  const filterColumns = useMemo(
    () => ['name_id', 'connection', 'operationalstate'],
    []
  ) // required to load filter states from local storage

  function showBoxDetails(box: IBox) {
    history.push(`/boxes/${box.id}`)
  }

  useEffect(() => {
    let parts = location.pathname.split('/')
    if (parts.length < 3) {
      selectedRow && setSelectedRow(null)
    } else {
      let boxId = parts[2]
      if (!selectedRow || selectedRow !== boxId) {
        setSelectedRow(boxId)
      }
    }
  }, [location.pathname, selectedRow])

  // load filters from storage
  useEffect(() => {
    let filters = {}
    filterColumns.forEach((key) => {
      let values = localStorage.getItem(`${LOCAL_STORAGE_FILTERS}_${key}`)
      let filterValues: string[] | null = null

      if (values) {
        if (key !== 'name_id') {
          filterValues = values.split(',') // for non-search fields, multiselect
        } else {
          filterValues = [values] // search fields have a single value, split with comma is problematic
        }
      }
      filters[key] = filterValues
    })
    setFilteredInfo(filters)
  }, [filterColumns])

  let clearAll = () => {
    handleChange(null, null, null)
  }

  let handleChange = (pagination, filters, sorter) => {
    filterColumns.forEach((key) => {
      let value = ''
      filters && filters[key] && (value = filters[key].toString())
      localStorage.setItem(`${LOCAL_STORAGE_FILTERS}_${key}`, value)
    })
    setFilteredInfo(filters)
  }

  let searchInput: Input | null = null
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={t('boxList.dataTable.search.name')}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].key
        ? record[dataIndex].key
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const data: any = []
  boxes.forEach((box) => {
    data.push({
      key: box.id,
      name_id: <NameIdCell box={box} key={box.name + box.id} />,
      connection: (
        <ConnectionStatusCell
          boxStatus={box.boxStatus}
          key={box.boxStatus?.connectionState}
        />
      ),
      operationalstate: (
        <OperationalStatusCell
          runtimeState={
            box.boxStatus && box.boxStatus.runtimeState
              ? box.boxStatus.runtimeState
              : ERuntimeState.unknown
          }
          styleOverride={{ textAlign: 'center' }}
          key={box.boxStatus?.runtimeState}
        />
      )
    })
  })

  const columns = [
    {
      title: t('boxList.dataTable.headers.name_id'),
      dataIndex: 'name_id',
      key: 'name_id',
      width: '40%',
      ...getColumnSearchProps('name_id'),
      sorter: (a, b) => {
        let nameA = a.name_id.key.toLowerCase()
        let nameB = b.name_id.key.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      title: t('boxList.dataTable.headers.connection'),
      dataIndex: 'connection',
      key: 'connection',
      align: 'center' as 'center',
      filters: [
        {
          text: t(
            `boxList.dataTable.connectionStatus.${EConnectionState.connected.toLowerCase()}`
          ),
          value: EConnectionState.connected
        },
        {
          text: t(
            `boxList.dataTable.connectionStatus.${EConnectionState.disconnected.toLowerCase()}`
          ),
          value: EConnectionState.disconnected
        },
        {
          text: t(
            `boxList.dataTable.connectionStatus.${EConnectionState.unknown.toLowerCase()}`
          ),
          value: EConnectionState.unknown
        }
      ],
      onFilter: (value, record) => record.connection.key === value,
      filteredValue: (filteredInfo && filteredInfo.connection) || null,
      sorter: (a, b) => a.connection.key.length - b.connection.key.length
    },
    {
      title: (
        <>
          {t('boxList.dataTable.headers.operationalstate')}
          {!Config.EXCLUDE_SUPPORT && (
            <Button
              type="link"
              icon={<InfoCircleOutlined />}
              onClick={(event) => {
                event.stopPropagation()
                window.open(
                  t('boxList.dataTable.headers.operationalstateUrl'),
                  '_blank'
                )
              }}
            />
          )}
        </>
      ),
      dataIndex: 'operationalstate',
      key: 'operationalstate',
      align: 'center' as 'center',
      filters: [
        {
          text: t(
            `boxList.dataTable.runtimeStatus.${ERuntimeState.operational}`
          ),
          value: ERuntimeState.operational
        },
        {
          text: t(`boxList.dataTable.runtimeStatus.${ERuntimeState.unknown}`),
          value: ERuntimeState.unknown
        },
        {
          text: t(
            `boxList.dataTable.runtimeStatus.${ERuntimeState.notconfigured}`
          ),
          value: ERuntimeState.notconfigured
        },
        {
          text: t(
            `boxList.dataTable.runtimeStatus.${ERuntimeState.notoperational}`
          ),
          value: ERuntimeState.notoperational
        }
      ],
      onFilter: (value, record) => record.operationalstate.key === value,
      filteredValue: (filteredInfo && filteredInfo.operationalstate) || null,
      sorter: (a, b) =>
        a.operationalstate.key.length - b.operationalstate.key.length
    }
  ]

  let isFiltered = false
  filteredInfo &&
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key]) {
        isFiltered = true
      }
    })

  return (
    <div className="scc--boxlist--container">
      <Scrollbars autoHide={true}>
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col">
              {isFiltered && (
                <div className="bx--col scc--boxlist--filterwarning">
                  <span>
                    <FilterOutlined /> {t('boxList.filterWarning.text')}
                  </span>
                  <Button onClick={clearAll} size={'small'} type="link">
                    {t('boxList.filterWarning.button')}
                  </Button>
                </div>
              )}
            </div>
            <div className="bx--col scc--boxlist--autorefresh">
              <span>{t('boxList.autoRefresh.text')}</span>
              <Switch
                size={'small'}
                checkedChildren={t('boxList.autoRefresh.toggleOn')}
                unCheckedChildren={t('boxList.autoRefresh.toggleOff')}
                checked={autoRefresh}
                onChange={(value) => {
                  setAutoRefresh(value)
                }}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <Table
                rowClassName={(record, index) =>
                  record.key === selectedRow
                    ? 'scc--tablerow scc--selected'
                    : 'scc--tablerow'
                }
                columns={columns}
                dataSource={data}
                loading={!boxesFetched}
                onRow={(row) => ({
                  onClick: () => {
                    const selectedBox = boxes.find((box) => {
                      return box.id === row.key
                    })
                    if (!selectedBox) {
                      return
                    }
                    showBoxDetails(selectedBox)
                  }
                })}
                pagination={{ defaultPageSize: 15 }}
                locale={{
                  emptyText: <EmptyBoxListText />
                }}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  )
}

export default BoxList
