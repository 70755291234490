import './CameraFeed.scss'
import './DrawCanvas.scss'

import React, { Component } from 'react'

import CanvasWrapper from './CanvasWrapper'
import { ICameraFrame } from '../../types/cameraFrame'
import { ICoordinateBasedEventTrigger } from '../../types/eventTrigger'

interface IDrawCanvasProps {
  frame?: ICameraFrame
  eventTriggers: ICoordinateBasedEventTrigger[]
  selectedEventTrigger: string
  highlightedEventTrigger: string
  onDragEnd?: (MouseEvent, IEventTrigger, any) => void
  onSelectEventTrigger?: (IEventTrigger) => void
  isEditable: boolean
  isLoading: boolean
  calibration: boolean
  onRefreshButtonClick: (MouseEvent) => void
}

class DrawCanvas extends Component<IDrawCanvasProps> {
  stage: any
  highlightedEventTrigger: any

  static defaultProps = {
    isEditable: false,
    calibration: false
  }

  constructor(props) {
    super(props)

    this.onDragStart = this.onDragStart.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onDragStart(event, currentEventTrigger) {
    event.currentTarget.moveToTop()
    this.setSelected(currentEventTrigger)
  }

  onClick(event, currentEventTrigger) {
    event.currentTarget.moveToTop()
    this.setSelected(currentEventTrigger)
  }

  setSelected(eventTrigger) {
    if (typeof this.props.onSelectEventTrigger === 'function') {
      this.props.onSelectEventTrigger(eventTrigger)
    }
  }

  /**
   * Returns the sorted list of event triggers.
   * Necessary to get the z-index for the canvas nodes right.
   * The event trigger to highlight is the last element in the array
   * and therefore gets the highest z-index.
   */
  get eventTriggers(): ICoordinateBasedEventTrigger[] {
    const sorted: ICoordinateBasedEventTrigger[] = []
    let highlightedEventTrigger: ICoordinateBasedEventTrigger | null = null

    this.props.eventTriggers.forEach((eventTrigger) => {
      if (eventTrigger.localId !== this.props.highlightedEventTrigger) {
        sorted.push(eventTrigger)
      } else {
        highlightedEventTrigger = eventTrigger
      }
    })

    if (highlightedEventTrigger) {
      sorted.push(highlightedEventTrigger)
    }

    return sorted
  }

  render() {
    return (
      <CanvasWrapper
        frame={this.props.frame}
        eventTriggers={this.props.eventTriggers}
        highlightedEventTrigger={this.props.highlightedEventTrigger}
        selectedEventTrigger={this.props.selectedEventTrigger}
        isEditable={this.props.isEditable}
        isLoading={this.props.isLoading}
        calibration={this.props.calibration}
        events={{
          onDragStart: this.onDragStart,
          onDragEnd: this.props.onDragEnd,
          onClick: this.onClick,
          onRefreshButtonClick: this.props.onRefreshButtonClick
        }}
      />
    )
  }
}

export default DrawCanvas
