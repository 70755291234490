import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Dropdown, Menu, Radio, Row, Space } from 'antd'
import { EWidgetType } from '../../../../types/solution'
import {
  CarOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  FundOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  StopOutlined,
  TableOutlined
} from '@ant-design/icons'
import CLSelection from '../../Helper/CLSelection'
import GranularityFilter from '../QueryBuilderComponents/GranularityFilter'
import SegmentationFilter from '../QueryBuilderComponents/SegmentationFilter'
import FilterData from '../QueryBuilderComponents/FilterData'
import SelectChartType from '../QueryBuilderComponents/SelectChartType'
import ChartRenderer from '../../../../datadiscovery/components/ChartRenderer'
import {
  ICrossinglineInfo,
  IRegionOfInterestInfo,
  IScene
} from '../../../../types/scene'
import { useTranslation, withTranslation } from 'react-i18next'
import { TCubeDimension } from '@cubejs-client/core'
import { connect } from 'react-redux'
import {
  listCrossinglinesInScene,
  listRegionsOfInterestInScene
} from '../../../../redux/actions/scenes'
import {
  compareElements,
  getElementsByName,
  handleElementChange,
  updateFilter
} from './QueryBuilderUtility'
import MeasurementSelection from '../QueryBuilderComponents/MeasurementSelection'
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_GRANULARITY
} from '../../../../datadiscovery/components/QueryBuilder/StateChangeHeuristics'
import { RegionSelection } from '../../Helper/RegionSelection'
import { Config } from '../../../../services/config'

const WidgetTypes = [
  EWidgetType.CurrentParkingUtilization,
  EWidgetType.HistoricParkingUtilization,
  EWidgetType.EntryExitFrequency,
  EWidgetType.OccupancyState,
  EWidgetType.ParkingTime,
  EWidgetType.HistoricParkingTime,
  EWidgetType.ParkingTimeViolations
]

const WidgetTypeIcons = {
  [EWidgetType.CurrentParkingUtilization]: <PercentageOutlined />,
  [EWidgetType.HistoricParkingUtilization]: <FundOutlined />,
  [EWidgetType.OccupancyState]: <TableOutlined />,
  [EWidgetType.EntryExitFrequency]: <CarOutlined />,
  [EWidgetType.ParkingTime]: <ClockCircleOutlined />,
  [EWidgetType.HistoricParkingTime]: <FieldTimeOutlined />,
  [EWidgetType.ParkingTimeViolations]: <StopOutlined />
}

export enum EAbsoluteRelative {
  absolute = 'absolute',
  relative = 'relative'
}

export enum EUtilizationMode {
  barrierless = 'barrierless',
  spacebased = 'spacebased'
}

const MeasureByWidgetType = {
  [EWidgetType.CurrentParkingUtilization]: {
    [EUtilizationMode.barrierless]: [
      'ParkingUtilization.free',
      'ParkingUtilization.occupied',
      'ParkingUtilization.capacity'
    ],
    [EUtilizationMode.spacebased]: [
      'SpacebasedParkingUtilization.free',
      'SpacebasedParkingUtilization.occupied',
      'SpacebasedParkingUtilization.capacity'
    ]
  },
  [EWidgetType.OccupancyState]: [
    'SpacebasedParkingUtilization.occupied',
    'SpacebasedParkingUtilization.capacity'
  ],
  [EWidgetType.HistoricParkingUtilization]: {
    [EUtilizationMode.barrierless]: {
      [EAbsoluteRelative.absolute]: [
        'ParkingUtilizationHistoric.avgUtilization',
        'ParkingUtilizationHistoric.minUtilization',
        'ParkingUtilizationHistoric.maxUtilization'
      ],
      [EAbsoluteRelative.relative]: [
        'ParkingUtilizationHistoric.avgUtilizationRelative',
        'ParkingUtilizationHistoric.minUtilizationRelative',
        'ParkingUtilizationHistoric.maxUtilizationRelative'
      ]
    },
    [EUtilizationMode.spacebased]: {
      [EAbsoluteRelative.absolute]: [
        'SpacebasedParkingUtilizationHistoric.avgUtilization'
      ],
      [EAbsoluteRelative.relative]: [
        'SpacebasedParkingUtilizationHistoric.avgUtilizationRelative'
      ]
    }
  },
  [EWidgetType.EntryExitFrequency]: 'CrossingEvents.count',
  [EWidgetType.ParkingTime]: 'ParkingAnalyticsEvents.sumDuration',
  [EWidgetType.HistoricParkingTime]: [
    'ParkingAnalyticsEvents.avgDuration',
    'ParkingAnalyticsEvents.minDuration',
    'ParkingAnalyticsEvents.maxDuration'
  ],
  [EWidgetType.ParkingTimeViolations]: 'ParkingAnalyticsEvents.sumDuration'
}

const DefaultDataFiltersByWidgetType = {
  [EWidgetType.ParkingTime]: 'ParkingAnalyticsEvents.sceneId',
  [EWidgetType.ParkingTimeViolations]: 'ParkingAnalyticsEvents.sceneId',
  [EWidgetType.HistoricParkingTime]: 'ParkingAnalyticsEvents.sceneId',
  [EWidgetType.OccupancyState]: 'SpacebasedParkingUtilization.sceneId',
  [EWidgetType.CurrentParkingUtilization]: {
    [EUtilizationMode.barrierless]: 'ParkingUtilization.sceneId',
    [EUtilizationMode.spacebased]: 'SpacebasedParkingUtilization.sceneId'
  },
  [EWidgetType.HistoricParkingUtilization]: {
    [EUtilizationMode.barrierless]: 'ParkingUtilizationHistoric.sceneId',
    [EUtilizationMode.spacebased]:
      'SpacebasedParkingUtilizationHistoric.sceneId'
  }
}

const DataFilterByWidgetType = {
  ...{ [EWidgetType.EntryExitFrequency]: 'CrossingEvents.lineId' },
  ...{ [EWidgetType.OccupancyState]: 'SpacebasedParkingUtilization.roiId' },
  ...DefaultDataFiltersByWidgetType
}

const FilterByWidgetType = {
  [EWidgetType.EntryExitFrequency]: [
    'CrossingEvents.classification',
    'CrossingEvents.subclass',
    'CrossingEvents.direction'
  ]
}

const DimensionsByWidgetType = {
  [EWidgetType.EntryExitFrequency]: [
    'CrossingEvents.classification',
    'CrossingEvents.subclass',
    'CrossingEvents.direction'
  ],
  [EWidgetType.ParkingTime]: [
    'ParkingAnalyticsEvents.entryImage',
    'ParkingAnalyticsEvents.exitImage',
    'ParkingAnalyticsEvents.entryTime',
    'ParkingAnalyticsEvents.exitTime',
    'ParkingAnalyticsEvents.entryLine',
    'ParkingAnalyticsEvents.exitLine'
  ],
  [EWidgetType.ParkingTimeViolations]: [
    'ParkingAnalyticsEvents.entryTime',
    'ParkingAnalyticsEvents.exitTime',
    'ParkingAnalyticsEvents.entryLine',
    'ParkingAnalyticsEvents.exitLine'
  ]
}

const DefaultDimensionsByWidgetType = {
  [EWidgetType.ParkingTime]: ['ParkingAnalyticsEvents.numberPlate'],
  [EWidgetType.ParkingTimeViolations]: [
    'ParkingAnalyticsEvents.numberPlate',
    'ParkingAnalyticsEvents.entryImage',
    'ParkingAnalyticsEvents.exitImage'
  ],
  [EWidgetType.OccupancyState]: [
    'SpacebasedParkingUtilization.roiId',
    'SpacebasedParkingUtilization.roiName'
  ]
}

const SegmentsByWidgetType = {
  [EWidgetType.ParkingTimeViolations]: ['ParkingAnalyticsEvents.violations']
}

const ChartTypesByWidgetType = {
  [EWidgetType.CurrentParkingUtilization]: ['utilization'],
  [EWidgetType.OccupancyState]: ['table', 'occupancymap'],
  [EWidgetType.HistoricParkingUtilization]: ['line', 'table', 'bar'],
  [EWidgetType.EntryExitFrequency]: ['table', 'number', 'bar', 'pie'],
  [EWidgetType.ParkingTime]: ['table'],
  [EWidgetType.HistoricParkingTime]: ['line', 'table', 'bar'],
  [EWidgetType.ParkingTimeViolations]: ['table']
}

const TimeDimensionsByWidgetType = {
  [EWidgetType.HistoricParkingUtilization]: {
    [EUtilizationMode.barrierless]: 'ParkingUtilizationHistoric.timestamp',
    [EUtilizationMode.spacebased]:
      'SpacebasedParkingUtilizationHistoric.timestamp'
  },
  [EWidgetType.EntryExitFrequency]: 'CrossingEvents.timestamp',
  [EWidgetType.ParkingTime]: 'ParkingAnalyticsEvents.entryTime',
  [EWidgetType.HistoricParkingTime]: 'ParkingAnalyticsEvents.entryTime',
  [EWidgetType.ParkingTimeViolations]: 'ParkingAnalyticsEvents.entryTime'
}

interface IParkingQueryBuilder {
  crossingLinesInScene: ICrossinglineInfo[]
  regionsInScene: IRegionOfInterestInfo[]
  listCrossinglinesInScene: Function
  listRegionsOfInterestInScene: Function
  scene: IScene
  setValidQuery: Function
  widgetType: EWidgetType | undefined
  setWidgetType: Function
  isQueryPresent: any
  validatedQuery: any
  timeDimensions: any
  availableTimeDimensions: any
  updateTimeDimensions: any
  dimensions: any
  availableDimensions: any
  updateDimensions: any
  measures: any
  availableMeasures: any
  updateMeasures: any
  chartType: any
  updateChartType: any
  timeFilter: string
  segments: any
  availableSegments: any
  updateSegments: any
  filters: any
  updateFilters: any
  updateQuery: any
}

const ParkingQueryBuilder: React.FC<IParkingQueryBuilder> = (props) => {
  const { t } = useTranslation()

  let [clFilter, setClFilter] = useState([])
  let [regionFilter, setRegionFilter] = useState([])
  let [newMeasure, setNewMeasure] = useState<string[]>([])
  let [newDimensions, setNewDimensions] = useState<string[]>([])
  let [anprWarning, setAnprWarning] = useState(false)
  let [defaultChartType, setDefaultChartType] = useState(false)
  let [absoluteRelative, setAbsoluteRelative] = useState<EAbsoluteRelative>(
    EAbsoluteRelative.absolute
  )

  let [utilizationMode, setUtilizationMode] = useState<EUtilizationMode>(
    EUtilizationMode.barrierless
  )

  const {
    listCrossinglinesInScene,
    listRegionsOfInterestInScene,
    scene,
    isQueryPresent,
    validatedQuery,
    setValidQuery,
    widgetType,
    chartType,
    setWidgetType
  } = props

  useEffect(() => {
    listCrossinglinesInScene(scene)
    listRegionsOfInterestInScene(scene)
  }, [listCrossinglinesInScene, listRegionsOfInterestInScene, scene, scene.id])

  useEffect(() => {
    setValidQuery(
      widgetType && isQueryPresent && Object.keys(validatedQuery).length > 0
    )
  }, [widgetType, isQueryPresent, validatedQuery, setValidQuery])

  useEffect(() => {
    // set current filters if widget is edited
    props.filters.forEach((filter) => {
      if (
        widgetType === EWidgetType.EntryExitFrequency &&
        filter.member ===
          DataFilterByWidgetType[EWidgetType.EntryExitFrequency] &&
        clFilter !== filter.values
      ) {
        setClFilter(filter.values)
      }

      if (
        widgetType === EWidgetType.OccupancyState &&
        filter.member === 'SpacebasedParkingUtilization.roiId' &&
        regionFilter !== filter.values
      ) {
        setRegionFilter(filter.values)
      }
    })

    // set current measurements if widget is edited
    if (newMeasure && !compareElements(props.measures, newMeasure)) {
      setNewMeasure(
        props.measures.map((item) => {
          return item.name
        })
      )

      if (widgetType === EWidgetType.HistoricParkingUtilization) {
        let newAbsoluteRelative = EAbsoluteRelative.absolute
        let newUtilizationMode = EUtilizationMode.barrierless
        props.measures.forEach((item) => {
          if (
            MeasureByWidgetType[EWidgetType.HistoricParkingUtilization][
              EUtilizationMode.spacebased
            ][EAbsoluteRelative.absolute].includes(item.name) ||
            MeasureByWidgetType[EWidgetType.HistoricParkingUtilization][
              EUtilizationMode.spacebased
            ][EAbsoluteRelative.relative].includes(item.name)
          ) {
            newUtilizationMode = EUtilizationMode.spacebased
          }
          if (
            MeasureByWidgetType[EWidgetType.HistoricParkingUtilization][
              EUtilizationMode.barrierless
            ][EAbsoluteRelative.relative].includes(item.name) ||
            MeasureByWidgetType[EWidgetType.HistoricParkingUtilization][
              EUtilizationMode.spacebased
            ][EAbsoluteRelative.relative].includes(item.name)
          ) {
            newAbsoluteRelative = EAbsoluteRelative.relative
          }
        })

        utilizationMode !== newUtilizationMode &&
          setUtilizationMode(newUtilizationMode)
        absoluteRelative !== newAbsoluteRelative &&
          setAbsoluteRelative(newAbsoluteRelative)
      }
    }
    if (widgetType === EWidgetType.CurrentParkingUtilization) {
      let newUtilizationMode = EUtilizationMode.barrierless
      props.measures.forEach((item) => {
        if (
          MeasureByWidgetType[EWidgetType.CurrentParkingUtilization][
            EUtilizationMode.spacebased
          ].includes(item.name)
        ) {
          newUtilizationMode = EUtilizationMode.spacebased
        }
      })
      utilizationMode !== newUtilizationMode &&
        setUtilizationMode(newUtilizationMode)
    }

    // set current dimensions if widget is edited
    if (newDimensions && !compareElements(props.dimensions, newDimensions)) {
      setNewDimensions(
        props.dimensions.map((item) => {
          return item.name
        })
      )
    }
  }, [
    props.filters,
    props.measures,
    props.dimensions,
    newMeasure,
    newDimensions,
    widgetType,
    clFilter,
    regionFilter,
    absoluteRelative,
    utilizationMode
  ])

  // ParkingTime and ParkingTimeViolations widgets have no granularity
  useEffect(() => {
    if (
      widgetType &&
      (widgetType === EWidgetType.ParkingTime ||
        widgetType === EWidgetType.ParkingTimeViolations)
    ) {
      props.timeDimensions.forEach((timeDimension) => {
        if (timeDimension.granularity !== undefined) {
          props.updateTimeDimensions.update(timeDimension, {
            ...timeDimension,
            granularity: undefined
          })
        }
      })
    }
  })

  // set default chart type
  useEffect(() => {
    if (
      widgetType &&
      defaultChartType &&
      ChartTypesByWidgetType[widgetType][0] !== props.chartType
    ) {
      props.updateChartType(ChartTypesByWidgetType[widgetType][0])
      setDefaultChartType(false)
    }
  }, [defaultChartType, widgetType, props])

  const handleNewClFilterChange = (data) => {
    setClFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name ===
        DataFilterByWidgetType[EWidgetType.EntryExitFrequency]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleRegionFilterChange = (data) => {
    setRegionFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return dimension.name === 'SpacebasedParkingUtilization.roiId'
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleParkingUtilizationModeChange = (event) => {
    if (
      props.widgetType &&
      (props.widgetType === EWidgetType.HistoricParkingUtilization ||
        props.widgetType === EWidgetType.CurrentParkingUtilization)
    ) {
      let newMode: EUtilizationMode = event.target.value
      let widgetType: EWidgetType = props.widgetType

      let newMeasures
      let newTimes = TimeDimensionsByWidgetType[widgetType]
      if (widgetType === EWidgetType.HistoricParkingUtilization) {
        newMeasures = MeasureByWidgetType[widgetType][newMode][absoluteRelative]
        newTimes = TimeDimensionsByWidgetType[widgetType][newMode]
      } else if (widgetType === EWidgetType.CurrentParkingUtilization) {
        newMeasures = MeasureByWidgetType[widgetType][newMode]
      }
      let filters: any = []

      filters.push({
        operator: 'equals',
        member: DefaultDataFiltersByWidgetType[widgetType][newMode],
        values: [scene.id]
      })

      let updatedQuery = {
        measures: getElementsByName(newMeasures, props.availableMeasures),
        filters: filters,
        timeDimensions: newTimes
          ? [
              {
                dimension: newTimes,
                granularity: DEFAULT_GRANULARITY,
                dateRange: DEFAULT_DATE_RANGE
              }
            ]
          : [],
        ...(widgetType === EWidgetType.CurrentParkingUtilization
          ? { renewQuery: true }
          : {})
      }
      props.updateQuery(updatedQuery)

      setUtilizationMode(newMode)
      setNewMeasure(newMeasures)
    }
  }
  const handleAbsoluteRelativeChange = (event) => {
    setAbsoluteRelative(event.target.value)
    let newMeasures =
      MeasureByWidgetType[EWidgetType.HistoricParkingUtilization][
        utilizationMode
      ][event.target.value]

    // keep current selection
    let currentMeasures: string[] = []
    props.measures.forEach((measure) => {
      measure.name.indexOf('.avg') > 0 && currentMeasures.push('avg')
      measure.name.indexOf('.min') > 0 && currentMeasures.push('min')
      measure.name.indexOf('.max') > 0 && currentMeasures.push('max')
    })
    newMeasures = newMeasures.filter((measureName) => {
      let included = false
      currentMeasures.forEach((item) => {
        measureName.indexOf(item) > 0 && (included = true)
      })
      return included
    })
    setNewMeasure(newMeasures)

    props.updateQuery({
      measures: getElementsByName(newMeasures, props.availableMeasures)
    })
  }

  const handleNewMeasureChange = (data) => {
    setNewMeasure(data)
    handleElementChange(
      props.availableMeasures,
      props.measures,
      props.updateMeasures,
      data
    )
  }

  const handleNewDimensionChange = (data) => {
    setNewDimensions(data)
    handleElementChange(
      props.availableDimensions,
      props.dimensions,
      props.updateDimensions,
      data
    )
  }

  const handleWidgetTypeChange = (item) => {
    // check ANPR for time widgets
    if (
      item === EWidgetType.ParkingTime ||
      item === EWidgetType.HistoricParkingTime ||
      item === EWidgetType.ParkingTimeViolations
    ) {
      let anprEnabled = scene.boxStreams.some((item) => item.hasAnpr)
      setAnprWarning(!anprEnabled)
      if (!anprEnabled) {
        return
      }
    } else {
      anprWarning && setAnprWarning(false)
    }

    // set new measures, dimensions and segments
    let dimensions = DefaultDimensionsByWidgetType[item]
    let measures = MeasureByWidgetType[item]
    let filterDimension = DefaultDataFiltersByWidgetType[item]
    let segments = SegmentsByWidgetType[item]
    let times = TimeDimensionsByWidgetType[item]

    if (
      item === EWidgetType.HistoricParkingUtilization ||
      item === EWidgetType.CurrentParkingUtilization
    ) {
      if (item === EWidgetType.HistoricParkingUtilization) {
        measures =
          MeasureByWidgetType[item][EUtilizationMode.barrierless][
            EAbsoluteRelative.absolute
          ]
        times = TimeDimensionsByWidgetType[item][EUtilizationMode.barrierless]
      } else if (item === EWidgetType.CurrentParkingUtilization) {
        measures = MeasureByWidgetType[item][EUtilizationMode.barrierless]
      }
      filterDimension =
        DefaultDataFiltersByWidgetType[item][EUtilizationMode.barrierless]
    }

    let filters: any = []
    if (filterDimension) {
      filters.push({
        operator: 'equals',
        member: filterDimension,
        values: [scene.id]
      })
    }
    if (item === EWidgetType.EntryExitFrequency) {
      filters.push({
        operator: 'notContains',
        member: 'CrossingEvents.classification',
        values: ['bicycle', 'motorbike', 'person']
      })
    }

    let updatedQuery = {
      measures: getElementsByName(measures, props.availableMeasures),
      dimensions: getElementsByName(dimensions, props.availableDimensions),
      segments: getElementsByName(segments, props.availableSegments),
      filters: filters,
      timeDimensions: times
        ? [
            {
              dimension: times,
              granularity: DEFAULT_GRANULARITY,
              dateRange: DEFAULT_DATE_RANGE
            }
          ]
        : [],
      ...(item === EWidgetType.CurrentParkingUtilization
        ? { renewQuery: true }
        : {})
    }

    props.updateQuery(updatedQuery)

    setDefaultChartType(true)
    setWidgetType(item)
    setNewMeasure(measures)
    setNewDimensions(dimensions)
  }

  const filteredDimensions: TCubeDimension[] =
    widgetType &&
    props.availableDimensions.filter(
      (dim) =>
        FilterByWidgetType[widgetType] &&
        FilterByWidgetType[widgetType].includes(dim.name)
    )

  const segmentationDimensions: TCubeDimension[] =
    widgetType &&
    props.availableDimensions.filter(
      (dim) =>
        DimensionsByWidgetType[widgetType] &&
        DimensionsByWidgetType[widgetType].includes(dim.name)
    )

  const typeFilteredFilters =
    widgetType &&
    props.filters.filter(
      (filter) =>
        DataFilterByWidgetType[widgetType] !== filter.member &&
        filter.member.split('.')[1] !== 'streamId'
    )

  const dimensionsSelected = !!(
    widgetType !== EWidgetType.EntryExitFrequency || clFilter.length
  )

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card title={t('solutions.scenes.overview.add.widgetType.title')}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row>
            <Dropdown
              overlay={
                <Menu>
                  {WidgetTypes.map((item) => (
                    <Menu.Item
                      key={item}
                      icon={WidgetTypeIcons[item]}
                      onClick={() => {
                        handleWidgetTypeChange(item)
                      }}
                    >
                      {t(
                        `solutions.scenes.overview.add.widgetType.${item.toString()}`
                      )}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottomLeft"
              trigger={['click']}
            >
              {widgetType ? (
                <Button icon={WidgetTypeIcons[widgetType]}>
                  {t(
                    `solutions.scenes.overview.add.widgetType.${widgetType.toString()}`
                  )}
                </Button>
              ) : (
                <Button icon={<PlusCircleOutlined />}>
                  {t('solutions.scenes.overview.add.widgetType.placeholder')}
                </Button>
              )}
            </Dropdown>
          </Row>
          <Row>
            {(widgetType === EWidgetType.HistoricParkingUtilization ||
              widgetType === EWidgetType.CurrentParkingUtilization) && (
              <>
                <Radio.Group
                  value={utilizationMode}
                  onChange={handleParkingUtilizationModeChange}
                >
                  <Radio.Button value={EUtilizationMode.barrierless.valueOf()}>
                    {t(
                      'solutions.scenes.overview.add.data.utilization.barrierless'
                    )}
                  </Radio.Button>
                  <Radio.Button value={EUtilizationMode.spacebased.valueOf()}>
                    {t(
                      'solutions.scenes.overview.add.data.utilization.spacebased'
                    )}
                  </Radio.Button>
                </Radio.Group>
              </>
            )}
          </Row>
        </Space>
      </Card>

      {anprWarning && (
        <Alert
          message={t('solutions.scenes.overview.add.anprWarning.message')}
          description={
            <>
              <p>
                {t('solutions.scenes.overview.add.anprWarning.description')}
              </p>
              {!Config.EXCLUDE_SUPPORT && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('solutions.scenes.overview.add.anprWarning.docs')
                  }}
                />
              )}
            </>
          }
          type="warning"
          style={{ marginTop: '10px' }}
          showIcon
        />
      )}

      {widgetType &&
        widgetType !== EWidgetType.CurrentParkingUtilization &&
        !anprWarning && (
          <>
            <Card title={t('solutions.scenes.overview.add.data.title')}>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <p>
                  {t(
                    `solutions.scenes.overview.add.data.headline.${widgetType}`
                  )}
                </p>

                {widgetType === EWidgetType.EntryExitFrequency && (
                  <CLSelection
                    crossingLines={props.crossingLinesInScene}
                    newCrossingLines={clFilter}
                    handleCrossingLinesChange={handleNewClFilterChange}
                    multi={true}
                  />
                )}

                {widgetType === EWidgetType.OccupancyState && (
                  <RegionSelection
                    regions={props.regionsInScene}
                    newRegions={regionFilter}
                    handleRegionChange={handleRegionFilterChange}
                    multi={true}
                  />
                )}

                {widgetType === EWidgetType.HistoricParkingUtilization && (
                  <>
                    <Radio.Group
                      value={absoluteRelative}
                      onChange={handleAbsoluteRelativeChange}
                    >
                      <Radio.Button value="absolute">
                        {t('solutions.scenes.overview.add.data.absolute')}
                      </Radio.Button>
                      <Radio.Button value="relative">
                        {t('solutions.scenes.overview.add.data.percentage')}
                      </Radio.Button>
                    </Radio.Group>

                    <MeasurementSelection
                      availableMeasurements={
                        MeasureByWidgetType[widgetType][utilizationMode][
                          absoluteRelative
                        ]
                      }
                      measures={newMeasure}
                      allMeasures={props.availableMeasures}
                      handleFieldChange={handleNewMeasureChange}
                      multi={true}
                    />
                  </>
                )}

                {widgetType === EWidgetType.HistoricParkingTime && (
                  <MeasurementSelection
                    availableMeasurements={MeasureByWidgetType[widgetType]}
                    measures={newMeasure}
                    allMeasures={props.availableMeasures}
                    handleFieldChange={handleNewMeasureChange}
                    multi={true}
                  />
                )}

                {widgetType !== EWidgetType.ParkingTime &&
                  widgetType !== EWidgetType.ParkingTimeViolations && (
                    <GranularityFilter
                      timeDimensions={props.timeDimensions}
                      updateTimeDimension={props.updateTimeDimensions}
                    />
                  )}
                <SegmentationFilter
                  key={props.dimensions}
                  segmentationDimensions={segmentationDimensions}
                  dimensions={newDimensions}
                  handleDimensionChange={handleNewDimensionChange}
                />
                <FilterData
                  filters={typeFilteredFilters}
                  updateFilters={props.updateFilters}
                  filteredDimensions={filteredDimensions}
                />
              </Space>
            </Card>

            <SelectChartType
              availableChartNames={ChartTypesByWidgetType[widgetType]}
              chartType={props.chartType}
              updateChartType={props.updateChartType}
            />
          </>
        )}

      {widgetType &&
        dimensionsSelected &&
        !anprWarning &&
        props.isQueryPresent &&
        Object.keys(props.validatedQuery).length > 0 && (
          <Card className="scc--solutions--add-widget-preview">
            <ChartRenderer
              vizState={{
                query: props.validatedQuery,
                chartType
              }}
              overrideTimeZone={props.scene?.location?.timezone}
            />
          </Card>
        )}
    </Space>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    crossingLinesInScene: state.scenes.clinesPerScene[ownProps.scene.id],
    regionsInScene: state.scenes.regionsPerScene[ownProps.scene.id]
  }
}

export default connect(mapStateToProps, {
  listCrossinglinesInScene,
  listRegionsOfInterestInScene
})(withTranslation()(ParkingQueryBuilder))
