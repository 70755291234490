import {
  DELETE_STREAM_REQUEST_SUCCESS,
  STREAMS_INFO_REQUEST_SUCCESS,
  SAVE_STREAM_REQUEST_SUCCESS,
  STREAMS_REQUEST_FAILURE,
  STREAMS_REQUEST_SUCCESS
} from '../actions/actionTypes'
import { IStream, IStreamInfo } from '../../types/stream'
import union from 'lodash/union'

const initialState = {
  all: [],
  allIds: [],
  byIds: {},
  streamsFetched: false
}

const fn = function (state = initialState, action) {
  let newState
  switch (action.type) {
    case STREAMS_REQUEST_SUCCESS:
      if (action.response.entities.streams) {
        newState = {
          allIds: Object.keys(action.response.entities.streams),
          byIds: action.response.entities.streams,
          streamsFetched: true
        }
      } else {
        newState = {
          allIds: [],
          byIds: {},
          streamsFetched: true
        }
      }
      return Object.assign({}, state, newState)

    case STREAMS_REQUEST_FAILURE:
      return Object.assign({}, state, { streamsFetched: true })
    case SAVE_STREAM_REQUEST_SUCCESS:
      let savedStream: IStream = Object.values(
        action.response.entities.streams
      )[0] as IStream
      let streamid = savedStream.id
      if (streamid !== undefined) {
        let resultObject = {}
        resultObject[streamid] = savedStream
        return {
          ...state,
          isSubmitting: false,
          allIds: union(state.allIds, [savedStream.id]),
          byIds: Object.assign({}, state.byIds, resultObject)
        }
      } else {
        return {
          ...state,
          isSubmitting: false
        }
      }
    case DELETE_STREAM_REQUEST_SUCCESS:
      if (!action.response || !action.response.id) {
        return state
      }

      const deletedStreamId = action.response.id
      newState = {
        ...state,
        allIds: state.allIds.filter((id) => deletedStreamId !== id)
      }

      delete newState.byIds[deletedStreamId]
      return newState

    case STREAMS_INFO_REQUEST_SUCCESS:
      const streams: IStreamInfo[] = []
      action.response.forEach((item) => {
        let stream: IStreamInfo = item
        streams.push(stream)
      })
      return {
        ...state,
        all: streams
      }

    default:
      return state
  }
}
export default fn
