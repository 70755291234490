import './DrawContextForm.scss'

import { Form } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'

import { EBoxModel } from '../../types/boxModel'
import SceneSetup from './SceneSetup'
import { HeatMapEventTrigger } from '../../types/heatMap'
import { ICoordinateBasedEventTrigger } from '../../types/eventTrigger'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ERegionOfInterestTriggerType } from '../../types/regionOfInterest'
import { AnprEventTrigger } from '../../types/anpr'
import ConfirmationDialog from '../DeviceConfigurationConfirmationDialog'

interface IDrawContextFormProps {
  eventTrigger?: ICoordinateBasedEventTrigger
  heatMap?: HeatMapEventTrigger
  anpr?: AnprEventTrigger
  shouldShowAnpr: boolean
  onButtonClick: (MouseEvent, EEventTriggerType) => void
  onValueChanged: (IEventTrigger, Object) => void
  onToggleDirection: (MouseEvent, IEventTrigger) => void
  onToggleSpeedEstimate: (MouseEvent, IEventTrigger, boolean) => void
  model: EBoxModel | null
  isSubmitting: boolean
  hasDataChanged: boolean
  onSubmit: any
}

const DrawContextForm: React.FC<IDrawContextFormProps> = ({
  eventTrigger,
  heatMap,
  anpr,
  shouldShowAnpr,
  onButtonClick,
  onValueChanged,
  onToggleDirection,
  onToggleSpeedEstimate,
  model,
  isSubmitting,
  hasDataChanged,
  onSubmit
}) => {
  const { t } = useTranslation()
  const { register, reset, getValues, handleSubmit, errors } = useForm()

  useEffect(() => {
    reset({ ...eventTrigger })
  }, [eventTrigger, reset])

  const [
    anprConfirmationDialogVisible,
    setAnprConfirmationDialogVisible
  ] = useState<boolean>(false)

  const valueChanged = (event) => {
    onValueChanged(eventTrigger, getValues())
  }

  const anprStorageDurationChanged = (event) => {
    let newValue = event.imaginaryTarget.value
    if (!newValue) newValue = null
    onValueChanged(anpr, {
      eventTrigger: Object.assign({}, anpr, { anprStorageTime: newValue })
    })
  }

  const onToggleRoiTrigger = (e) => {
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, {
        trigger: e.target.value
      })
    })
  }

  const onToggleRoiType = (e) => {
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, {
        roiType: e.target.value,
        trigger: ERegionOfInterestTriggerType.time
      })
    })
  }

  const onChangeRoiCapacity = (event) => {
    let newValue = event.imaginaryTarget.value
    if (!newValue) newValue = null
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, { capacity: newValue })
    })
  }

  const onToggleSpeed = (checked, id, evt) => {
    onToggleSpeedEstimate(evt, eventTrigger, checked)
  }

  // Notifies the parent container about the changes
  const onToggleHeatmap = (checked, id, evt) => {
    onValueChanged(heatMap, {
      eventTrigger: Object.assign({}, heatMap, { enabled: checked })
    })
  }
  const onToggleAnpr = (checked, id, evt) => {
    if (checked) {
      setAnprConfirmationDialogVisible(true)
    } else {
      onValueChanged(anpr, {
        eventTrigger: Object.assign({}, anpr, { enabled: false })
      })
    }
  }

  const confirmAnpr = () => {
    setAnprConfirmationDialogVisible(false)
    onValueChanged(anpr, {
      eventTrigger: Object.assign({}, anpr, { enabled: true })
    })
  }

  const onCloseAnprConfirmation = () => {
    setAnprConfirmationDialogVisible(false)
  }

  return (
    <>
      <Form name="draw-config-form" onSubmit={handleSubmit(onSubmit)}>
        <SceneSetup
          register={register}
          onButtonClick={onButtonClick}
          onToggleHeatmap={onToggleHeatmap}
          onToggleAnpr={onToggleAnpr}
          anprStorageDurationChanged={anprStorageDurationChanged}
          shouldShowAnpr={shouldShowAnpr}
          onToggleDirection={onToggleDirection}
          onToggleSpeedEstimate={onToggleSpeed}
          onToggleRoiTrigger={onToggleRoiTrigger}
          onToggleRoiType={onToggleRoiType}
          onChangeRoiCapacity={onChangeRoiCapacity}
          onValueChanged={valueChanged}
          eventTrigger={eventTrigger}
          errors={errors}
          model={model}
          heatMap={heatMap}
          anpr={anpr}
        />

        <div className="scc--sticky scc--boxcontextual--btn-save--container">
          <button
            type="submit"
            disabled={isSubmitting || !hasDataChanged}
            className="bx--btn bx--btn--primary scc--boxcontextual--btn-save"
          >
            {t('draw.buttonSave.title')}
          </button>
        </div>
      </Form>

      <ConfirmationDialog
        modalHeading={t('draw.form.anpr.confirmation.heading')}
        primaryButtonText={t('draw.form.anpr.confirmation.primaryButton.title')}
        secondaryButtonText={t(
          'draw.form.anpr.confirmation.secondaryButton.title'
        )}
        onRequestClose={onCloseAnprConfirmation}
        onSecondarySubmit={onCloseAnprConfirmation}
        onRequestSubmit={confirmAnpr}
        open={anprConfirmationDialogVisible}
      >
        {t('draw.form.anpr.confirmation.text')}
      </ConfirmationDialog>
    </>
  )
}

export default DrawContextForm
