import boxDetails from './boxDetails'
import boxes from './boxes'
import cameraConfigurations from './cameraConfigurations'
import cameraFrames from './cameraFrames'
import { combineReducers } from 'redux'
import error from './error'
import eventTriggers from './eventTriggers'
import mqttConfigurations from './mqttConfigurations'
import streamDetails from './streamDetails'
import streams from './streams'
import groups from './groups'
import scenes from './scenes'

export default combineReducers({
  boxes,
  boxDetails,
  streams,
  streamDetails,
  cameraConfigurations,
  mqttConfigurations,
  eventTriggers,
  cameraFrames,
  error,
  groups,
  scenes
})
