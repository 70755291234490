import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { IScene } from '../../../types/scene'
import { FormGroup, NumberInput } from 'carbon-components-react'
import { UseFormMethods } from 'react-hook-form'

import FormLabelWithTooltip from '../../../components/FormLabelWithToolTip'
import { Alert } from 'antd'

const ParkingConfig = ({
  scene,
  parentForm
}: {
  scene: IScene
  parentForm: UseFormMethods<Record<string, any>>
}) => {
  const { t } = useTranslation()
  const { register } = parentForm

  let [utilizationWarning, setUtilizationWarning] = useState(false)
  let currentUtilizationInput

  const checkUtilizationWarning = (event) => {
    let newValue = currentUtilizationInput.value
    setUtilizationWarning(
      scene.parkingSettings &&
        newValue &&
        newValue !== '' &&
        newValue !== String(scene.parkingSettings.utilization)
    )
  }

  return (
    <FormGroup
      legendText={t('solutions.scenes.config.parkingGroup.title')}
      className="scc--icon--legend scc--icon--doves"
    >
      <FormLabelWithTooltip id="solutions.scenes.config.parkingGroup.capacity" />
      <div className="bx--row">
        <div className="bx--col-lg-8">
          <NumberInput
            id={'solutions.scenes.config.parking.maxcapacity'}
            invalidText=""
            className="bx--number-input"
            allowEmpty={true}
            min={1}
            placeholder={t(
              'solutions.scenes.config.parkingGroup.capacity.placeholder'
            )}
            name="sceneConfig.parkingSettings.maxCapacity"
            defaultValue={scene.parkingSettings?.maxCapacity}
            ref={register({ required: false })}
          />
        </div>
      </div>

      <FormLabelWithTooltip id="solutions.scenes.config.parkingGroup.parkingtime" />
      <div className="bx--row">
        <div className="bx--col-lg-8">
          <NumberInput
            id={'solutions.scenes.config.parkingGroup.parkingtime'}
            invalidText=""
            className="bx--number-input"
            allowEmpty={true}
            min={1}
            placeholder={t(
              'solutions.scenes.config.parkingGroup.parkingtime.placeholder'
            )}
            name="sceneConfig.parkingSettings.maxParkingTime"
            defaultValue={scene.parkingSettings?.maxParkingTime}
            ref={register({ required: false })}
          />
        </div>
      </div>

      <FormLabelWithTooltip id="solutions.scenes.config.parkingGroup.utilization" />
      <div className="bx--row">
        <div className="bx--col-lg-8">
          <NumberInput
            id={'solutions.scenes.config.parkingGroup.utilization'}
            invalidText=""
            className="bx--number-input"
            allowEmpty={true}
            placeholder={t(
              'solutions.scenes.config.parkingGroup.utilization.placeholder'
            )}
            name="sceneConfig.parkingSettings.utilization"
            defaultValue={scene.parkingSettings?.utilization}
            onChange={checkUtilizationWarning}
            ref={(e) => {
              register(e, { required: false })
              currentUtilizationInput = e
            }}
          />
        </div>
      </div>
      {utilizationWarning && (
        <Alert
          message={t(
            'solutions.scenes.config.parkingGroup.utilization.warning'
          )}
          type="warning"
          style={{ marginTop: '10px' }}
          showIcon
        />
      )}
    </FormGroup>
  )
}

export default ParkingConfig
