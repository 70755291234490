import './Header.scss'

import AccountInfo from '../AccountInfo'
import React from 'react'

interface IHeaderProps {
  children?
}

const Header: React.FC<IHeaderProps> = (props) => {
  return (
    <header className="scc--header">
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-sm-2 bx--col-lg-8">
            <h1 className="scc--app--title">Control Center</h1>
          </div>
          <div className="bx--col-sm-2 bx--col-lg-8 scc--header--right">
            <AccountInfo />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
