import ConfirmationDialog from '.'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const RemoveConfirmationDialog = (props) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      primaryButtonText={t('modal.delete.primaryButton.title')}
      secondaryButtonText={t('modal.delete.secondaryButton.title')}
      modalHeading={t('modal.delete.heading')}
      danger={true}
      size="xs"
      {...props}
    >
      {t('modal.remove.text')}
    </ConfirmationDialog>
  )
}
