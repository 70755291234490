import { Layer, Stage } from 'react-konva'
import React, { useRef, useState } from 'react'

import CameraFeedImage from './CameraFeedImage'
import CanvasError from './CanvasError'
import CanvasLoading from './CanvasLoading'
import { CrossingLineEventTrigger } from '../../types/crossingLine'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import { ICameraFrame } from '../../types/cameraFrame'
import { Region } from './Region'
import refresh from '../../theme/icons/refresh.svg'
import { useResize } from '../../helpers/hooks/useResize'
import { useTranslation } from 'react-i18next'
import { AnprEventTrigger } from '../../types/anpr'
import { CrossingLine } from './CrossingLine'
import { Radio } from 'antd'

interface ICanvasWrapperProps {
  frame?: ICameraFrame
  eventTriggers: ICoordinateBasedEventTrigger[]
  anpr?: AnprEventTrigger
  events: any
  highlightedEventTrigger: string
  selectedEventTrigger: string
  isEditable: boolean
  isLoading: boolean
  calibration: boolean
}

const DEFAULT_CANVAS_HEIGHT = 400

let defaultCanvasDimensions: any = {
  height: DEFAULT_CANVAS_HEIGHT
}

const CanvasWrapper: React.FC<ICanvasWrapperProps> = ({
  frame,
  eventTriggers,
  events,
  highlightedEventTrigger,
  selectedEventTrigger,
  isEditable,
  isLoading
}) => {
  // This is used to force a rerendering of the component
  const timestamp = new Date().getTime()
  const stageEl = useRef(null)
  const containerEl = useRef(null)
  const { t } = useTranslation()

  const [dimensions, setDimensions] = useState(defaultCanvasDimensions)
  const [calibration, setCalibration] = useState(false)

  const oncalibration = (event) => {
    setCalibration(event.target.value)
    events.onRefreshButtonClick({
      calibration: event.target.value
    })
  }

  let image

  if (frame) {
    image = new Image()
    image.src = `data:image/png;base64,${frame.encodedImage}`
  }

  let timeout: NodeJS.Timeout

  // Resize the canvas when the window resizes
  useResize(containerEl, () => {
    clearTimeout(timeout)

    // Use a little timeout to wait for transitions to finish
    timeout = setTimeout(() => {
      const calculatedDimensions = calculateDimensions(
        containerEl.current,
        frame,
        isLoading
      )

      if (
        calculatedDimensions.width !== dimensions.width ||
        calculatedDimensions.height !== dimensions.height
      ) {
        defaultCanvasDimensions = calculatedDimensions
        setDimensions(calculatedDimensions)
      }
    }, 300)
  })

  const sortedTriggers = eventTriggers
    ? eventTriggers
        .slice(0)
        .sort((a, b) => (a.localId === selectedEventTrigger ? 1 : -1))
    : null

  return (
    <div
      className="scc--draw--canvas--wrapper"
      ref={containerEl}
      style={{ height: dimensions.height }}
    >
      <div id="scc--draw--canvas" style={{ width: dimensions.width }}>
        {isLoading && <CanvasLoading />}
        {!isLoading && !image && <CanvasError />}
        {!isLoading && (
          <>
            <button
              className="scc--camera-feed--refresh-button"
              title={t('camera.frame.buttonRefresh')}
              onClick={(event) => {
                event.preventDefault()
                events.onRefreshButtonClick({
                  calibration: calibration
                })
              }}
            >
              <img src={refresh} alt={t('camera.frame.buttonRefresh')} />
            </button>
            <Radio.Group
              defaultValue={calibration}
              onChange={oncalibration}
              className="scc--radio--calibrationMode"
            >
              <Radio.Button value={false}>Camera</Radio.Button>
              <Radio.Button value={true}>Calibration</Radio.Button>
            </Radio.Group>
          </>
        )}
        <Stage
          ref={stageEl}
          height={dimensions.height}
          width={dimensions.width}
        >
          {!isLoading && image && (
            <>
              <Layer>
                <CameraFeedImage
                  image={image}
                  width={dimensions.width}
                  height={dimensions.height}
                />
              </Layer>
              <Layer>
                {sortedTriggers &&
                  sortedTriggers.map((eventTrigger, index) => {
                    const key = timestamp + '-' + index
                    const isEventTriggerHighlighted =
                      eventTrigger.localId === highlightedEventTrigger
                    const isEventTriggerSelected =
                      eventTrigger.localId === selectedEventTrigger
                    return getEventTrigger(
                      eventTrigger,
                      stageEl.current,
                      events,
                      isEventTriggerHighlighted,
                      isEventTriggerSelected,
                      isEditable,
                      key
                    )
                  })}
              </Layer>
            </>
          )}
        </Stage>
      </div>
    </div>
  )
}

/**
 * Calculates the proper dimensions based on the available space
 * and the image size.
 *
 * @param container: HTMLElement The HTML element to place the canvas in.
 * @param frame
 * @param isLoading
 */
const calculateDimensions = (
  container,
  frame: ICameraFrame | undefined,
  isLoading
) => {
  if (!container || !frame || isLoading) {
    defaultCanvasDimensions = {
      height: DEFAULT_CANVAS_HEIGHT
    }

    return defaultCanvasDimensions
  }

  const parentColumn = container.closest('.scc--flex--column')
  const maxContainerHeight = parentColumn
    ? (parentColumn.offsetHeight * 2) / 3
    : 0
  const containerWidth = container.offsetWidth
  const { width, height } = frame
  const ratio = height / width

  // Get max possible width
  let calculatedWidth = Math.min(containerWidth, width)
  let calculatedHeight = calculatedWidth * ratio

  // Make sure the calculated canvas is not higher than
  // 2/3 of the column in order to properly show the table beneath
  if (parentColumn && calculatedHeight > maxContainerHeight) {
    calculatedHeight = maxContainerHeight
    calculatedWidth = maxContainerHeight / ratio
  }

  return {
    height: calculatedHeight,
    width: calculatedWidth
  }
}

/**
 * Helper to return the event trigger component.
 * @param eventTrigger The event trigger data object.
 * @param stage
 * @param events
 * @param isHighlighted
 * @param isSelected
 * @param isEditable
 * @param key The key to set as reference.
 */
const getEventTrigger = (
  eventTrigger,
  stage,
  events,
  isHighlighted,
  isSelected,
  isEditable,
  key
) => {
  if (!stage) {
    return null
  }

  if (eventTrigger.objectType === EEventTriggerType.crossingLine) {
    return (
      <CrossingLine
        key={`${eventTrigger.coordinates}-${key}`}
        eventTrigger={eventTrigger as CrossingLineEventTrigger}
        stage={stage}
        onDragStart={events.onDragStart}
        onDragEnd={events.onDragEnd}
        onClick={events.onClick}
        isEditable={isEditable}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
      />
    )
  } else if (
    eventTrigger.objectType === EEventTriggerType.regionOfInterest ||
    eventTrigger.objectType === EEventTriggerType.virtualDoor ||
    eventTrigger.objectType === EEventTriggerType.originDestinationZone
  ) {
    return (
      <Region
        key={`${eventTrigger.coordinates}-${key}`}
        eventTrigger={eventTrigger}
        stage={stage}
        onDragStart={events.onDragStart}
        onDragEnd={events.onDragEnd}
        onClick={events.onClick}
        isEditable={isEditable}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
      />
    )
  }

  return null
}

export default CanvasWrapper
