import { EConfigurationStatus } from './configurationStatus'

export enum ECameraConfigurationType {
  granularCameraConfiguration = 'GranularCameraConfiguration',
  rawCameraConfiguration = 'RawCameraConfiguration',
  usbCameraConfiguration = 'UsbCameraConfiguration'
}

export interface ICameraConfiguration {
  boxId?: string
  streamId?: string
  objectType?: string
  configurationType: ECameraConfigurationType
  status?: EConfigurationStatus
  rawConnectionURI?: string
  username?: string
  password?: string
  host?: string
  port?: number
  path?: string
  eventTriggers?: []
}

export const DefaultCameraConfiguration: ICameraConfiguration = {
  objectType: 'CameraConfiguration',
  status: undefined,
  configurationType: ECameraConfigurationType.granularCameraConfiguration,
  rawConnectionURI: undefined,
  username: '',
  password: '',
  host: '',
  port: undefined,
  path: undefined,
  eventTriggers: undefined
}
