import {
  GET_CAMERA_FRAME,
  GET_CAMERA_FRAME_FAILURE,
  GET_CAMERA_FRAME_SUCCESS,
  RESET_CAMERA_FRAME
} from './actionTypes'
import axios, { Canceler } from 'axios'
import { Config } from '../../services/config'
import { client, Schemas } from '../middleware/api'
import { normalize } from 'normalizr'
import { getIdToken } from '../../services/ApiTokenProvider'

/**
 * ============================
 * CAMERA FRAME ACTIONS
 * ============================
 */

const CancelToken = axios.CancelToken
let cancelRequest: Canceler | undefined

/**
 * Resets the camera frame.
 */
export const resetCameraFrame = () => ({
  type: RESET_CAMERA_FRAME
})

/**
 * Fetches a single camera frame from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 */
const fetchCameraFrame = async (boxId, streamId, calibration) => {
  const timestamp = new Date().getTime()
  const idToken = await getIdToken()

  let url = `boxes/${boxId}/${streamId}/getFrame?t=${timestamp}`
  if (calibration) {
    url = `boxes/${boxId}/${streamId}/getFrame?calibration=${calibration}&t=${timestamp}`
  }
  return client({
    url: url,
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelRequest = c
    })
  })
}

// Timeout function after which the request will be retried
let retryTimeout: NodeJS.Timeout | undefined

/**
 * Fetches a single camera frame from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 */
export const loadCameraFrame = (
  boxId,
  streamId,
  forceRefresh: boolean,
  calibration?: boolean,
  retries?: number
) => async (dispatch, getState) => {
  if (!boxId || !streamId) {
    return null
  }

  // Cancel any ongoing frame requests
  if (cancelRequest) {
    cancelRequest()
  }

  if (retryTimeout) {
    clearTimeout(retryTimeout)
  }

  // Set max number of retries
  if (typeof retries === 'undefined') {
    retries = Config.CAMERA_FRAME_REQUEST_MAX_RETRIES
  }

  const currentTimestamp = new Date().getTime()
  const { cameraFrames } = getState()

  // Check cache
  if (!forceRefresh && cameraFrames.allIds.indexOf(streamId) > -1) {
    const frame = cameraFrames.byIds[streamId]

    // Check if cached frame is expired
    if (frame.timestamp > currentTimestamp - Config.CAMERA_FRAME_CACHE_TIME) {
      return Promise.resolve(frame)
    }
  }

  dispatch({ type: GET_CAMERA_FRAME, id: streamId })

  return fetchCameraFrame(boxId, streamId, calibration)
    .then((response) => {
      // Frame received => continue
      if (response.status === 200) {
        const data = response.data
        data.id = streamId

        const payload = Object.assign({}, normalize(data, Schemas.FRAME))
        return dispatch({ type: GET_CAMERA_FRAME_SUCCESS, response: payload })
      }

      // Retry request in case the API responded with 204 and the
      // maximum amount of retries has not been exceeded
      if (response.status === 204 && retries && retries > 0) {
        retries = retries - 1

        // Try reloading after timeout as box is not ready
        retryTimeout = setTimeout(() => {
          dispatch(
            loadCameraFrame(boxId, streamId, forceRefresh, calibration, retries)
          )
        }, Config.CAMERA_FRAME_REQUEST_INTERVAL)

        return
      }

      // server could not produce valid image response in retry window, i.e. device not fully synced for reasons
      if (response.status === 204 && retries && retries === 0) {
        dispatch({
          type: GET_CAMERA_FRAME_FAILURE,
          id: streamId
        })
        return
      }

      throw Error(`Retrieving camera frame failed unexpectedly`)
    })
    .catch((error) => {
      // Prevent cancelled requests from updating the state
      if (axios.isCancel(error)) {
        return
      }

      // this also handles (axios error) 404. This happens in cases the deviceconfig is fully synced, but no image can be retrieved (wrong camera config?)
      dispatch({
        type: GET_CAMERA_FRAME_FAILURE,
        id: streamId
      })
    })
}
