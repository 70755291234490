import {
  DELETE_SCENE_REQUEST,
  DELETE_SCENE_REQUEST_FAILURE,
  DELETE_SCENE_REQUEST_SUCCESS,
  GET_ANPRIMAGE_REQUEST,
  GET_ANPRIMAGE_REQUEST_FAILURE,
  GET_ANPRIMAGE_REQUEST_SUCCESS,
  LIST_SCENES_CLINES_REQUEST,
  LIST_SCENES_CLINES_REQUEST_FAILURE,
  LIST_SCENES_CLINES_REQUEST_SUCCESS,
  LIST_SCENES_GROUP_REQUEST,
  LIST_SCENES_GROUP_REQUEST_FAILURE,
  LIST_SCENES_GROUP_REQUEST_SUCCESS,
  LIST_SCENES_REGIONS_REQUEST,
  LIST_SCENES_REGIONS_REQUEST_FAILURE,
  LIST_SCENES_REGIONS_REQUEST_SUCCESS,
  LIST_SCENES_REQUEST,
  LIST_SCENES_REQUEST_FAILURE,
  LIST_SCENES_REQUEST_SUCCESS,
  LIST_SCENES_SPEEDLINES_REQUEST,
  LIST_SCENES_SPEEDLINES_REQUEST_FAILURE,
  LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS,
  SAVE_SCENE_REQUEST,
  SAVE_SCENE_REQUEST_FAILURE,
  SAVE_SCENE_REQUEST_SUCCESS
} from './actionTypes'
import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'

import { IGroup } from '../../types/group'
import { log } from '../../services/log'
import { IScene } from '../../types/scene'

/**
 * ===========
 * SOLUTION SCENE ACTIONS
 * ===========
 */

/**
 * Creates or updates the scene via API request.
 * @param scene: The scene to save.
 */
export const createUpdateScene = (scene: IScene) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const stateScene = state.scenes.byId[scene.id]
  if (
    scene?.parkingSettings?.utilization &&
    stateScene?.parkingSettings?.utilization ===
      parseInt(String(scene.parkingSettings.utilization))
  ) {
    delete scene.parkingSettings?.utilization
  }
  const result = await dispatch({
    [CALL_API]: {
      types: [
        SAVE_SCENE_REQUEST,
        SAVE_SCENE_REQUEST_SUCCESS,
        SAVE_SCENE_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: scene,
      endpoint: `solutions/scenes/${scene.id}`,
      schema: Schemas.SCENE
    }
  })
  if (result.type !== SAVE_SCENE_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * Deletes the scene via API request.
 * @param scene: The scene to delete.
 *
 */
export const deleteScene = (scene: IScene) => async (dispatch, getState) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        DELETE_SCENE_REQUEST,
        DELETE_SCENE_REQUEST_SUCCESS,
        DELETE_SCENE_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.DELETE,
      endpoint: `solutions/scenes/${scene.id}`,
      id: scene.id
    }
  })
  if (result.type !== DELETE_SCENE_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * List all scenes via API request.
 */
export const listScenes = () => async (dispatch, getState) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        LIST_SCENES_REQUEST,
        LIST_SCENES_REQUEST_SUCCESS,
        LIST_SCENES_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `solutions/scenes`
    }
  })
  if (result.type !== LIST_SCENES_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * List all scenes in a group via API request.
 * @param group: The requested group.
 */
export const listScenesInGroup = (group: IGroup) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        LIST_SCENES_GROUP_REQUEST,
        LIST_SCENES_GROUP_REQUEST_SUCCESS,
        LIST_SCENES_GROUP_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `solutions/groups/${group.id}/scenes`,
      id: group.id
    }
  })
  if (result.type !== LIST_SCENES_GROUP_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * List all crossing lines for a scene via API request.
 * @param scene: The requested scene.
 */
export const listCrossinglinesInScene = (scene: IScene) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        LIST_SCENES_CLINES_REQUEST,
        LIST_SCENES_CLINES_REQUEST_SUCCESS,
        LIST_SCENES_CLINES_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `solutions/scenes/${scene.id}/crossinglines`,
      id: scene.id
    }
  })
  if (result.type !== LIST_SCENES_CLINES_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * List all speed lines for a scene via API request.
 * @param scene: The requested scene.
 */
export const listSpeedlinesInScene = (scene: IScene) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        LIST_SCENES_SPEEDLINES_REQUEST,
        LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS,
        LIST_SCENES_SPEEDLINES_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `solutions/scenes/${scene.id}/speedlines`,
      id: scene.id
    }
  })
  if (result.type !== LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * List all regions of interest in a scene via API request.
 * @param scene: The requested scene.
 */
export const listRegionsOfInterestInScene = (scene: IScene) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        LIST_SCENES_REGIONS_REQUEST,
        LIST_SCENES_REGIONS_REQUEST_SUCCESS,
        LIST_SCENES_REGIONS_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `solutions/scenes/${scene.id}/regions`,
      id: scene.id
    }
  })
  if (result.type !== LIST_SCENES_REGIONS_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * Get the image Urls for a list of ANPR images.
 *
 * @param imageIds: [string] List of image UUIDs.
 */
export const getAnprImageUrl = (imageIds: string[]) => ({
  [CALL_API]: {
    types: [
      GET_ANPRIMAGE_REQUEST,
      GET_ANPRIMAGE_REQUEST_SUCCESS,
      GET_ANPRIMAGE_REQUEST_FAILURE
    ],
    method: HTTP_METHOD.POST,
    payload: imageIds,
    endpoint: `solutions/scenes/anprimages`
  }
})
