import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import {
  MQTT_CONFIG_REQUEST,
  MQTT_CONFIG_REQUEST_FAILURE,
  MQTT_CONFIG_REQUEST_SUCCESS,
  SAVE_MQTT_CONFIG_REQUEST,
  SAVE_MQTT_CONFIG_REQUEST_FAILURE,
  SAVE_MQTT_CONFIG_REQUEST_SUCCESS
} from './actionTypes'

import { EConfigurationStatus } from '../../types/configurationStatus'
import { IMqttConfiguration } from '../../types/mqtt_configuration'

/**
 * ==========================
 * MQTT CONFIGURATION ACTIONS
 * ==========================
 */

/**
 * Fetches a single configuration from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 * @param boxId
 * @param streamId
 */
const fetchMqttConfig = (boxId, streamId) => ({
  [CALL_API]: {
    types: [
      MQTT_CONFIG_REQUEST,
      MQTT_CONFIG_REQUEST_SUCCESS,
      MQTT_CONFIG_REQUEST_FAILURE
    ],
    endpoint: `boxes/${boxId}/${streamId}/configurations/mqtt`,
    schema: Schemas.CONFIG,
    id: streamId
  }
})

/**
 * Fetches a single configuration from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 * @param boxId
 * @param streamId
 */
export const loadMqttConfig = (boxId, streamId) => (dispatch, getState) => {
  const { mqttConfigurations } = getState()

  if (!boxId || !streamId) {
    return null
  }

  if (mqttConfigurations.allIds.indexOf(streamId) > -1) {
    return Promise.resolve(mqttConfigurations.byIds[streamId])
  }

  return dispatch(fetchMqttConfig(boxId, streamId)).then(
    (data) => data.response.entities.configurations[data.response.result]
  )
}

/**
 * Save a configuration via the API.
 * Will use PUT if an id is provided, POST if not.
 * @param config: CONFIG The configuration data to be saved.
 */
export const saveMqttConfig = (config: IMqttConfiguration) => {
  config.status = EConfigurationStatus.desired

  return {
    [CALL_API]: {
      types: [
        SAVE_MQTT_CONFIG_REQUEST,
        SAVE_MQTT_CONFIG_REQUEST_SUCCESS,
        SAVE_MQTT_CONFIG_REQUEST_FAILURE
      ],
      method:
        typeof config.boxId !== 'undefined' &&
        config.boxId !== '' &&
        typeof config.streamId !== 'undefined' &&
        config.streamId !== ''
          ? HTTP_METHOD.PUT
          : HTTP_METHOD.POST,
      payload: config,
      endpoint:
        typeof config.boxId !== 'undefined' &&
        config.boxId !== '' &&
        typeof config.streamId !== 'undefined' &&
        config.streamId !== ''
          ? `boxes/${config.boxId}/${config.streamId}/configurations/mqtt`
          : 'configurations',
      schema: Schemas.CONFIG
    }
  }
}
