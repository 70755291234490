import React, { FunctionComponent, memo, useCallback, useState } from 'react'
import './ResultSetOverride'
import PropTypes from 'prop-types'
import { useCubeQuery } from '@cubejs-client/react'

import { Col, Empty, Row, Statistic, Table } from 'antd'
import { ResponsiveChord } from '@nivo/chord'
import { ComputedCell, ResponsiveHeatMap } from '@nivo/heatmap'
import { Chip, TableTooltip as ChordTooltip } from '@nivo/tooltip'
import { useTheme } from '@nivo/core'

import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { ResultSet } from '@cubejs-client/core'
import moment from 'moment-timezone'
import LoadingAnimation from '../../components/LoadingAnimation'
import {
  addCustomRenderMethods,
  AnprSampleExport,
  CSVExport,
  renderActiveShape
} from './ChartRendererHelper'
import { useTranslation } from 'react-i18next'

const dateFormatter = (key, item, timezone) => {
  if (!item) return ''

  let split = key && key.split('.')
  let aggregation = split && split[split.length - 1]

  let format = 'YYYY-MM-DD HH:mm:ss'
  if (aggregation === 'day') {
    format = 'YYYY-MM-DD'
  } else if (aggregation === 'week') {
    format = 'YYYY-[W]WW'
  } else if (aggregation === 'month') {
    format = 'YYYY-MM'
  } else if (aggregation === 'year') {
    format = 'YYYY'
  }

  let timeData = moment.utc(item)
  if (timezone === 'Etc/ETC') {
    format += '[\u00a0(UTC)]' // non-breaking space
  } else {
    timeData = timeData.tz(timezone)
  }

  return timeData.format(format) // https://momentjs.com/timezone/docs/#/using-timezones/
}

const durationFormatterMin = (item) =>
  durationFormatter(
    moment.utc(moment.duration(item, 'minutes').asMilliseconds())
  )
const durationFormatter = (item) =>
  `${parseInt(item.format('DDD')) - 1}d ${item.format('H[h] m[m]')}`

const getCustomizedXAxisTick = (granularity, timezone) => {
  return ({ x, y, stroke, payload }) => {
    return (
      <Text
        x={x}
        y={y}
        width={5}
        fontSize="0.90em"
        textAnchor="middle"
        verticalAnchor="start"
      >
        {xAxisTickFormatter(granularity, payload.value, timezone)}
      </Text>
    )
  }
}

const xAxisTickFormatter = (granularity, item, timezone) => {
  if (moment(item).isValid()) {
    return dateFormatter(granularity, item, timezone)
  } else {
    return item.split(',').join(' ')
  }
}

const getLabelFormatter = (granularity, timezone) => {
  return (item) => {
    if (moment(item).isValid()) {
      return dateFormatter(granularity, item, timezone)
    } else {
      return item
    }
  }
}

type CartesianChartProps = {
  ChartComponent: any
  resultSet: ResultSet
}

const CartesianChart: FunctionComponent<CartesianChartProps> = ({
  resultSet,
  children,
  ChartComponent
}) => {
  const isCategorigal =
    resultSet.chartPivot() &&
    resultSet.chartPivot().length > 0 &&
    !moment(resultSet.chartPivot()[0]['x']).isValid()

  const granularity =
    resultSet['loadResponse'].pivotQuery.timeDimensions[0].granularity

  return (
    <>
      <ResponsiveContainer height={350}>
        <ChartComponent data={resultSet.chartPivot()}>
          <XAxis
            dataKey="x"
            minTickGap={20}
            tick={getCustomizedXAxisTick(
              granularity,
              resultSet.query().timezone
            )}
            height={40}
            interval={isCategorigal ? 0 : 'preserveStartEnd'}
          />
          <YAxis />
          <CartesianGrid />
          {children}
          <Legend />
          <Tooltip
            labelFormatter={getLabelFormatter(
              granularity,
              resultSet.query().timezone
            )}
            cursor={{ stroke: 'none', fill: 'rgba(211,211,211,0.8' }}
          />
        </ChartComponent>
      </ResponsiveContainer>
      <CSVExport resultSet={resultSet} />
    </>
  )
}

type OccupancyChartProps = {
  resultSet: ResultSet
}

const OccupancyChart: FunctionComponent<OccupancyChartProps> = ({
  resultSet
}) => {
  const rawData = resultSet.tablePivot()
  if (rawData.length === 0) {
    return <Empty />
  }
  const nameKey = 'SpacebasedParkingUtilization.roiName'
  const occupiedKey = 'SpacebasedParkingUtilization.occupied'
  const capacityKey = 'SpacebasedParkingUtilization.capacity'

  rawData.sort((a, b) =>
    a[nameKey].toString().localeCompare(b[nameKey].toString())
  )

  //max 10 column
  const maxColumns = 10
  const blocksColumns = Math.min(
    Math.ceil(Math.sqrt(rawData.length)),
    maxColumns
  )
  const blocksRows = Math.ceil(rawData.length / blocksColumns)
  let chartData: any[] = []
  let dataIndex = 0
  for (let rowIndex = 1; rowIndex <= blocksRows; rowIndex++) {
    let data: any[] = []
    let chartRow = {
      id: rowIndex,
      data: data
    }
    for (let columnIndex = 1; columnIndex <= blocksColumns; columnIndex++) {
      if (dataIndex > rawData.length - 1) {
        chartRow.data.push({
          x: columnIndex
        })
        continue
      }

      const entry = rawData[dataIndex]
      let occ = entry[occupiedKey] ? (entry[occupiedKey] as number) : 0
      let capacity = entry[capacityKey] ? (entry[capacityKey] as number) : 0
      let usage
      if (capacity === 0 && occ > 0) {
        usage = 100.0
        capacity = 0
      } else if (capacity === 0 && occ === 0) {
        usage = 0.0
        capacity = 0
        occ = 0
      } else {
        usage = (occ / capacity) * 100.0
      }
      let dataEntry = {
        x: columnIndex,
        y: usage,
        capacity: capacity as number,
        occupancy: occ as number,
        name: entry[nameKey] as string
      }
      chartRow.data.push(dataEntry)
      dataIndex = dataIndex + 1
    }
    chartData.push(chartRow)
  }

  const parkingLabel = (value) => {
    if (!value.data.name) {
      return ''
    }
    if (value.data.name.length > 15) {
      return value.data.name.substring(0, 3) + '...'
    } else {
      return value.data.name
    }
  }

  const OccupancyTooltip = ({ cell }: { cell: ComputedCell<any> }) => {
    return (
      <div
        style={{
          backgroundColor: '#ffffff',
          padding: '6px 9px',
          borderRadius: '2px',
          minWidth: '160px',
          boxShadow: '0 3px 5px rgba(0, 0, 0, .25)',
          whiteSpace: 'pre'
        }}
      >
        {'Name '}&nbsp;<strong>{cell.label}</strong>
        <br />
        {'Capacity '}&nbsp;<strong>{cell.data.capacity}</strong>
        <br />
        {'Occupancy '}&nbsp;<strong>{cell.formattedValue}</strong>
        {'%'}&nbsp;
        <br />
        {'Occupancy (absolute)'}&nbsp;<strong>{cell.data.occupancy}</strong>
        <br />
      </div>
    )
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={350}>
        <ResponsiveHeatMap
          data={chartData}
          margin={{ top: 10, right: 10, bottom: 60, left: 10 }}
          axisTop={null}
          axisLeft={null}
          axisRight={null}
          valueFormat=">-.2s"
          colors={{
            type: 'diverging',
            scheme: 'red_yellow_green',
            minValue: 100,
            maxValue: 0,
            divergeAt: 0.5
          }}
          emptyColor="#ffffff"
          labelTextColor="black"
          label={parkingLabel}
          opacity={0.9}
          inactiveOpacity={0.7}
          cellComponent={'circle'}
          forceSquare
          hoverTarget="cell"
          tooltip={OccupancyTooltip}
          legends={[
            {
              anchor: 'bottom',
              translateX: 0,
              translateY: 30,
              length: 300,
              thickness: 8,
              direction: 'row',
              tickPosition: 'after',
              tickSize: 3,
              tickSpacing: 4,
              tickOverlap: false,
              tickFormat: ' >-.2s',
              title: 'Occupancy %',
              titleAlign: 'start',
              titleOffset: 4
            }
          ]}
        />
      </ResponsiveContainer>
      <CSVExport resultSet={resultSet} />
    </>
  )
}

type UtilizationChartProps = {
  resultSet: ResultSet
}

const UtilizationChart: FunctionComponent<UtilizationChartProps> = ({
  resultSet
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  const chartKeys = ['free', 'occupied']
  const chartColors = { free: colors[0], occupied: colors[2] }
  const numberKey = 'capacity'

  let chartData: any = []
  let numberValue: any = undefined
  let numberTitle: any = undefined

  const rawData = resultSet.tablePivot()[0]
  Object.keys(rawData).forEach((key) => {
    let subKey = key.split('.')[1]
    if (chartKeys.includes(subKey)) {
      chartData.push({
        key: subKey,
        name: resultSet.annotation().measures[key]['shortTitle'],
        description: resultSet.annotation().measures[key]['description'],
        value: Math.max(parseInt(String(rawData[key])), 0),
        fill: chartColors[subKey]
      })
    }
    if (numberKey === subKey) {
      numberValue = rawData[key]
      numberTitle = resultSet.annotation().measures[key].shortTitle
    }
  })

  if (isNaN(chartData[0].value) || isNaN(chartData[1].value)) {
    return <Empty />
  }
  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            startAngle={180}
            endAngle={0}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={chartData}
            cy="80%"
            innerRadius="70%"
            outerRadius="100%"
            dataKey="value"
            onMouseEnter={onPieEnter}
          />
        </PieChart>
      </ResponsiveContainer>
      {numberValue && (
        <Row
          justify="center"
          align="middle"
          style={{
            height: '100%'
          }}
        >
          <Col>
            <Statistic
              title={numberTitle}
              value={numberValue}
              style={{ textAlign: 'center' }}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

// https://www.carbondesignsystem.com/data-visualization/color-palettes/
const colors = [
  '#00a86b',
  '#1192e8',
  '#9f1853',
  '#fa4d56',
  '#6929c4',
  '#002d9c',
  '#ee538b',
  '#b28600',
  '#009d9a',
  '#005d5d',
  '#012749',
  '#8a3800',
  '#a56eff'
]

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
        {resultSet.completeSeriesNames().map((series, i) => (
          <Line
            key={series.key}
            dataKey={series.key}
            name={series.title}
            stroke={colors[i]}
            strokeWidth={2}
          />
        ))}
      </CartesianChart>
    )
  },
  chord: ({ resultSet, pivotConfig }) => {
    const odData = resultSet.tablePivot(pivotConfig)
    const chordEndpointFields = resultSet
      .tableColumns(pivotConfig)
      .filter((column) => column.type === 'string')
    const connectionValueFields = resultSet
      .tableColumns(pivotConfig)
      .filter((column) => column.type === 'number')

    // wrong data setup. an OD chord visualzation is only useful between at least two nodes with a numeric connection
    if (
      !(chordEndpointFields.length >= 2 && connectionValueFields.length >= 1)
    ) {
      return <Empty />
    }

    //first build up all possible values for the key combinations
    let keys = new Set<string>()
    let values = new Map<string, Map<string, number>>()
    odData.forEach((entry) => {
      let firstEndpoint = chordEndpointFields[0]
      let secondEndpoint = chordEndpointFields[1]
      let firstEndpointValue = entry[firstEndpoint.key]
      let secondEndpointValue = entry[secondEndpoint.key]
      let connectionValue = entry[connectionValueFields[0].key]

      keys.add(firstEndpointValue)
      keys.add(secondEndpointValue)

      if (!values.has(firstEndpointValue)) {
        values.set(firstEndpointValue, new Map<string, number>())
      }
      let assignmentMap = values.get(firstEndpointValue)
      if (assignmentMap && !assignmentMap.has(secondEndpointValue)) {
        assignmentMap.set(secondEndpointValue, connectionValue)
      }
    })

    //build flow matrix
    const keyArray = Array.from(keys.values())
    let valueMatrix: number[][] = [[]]
    for (let keyIndex = 0; keyIndex < keyArray.length; keyIndex++) {
      valueMatrix[keyIndex] = []
      let key = keyArray[keyIndex]
      for (
        let combinationIndex = 0;
        combinationIndex < keyArray.length;
        combinationIndex++
      ) {
        let combination = keyArray[combinationIndex]
        valueMatrix[keyIndex][combinationIndex] =
          values.get(key)?.get(combination) || 0
      }
    }

    //most nivo components do not have nice typescript definitions yet :(
    // @ts-ignore
    const ChordRibbonTooltip = memo(({ ribbon }) => {
      const theme = useTheme()
      return (
        <ChordTooltip
          // @ts-ignore
          theme={theme}
          rows={[
            [
              <Chip key="chip" color={ribbon.source.color} />,
              <strong key="id">
                {ribbon.source.label} / {ribbon.target.label}
              </strong>,
              ribbon.source.formattedValue
            ],
            [
              <Chip key="chip" color={ribbon.target.color} />,
              <strong key="id">
                {ribbon.target.label} / {ribbon.source.label}
              </strong>,
              ribbon.target.formattedValue
            ]
          ]}
        />
      )
    })

    return (
      <>
        {odData.length > 0 ? (
          <ResponsiveContainer width="95%" height={350}>
            <ResponsiveChord
              data={valueMatrix}
              keys={keyArray}
              margin={{ top: 0, right: 10, bottom: 75, left: 10 }}
              padAngle={0.02}
              enableLabel={false}
              innerRadiusRatio={0.96}
              innerRadiusOffset={0.02}
              ribbonTooltip={ChordRibbonTooltip}
              arcBorderWidth={1}
              arcBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
              ribbonOpacity={0.5}
              ribbonBorderWidth={1}
              ribbonBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.4]]
              }}
              colors={{ scheme: 'category10' }}
              isInteractive={true}
              activeArcOpacity={1}
              arcOpacity={0.25}
              activeRibbonOpacity={0.75}
              inactiveRibbonOpacity={0.25}
              animate={true}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 70,
                  itemWidth: 100,
                  itemHeight: 14,
                  itemsSpacing: 20,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  symbolSize: 12,
                  symbolShape: 'square',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          </ResponsiveContainer>
        ) : (
          <Empty />
        )}

        <CSVExport resultSet={resultSet} />
      </>
    )
  },
  bar: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
        {resultSet.completeSeriesNames().map((series, i) => (
          <Bar
            key={series.key}
            stackId="a"
            dataKey={series.key}
            name={series.title}
            fill={colors[i]}
          />
        ))}
      </CartesianChart>
    )
  },
  area: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
        {resultSet.completeSeriesNames().map((series, i) => (
          <Area
            key={series.key}
            stackId="a"
            dataKey={series.key}
            name={series.title}
            stroke={colors[i]}
            fill={colors[i]}
          />
        ))}
      </CartesianChart>
    )
  },
  pie: ({ resultSet, pivotConfig, widgetTitle, t }) => {
    if (!resultSet.completeSeriesNames().length) {
      return <Empty />
    }
    return (
      <>
        <ResponsiveContainer width="95%" height={350}>
          {resultSet.chartPivot().length <= 16 ? (
            <PieChart>
              <Pie
                isAnimationActive={false}
                data={resultSet.chartPivot()}
                nameKey="x"
                dataKey={resultSet.completeSeriesNames()[0].key}
                fill="#8884d8"
              >
                {resultSet.chartPivot().map((e, index) => (
                  <Cell key={index} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          ) : (
            <p className={'scc--centered'}>{t('widgets.tooManyDimensions')}</p>
          )}
        </ResponsiveContainer>
        <CSVExport resultSet={resultSet} />
      </>
    )
  },
  number: ({ resultSet }) => {
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100%'
        }}
      >
        <Col>
          {resultSet.completeSeriesNames().map((s) => (
            <Statistic
              key={s.key}
              value={
                resultSet.totalRow()[s.key] !== null
                  ? resultSet.totalRow()[s.key]
                  : 'No Data'
              }
            />
          ))}
        </Col>
      </Row>
    )
  },
  table: ({ resultSet, pivotConfig, widgetTitle }) => {
    let normalizedResult = resultSet.tablePivot(pivotConfig)
    normalizedResult.map((v) => {
      let formatDate = Object.keys(v).filter(function (propertyName) {
        return (
          ~propertyName.indexOf('timestamp') || ~propertyName.indexOf('Time')
        )
      })
      let formatDuration = Object.keys(v).filter(function (propertyName) {
        return (
          ~propertyName.indexOf('duration') || ~propertyName.indexOf('Duration')
        )
      })
      formatDate.forEach(
        (index) =>
          (v[index] = dateFormatter(
            index,
            v[index],
            resultSet.query().timezone
          ))
      )
      formatDuration.forEach(
        (index) => (v[index] = durationFormatterMin(v[index]))
      )
      return v
    })

    let tableColums = resultSet.tableColumns(pivotConfig)
    tableColums.map((c) => {
      c.ellipsis = {
        showTitle: false
      }
      c = addCustomRenderMethods(c)
      return c
    })

    return (
      <>
        <Table
          columns={tableColums}
          dataSource={normalizedResult}
          pagination={{
            position: ['bottomCenter'],
            pageSize: 10,
            showSizeChanger: false
          }}
          rowKey={(record) => JSON.stringify(record)}
        />
        <CSVExport resultSet={resultSet} />
        <AnprSampleExport resultSet={resultSet} widgetTitle={widgetTitle} />
      </>
    )
  },
  utilization: ({ resultSet }) => {
    return <UtilizationChart resultSet={resultSet} />
  },
  occupancymap: ({ resultSet }) => {
    return <OccupancyChart resultSet={resultSet} />
  }
}
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }))

const renderChart = (Component) => ({
  resultSet,
  error,
  pivotConfig,
  widgetTitle,
  t
}) =>
  (resultSet && (
    <Component
      resultSet={resultSet}
      pivotConfig={pivotConfig}
      widgetTitle={widgetTitle}
      t={t}
    />
  )) ||
  (error && error.toString()) || <LoadingAnimation />

interface IChartRenderer {
  vizState: any
  overrideTimeRange?: any
  overrideTimeZone?: any
  widgetTitle?: string // widget title is needed for the ANPR image download
}

const ChartRenderer = (props: IChartRenderer) => {
  const { t } = useTranslation(['datadiscovery'])
  const { query, chartType, pivotConfig } = props.vizState
  const component = TypeToMemoChartComponent[chartType]

  if (props.overrideTimeRange) {
    query.timeDimensions.forEach((dimension) => {
      dimension.dateRange = props.overrideTimeRange
    })
  }
  query.timezone = props.overrideTimeZone ? props.overrideTimeZone : 'Etc/ETC'

  const renderProps = useCubeQuery(query, {
    subscribe: chartType === 'utilization' || chartType === 'occupancymap'
  })
  const widgetTitle = props.widgetTitle
  return (
    component &&
    renderChart(component)({ ...renderProps, pivotConfig, widgetTitle, t })
  )
}

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object
}
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null
}
export default ChartRenderer
