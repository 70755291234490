import React, { Component } from 'react'

import BoxList from '../components/BoxList'
import { IBox } from '../types/box'
import { connect } from 'react-redux'
import { loadBoxes } from '../redux/actions/boxes'
import { withRouter } from 'react-router-dom'

const LOCAL_STORAGE_AUTOREFRESH = 'deviceAutoRefresh'
const AUTOREFRESH_INTERVAL = 10000

interface IBoxesPageProps {
  children?
  boxes: IBox[]
  boxesFetched: boolean
  loadBoxes: Function
  loadBoxDetails: Function
}

interface IBoxesPageState {
  autoRefresh: boolean
}

class BoxesPage extends Component<IBoxesPageProps, IBoxesPageState> {
  private refresh: number | undefined
  constructor(props) {
    super(props)
    this.state = { autoRefresh: true }
  }

  componentDidMount() {
    this.props.loadBoxes()
    let storageautoRefresh = localStorage.getItem(
      `${LOCAL_STORAGE_AUTOREFRESH}`
    )
    if (storageautoRefresh === 'false') {
      this.setState({
        autoRefresh: false
      })
    } else {
      this.startAutoRefresh()
    }
  }

  componentWillUnmount() {
    this.stopAutoRefresh()
  }

  startAutoRefresh = () => {
    if (!this.refresh) {
      this.refresh = setInterval(this.props.loadBoxes, AUTOREFRESH_INTERVAL)
    }
  }

  stopAutoRefresh = () => {
    if (!this.refresh) {
      return
    }
    clearInterval(this.refresh)
    this.refresh = undefined
  }

  setAutoRefresh = (value) => {
    if (value) {
      this.startAutoRefresh()
    } else {
      this.stopAutoRefresh()
    }

    this.setState({
      autoRefresh: value
    })
    localStorage.setItem(`${LOCAL_STORAGE_AUTOREFRESH}`, value)
  }

  render() {
    const { boxes, boxesFetched } = this.props
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    boxes.sort(function (lhs, rhs) {
      // eslint-disable-next-line eqeqeq
      if (lhs.name == undefined) {
        return 1
      }
      // eslint-disable-next-line eqeqeq
      if (rhs.name == undefined) {
        return -1
      }
      return collator.compare(lhs.name, rhs.name)
    })
    return (
      <BoxList
        boxes={boxes}
        boxesFetched={boxesFetched}
        autoRefresh={this.state.autoRefresh}
        setAutoRefresh={this.setAutoRefresh}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    boxes: state.boxes.allIds.map((id) => state.boxes.byIds[id]),
    boxesFetched: state.boxes.boxesFetched
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadBoxes
  })(BoxesPage)
)
