import { IBox } from '../../../types/box'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  box: IBox
}

const NameIdCell: React.FC<IProps> = ({ box }) => {
  const { t } = useTranslation()
  return (
    <>
      <span className="scc--box_name">
        {box.name || t('boxList.dataTable.empty.deviceName')}
      </span>
      <br />
      <span className="scc--box_id">ID: {box.id}</span>
    </>
  )
}

export default NameIdCell
