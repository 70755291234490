import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Button, Card, Dropdown, Menu, Row, Space } from 'antd'
import {
  DashboardOutlined,
  LoginOutlined,
  PlusCircleOutlined,
  VerticalAlignMiddleOutlined
} from '@ant-design/icons'
import { EWidgetType } from '../../../../types/solution'
import SelectChartType from '../QueryBuilderComponents/SelectChartType'
import ChartRenderer from '../../../../datadiscovery/components/ChartRenderer'
import StreamSelection from '../../Helper/StreamSelection'
import { connect } from 'react-redux'
import CLSelection from '../../Helper/CLSelection'
import {
  listCrossinglinesInScene,
  listSpeedlinesInScene
} from '../../../../redux/actions/scenes'
import { ICrossinglineInfo, IScene } from '../../../../types/scene'
import GranularityFilter from '../QueryBuilderComponents/GranularityFilter'
import FilterData from '../QueryBuilderComponents/FilterData'
import { TCubeDimension } from '@cubejs-client/core'
import SegmentationFilter from '../QueryBuilderComponents/SegmentationFilter'
import {
  compareElements,
  getElementsByName,
  handleElementChange,
  updateFilter
} from './QueryBuilderUtility'
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_GRANULARITY
} from '../../../../datadiscovery/components/QueryBuilder/StateChangeHeuristics'
import SpeedToggle from '../../Helper/SpeedToggle'

const WidgetTypes = [
  EWidgetType.TrafficCounting,
  EWidgetType.OriginDestinationAnalysis,
  EWidgetType.SpeedEstimation
]

const WidgetTypeIcons = {
  [EWidgetType.TrafficCounting]: <VerticalAlignMiddleOutlined />,
  [EWidgetType.OriginDestinationAnalysis]: <LoginOutlined />,
  [EWidgetType.SpeedEstimation]: <DashboardOutlined />
}

const MeasureByWidgetType = {
  [EWidgetType.TrafficCounting]: [
    'CrossingEvents.count',
    'CrossingEvents.averageSpeed'
  ],
  [EWidgetType.OriginDestinationAnalysis]: 'OriginDestinationEvents.count',
  [EWidgetType.SpeedEstimation]: 'CrossingEvents.averageSpeed'
}

const DataFilterByWidgetType = {
  [EWidgetType.TrafficCounting]: 'CrossingEvents.lineId',
  [EWidgetType.OriginDestinationAnalysis]: 'OriginDestinationEvents.streamId',
  [EWidgetType.SpeedEstimation]: 'CrossingEvents.lineId'
}

const FilterByWidgetType = {
  [EWidgetType.TrafficCounting]: [
    'CrossingEvents.classification',
    'CrossingEvents.subclass',
    'CrossingEvents.direction'
  ],
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.classification',
    'OriginDestinationEvents.subclass'
  ]
}

const DimensionsByWidgetType = {
  [EWidgetType.TrafficCounting]: [
    'CrossingEvents.classification',
    'CrossingEvents.subclass',
    'CrossingEvents.direction',
    'CrossingEvents.timestamp',
    'CrossingEvents.lineName',
    'CrossingEvents.speed'
  ],
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.classification',
    'OriginDestinationEvents.subclass',
    'OriginDestinationEvents.exitTimestamp',
    'OriginDestinationEvents.entryZone',
    'OriginDestinationEvents.exitZone'
  ],
  [EWidgetType.SpeedEstimation]: ['CrossingEvents.lineName']
}

const DefaultDimensionsByWidgetType = {
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.entryZone',
    'OriginDestinationEvents.exitZone'
  ]
}

const ChartTypesByWidgetType = {
  [EWidgetType.TrafficCounting]: ['table', 'number', 'bar', 'pie'],
  [EWidgetType.OriginDestinationAnalysis]: ['table', 'chord', 'bar'],
  [EWidgetType.SpeedEstimation]: ['line', 'table', 'number']
}

const TimeDimensionsByWidgetType = {
  [EWidgetType.TrafficCounting]: 'CrossingEvents.timestamp',
  [EWidgetType.OriginDestinationAnalysis]:
    'OriginDestinationEvents.exitTimestamp',
  [EWidgetType.SpeedEstimation]: 'CrossingEvents.timestamp'
}

interface ITrafficQueryBuilder {
  crossingLinesInScene: ICrossinglineInfo[]
  speedLinesInScene: ICrossinglineInfo[]
  listCrossinglinesInScene: Function
  listSpeedlinesInScene: Function
  scene: IScene
  setValidQuery: Function
  widgetType: EWidgetType | undefined
  setWidgetType: Function
  isQueryPresent: any
  validatedQuery: any
  timeDimensions: any
  availableTimeDimensions: any
  updateTimeDimensions: any
  dimensions: any
  availableDimensions: any
  updateDimensions: any
  measures: any
  availableMeasures: any
  updateMeasures: any
  chartType: any
  updateChartType: any
  timeFilter: string
  filters: any
  updateFilters: any
  updateQuery: any
}

const TrafficQueryBuilder: React.FC<ITrafficQueryBuilder> = (props) => {
  const { t } = useTranslation()

  let [clFilter, setClFilter] = useState([])
  let [speedlineFilter, setSpeedlineFilter] = useState([])
  let [newDimensions, setNewDimensions] = useState<string[]>([])
  let [streamFilter, setStreamFilter] = useState([])
  let [newMeasure, setNewMeasure] = useState<string[]>([])

  const {
    listCrossinglinesInScene,
    listSpeedlinesInScene,
    scene,
    isQueryPresent,
    validatedQuery,
    setValidQuery,
    widgetType,
    chartType,
    setWidgetType
  } = props

  useEffect(() => {
    listCrossinglinesInScene(scene)
    listSpeedlinesInScene(scene)
  }, [listCrossinglinesInScene, listSpeedlinesInScene, scene, scene.id])

  useEffect(() => {
    setValidQuery(
      widgetType && isQueryPresent && Object.keys(validatedQuery).length > 0
    )
  }, [widgetType, isQueryPresent, validatedQuery, setValidQuery])

  useEffect(() => {
    // set current filters if widget is edited
    props.filters.forEach((filter) => {
      if (widgetType === EWidgetType.TrafficCounting) {
        if (
          filter.member ===
            DataFilterByWidgetType[EWidgetType.TrafficCounting] &&
          clFilter !== filter.values
        ) {
          setClFilter(filter.values)
        }
      } else if (widgetType === EWidgetType.OriginDestinationAnalysis) {
        if (
          filter.member ===
            DataFilterByWidgetType[EWidgetType.OriginDestinationAnalysis] &&
          streamFilter !== filter.values[0]
        ) {
          setStreamFilter(filter.values[0])
        }
      } else if (widgetType === EWidgetType.SpeedEstimation) {
        if (
          filter.member ===
            DataFilterByWidgetType[EWidgetType.SpeedEstimation] &&
          speedlineFilter !== filter.values
        ) {
          setSpeedlineFilter(filter.values)
        }
      }
    })

    // set current measurements if widget is edited
    if (newMeasure && !compareElements(props.measures, newMeasure)) {
      setNewMeasure(
        props.measures.map((item) => {
          return item.name
        })
      )
    }

    // set current dimensions if widget is edited
    if (newDimensions && !compareElements(props.dimensions, newDimensions)) {
      setNewDimensions(
        props.dimensions.map((item) => {
          return item.name
        })
      )
    }
  }, [
    props.filters,
    props.dimensions,
    props.measures,
    newDimensions,
    newMeasure,
    widgetType,
    clFilter,
    streamFilter,
    speedlineFilter
  ])

  // reset chart type if current type is not available
  useEffect(() => {
    if (
      widgetType &&
      !ChartTypesByWidgetType[widgetType].includes(props.chartType)
    ) {
      props.updateChartType(ChartTypesByWidgetType[widgetType][0])
    }
  })

  const handleNewClFilterChange = (data) => {
    setClFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name === DataFilterByWidgetType[EWidgetType.TrafficCounting]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleNewSpeedLineFilterChange = (data) => {
    setSpeedlineFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name === DataFilterByWidgetType[EWidgetType.SpeedEstimation]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleSpeedToggleChange = (checked, event) => {
    let data
    if (checked) {
      data = MeasureByWidgetType[EWidgetType.TrafficCounting]
    } else {
      data = ['CrossingEvents.count']
    }
    handleElementChange(
      props.availableMeasures,
      props.measures,
      props.updateMeasures,
      data
    )
  }

  const handleWidgetTypeChange = (item) => {
    let measures = MeasureByWidgetType[item]
    let times = TimeDimensionsByWidgetType[item]
    let dimensions = DefaultDimensionsByWidgetType[item]

    if (item === EWidgetType.TrafficCounting) {
      measures = MeasureByWidgetType[item]
    }

    props.updateQuery({
      measures: getElementsByName(measures, props.availableMeasures),
      dimensions: getElementsByName(dimensions, props.availableDimensions),
      segments: [],
      filters: [],
      timeDimensions: times
        ? [
            {
              dimension: times,
              granularity: DEFAULT_GRANULARITY,
              dateRange: DEFAULT_DATE_RANGE
            }
          ]
        : []
    })

    setWidgetType(item)
    setNewDimensions([])
    setNewMeasure(measures)
  }

  const handleNewStreamFilterChange = (data) => {
    setStreamFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name ===
        DataFilterByWidgetType[EWidgetType.OriginDestinationAnalysis]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data ? [data] : []
    })
  }

  const handleNewDimensionChange = (data) => {
    setNewDimensions(data)
    handleElementChange(
      props.availableDimensions,
      props.dimensions,
      props.updateDimensions,
      data
    )
  }

  const filteredDimensions: TCubeDimension[] =
    widgetType &&
    props.availableDimensions.filter(
      (dim) =>
        FilterByWidgetType[widgetType] &&
        FilterByWidgetType[widgetType].includes(dim.name)
    )

  const segmentationDimensions: TCubeDimension[] =
    widgetType &&
    props.availableDimensions.filter(
      (dim) =>
        DimensionsByWidgetType[widgetType] &&
        DimensionsByWidgetType[widgetType].includes(dim.name)
    )

  const typeFilteredFilters =
    widgetType &&
    props.filters.filter(
      (filter) =>
        DataFilterByWidgetType[widgetType] !== filter.member &&
        filter.member.split('.')[1] !== 'streamId'
    )

  const dimensionsSelected = !!(
    (widgetType === EWidgetType.TrafficCounting && clFilter.length) ||
    (widgetType === EWidgetType.OriginDestinationAnalysis &&
      streamFilter.length) ||
    (widgetType === EWidgetType.SpeedEstimation && speedlineFilter.length)
  )

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card title={t('solutions.scenes.overview.add.widgetType.title')}>
        <Dropdown
          overlay={
            <Menu>
              {WidgetTypes.map((item) => (
                <Menu.Item
                  key={item}
                  icon={WidgetTypeIcons[item]}
                  onClick={() => {
                    handleWidgetTypeChange(item)
                  }}
                >
                  {t(
                    `solutions.scenes.overview.add.widgetType.${item.toString()}`
                  )}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomLeft"
          trigger={['click']}
        >
          {widgetType ? (
            <Button icon={WidgetTypeIcons[widgetType]}>
              {t(
                `solutions.scenes.overview.add.widgetType.${widgetType.toString()}`
              )}
            </Button>
          ) : (
            <Button icon={<PlusCircleOutlined />}>
              {t('solutions.scenes.overview.add.widgetType.placeholder')}
            </Button>
          )}
        </Dropdown>
      </Card>

      {widgetType && (
        <>
          <Card title={t('solutions.scenes.overview.add.data.title')}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <p>
                {t(`solutions.scenes.overview.add.data.headline.${widgetType}`)}
              </p>

              {widgetType === EWidgetType.TrafficCounting && (
                <CLSelection
                  crossingLines={props.crossingLinesInScene}
                  newCrossingLines={clFilter}
                  handleCrossingLinesChange={handleNewClFilterChange}
                  multi={true}
                />
              )}

              {widgetType === EWidgetType.OriginDestinationAnalysis && (
                <StreamSelection
                  placeholder={t('solutions.scenes.overview.add.data.stream')}
                  streams={scene.boxStreams}
                  newStreams={streamFilter}
                  handleNewStreamsChange={handleNewStreamFilterChange}
                  multi={false}
                />
              )}

              {widgetType === EWidgetType.SpeedEstimation && (
                <CLSelection
                  crossingLines={props.speedLinesInScene}
                  newCrossingLines={speedlineFilter}
                  handleCrossingLinesChange={handleNewSpeedLineFilterChange}
                  multi={true}
                />
              )}

              {widgetType === EWidgetType.TrafficCounting && (
                <>
                  <SpeedToggle
                    measures={props.measures}
                    handleSpeedToggleChange={handleSpeedToggleChange}
                  ></SpeedToggle>
                </>
              )}

              <Row>
                <GranularityFilter
                  timeDimensions={props.timeDimensions}
                  updateTimeDimension={props.updateTimeDimensions}
                />
              </Row>
              <Row>
                <SegmentationFilter
                  key={props.dimensions}
                  segmentationDimensions={segmentationDimensions}
                  dimensions={newDimensions}
                  handleDimensionChange={handleNewDimensionChange}
                />
              </Row>
              <Row>
                <FilterData
                  filters={typeFilteredFilters}
                  updateFilters={props.updateFilters}
                  filteredDimensions={filteredDimensions}
                />
              </Row>
            </Space>
          </Card>

          <SelectChartType
            availableChartNames={ChartTypesByWidgetType[widgetType]}
            chartType={props.chartType}
            updateChartType={props.updateChartType}
          />
        </>
      )}

      {widgetType &&
        dimensionsSelected &&
        props.isQueryPresent &&
        Object.keys(props.validatedQuery).length > 0 && (
          <Card className="scc--solutions--add-widget-preview">
            <ChartRenderer
              vizState={{
                query: props.validatedQuery,
                chartType
              }}
              overrideTimeZone={props.scene?.location?.timezone}
            />
          </Card>
        )}
    </Space>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    crossingLinesInScene: state.scenes.clinesPerScene[ownProps.scene.id],
    speedLinesInScene: state.scenes.speedlinesPerScene[ownProps.scene.id]
  }
}

export default connect(mapStateToProps, {
  listCrossinglinesInScene,
  listSpeedlinesInScene
})(withTranslation()(TrafficQueryBuilder))
