import { Config } from './config'
import axios from 'axios'
import { HTTP_METHOD } from '../redux/middleware/api'
import { log } from './log'
import { AuthProvider } from '../provider/AuthProvider'
import {
  BrowserAuthError,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import _ from 'lodash'

const API_ROOT = Config.API_ROOT

export const request = {
  scopes: Config.isB2C
    ? [Config.AAD_APP_CLIENT_ID]
    : ['openid', 'profile', 'offline_access'],
  authority: Config.AUTHORITY,
  account: AuthProvider.getAllAccounts()[0],
  redirectUri: window.location.origin
}

const loginRedirect = function (request) {
  return AuthProvider.acquireTokenRedirect(request)
}
// this needs to be wrapped in a closure / once utility function, because we must not call redirect multiple times in parallel,
// even if multiple api calls are in flight and acquireTokenSilent fails for all of them
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
const triggerRedirectLoginOnce = _.once(loginRedirect)

export const getIdToken = async (): Promise<string> => {
  const account = AuthProvider.getActiveAccount()

  if (account) {
    try {
      const token = await AuthProvider.acquireTokenSilent(request)
      return token.idToken
    } catch (error) {
      if (
        error instanceof InteractionRequiredAuthError ||
        (error instanceof BrowserAuthError &&
          error.errorCode === 'monitor_window_timeout')
      ) {
        await triggerRedirectLoginOnce(request)
      }
    }
  } else {
    //this is a fallback and should never happen
    await triggerRedirectLoginOnce(request)
  }
  //reject the token promise, i.e. we do need a full new login, further api calls will fail
  return Promise.reject()
}

const rawRequest = async ({ ...options }) => {
  const token = await getIdToken()
  let client = axios.create({
    baseURL: API_ROOT,
    method: HTTP_METHOD.GET
  })
  client.defaults.headers.common.Authorization = `Bearer ${token}`
  client.defaults.headers.common.Accept = 'application/json'

  const onSuccess = (response) => response
  const onError = (error) => {
    log.error(error)
    return error
  }

  return client(options).then(onSuccess).catch(onError)
}

export default rawRequest
