import {
  BOX_DETAILS_REQUEST_SUCCESS,
  RESET_BOX_DETAILS,
  SAVE_BOX_DETAILS_REQUEST,
  SAVE_BOX_DETAILS_REQUEST_FAILURE,
  SAVE_BOX_DETAILS_REQUEST_SUCCESS
} from '../actions/actionTypes'

import { DefaultBox } from '../../types/box'

const initialState = {
  boxConfig: {},
  isSubmitting: false
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case RESET_BOX_DETAILS:
      return { ...initialState }

    case BOX_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        boxConfig: Object.assign(
          {},
          DefaultBox,
          state.boxConfig,
          action.payload.boxConfig
        )
      }

    case SAVE_BOX_DETAILS_REQUEST:
      return {
        ...state,
        isSubmitting: true
      }

    case SAVE_BOX_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        boxConfig: Object.assign({}, state.boxConfig, {
          name: action.payload.boxConfig.name
        }),
        isSubmitting: false
      }

    case SAVE_BOX_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        isSubmitting: false
      }

    default:
      return state
  }
}
export default fn
