import './NavItem.scss'

import { NavLink } from 'react-router-dom'
import React from 'react'

interface INavItemProps {
  children?
  route: string
}

const NavItem: React.FC<INavItemProps> = ({ children, route }) => {
  return (
    <li className="scc--navitem">
      <NavLink
        to={route}
        className="scc--navitem--link"
        activeClassName="active"
      >
        {children}
      </NavLink>
    </li>
  )
}

export default NavItem
