import { NavLink, withRouter, Prompt } from 'react-router-dom'
import React, { Component } from 'react'
import {
  addBoxEventTrigger,
  deleteStreamEventTrigger,
  loadStreamEventTriggers,
  saveStreamEventTriggers,
  setHighlightedStreamEventTrigger,
  setSelectedStreamEventTrigger,
  updateStreamEventTrigger
} from '../redux/actions/eventTriggers'
import {
  loadCameraConfig,
  saveCameraConfig
} from '../redux/actions/cameraConfigurations'
import { CloseOutline24 } from '@carbon/icons-react'
import ConfirmationDialog from '../components/DeviceConfigurationConfirmationDialog'
import { DeleteConfirmationDialog } from '../components/DeviceConfigurationConfirmationDialog/DeleteConfirmationDialog'
import DrawCanvas from '../components/DrawCanvas'
import DrawContextForm from '../components/DrawContextForm'
import DrawContextTable from '../components/DrawContextTable'
import { EBoxModel } from '../types/boxModel'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../types/eventTrigger'
import { HeatMapEventTrigger } from '../types/heatMap'
import { AnprEventTrigger } from '../types/anpr'
import { connect } from 'react-redux'
import { loadCameraFrame } from '../redux/actions/cameraFrame'
import { notify } from '../services/notify'
import { showErrorMessage } from '../redux/actions/error'
import { withTranslation } from 'react-i18next'
import { loadBoxStreams, saveStream } from '../redux/actions/streams'
import { DeviceType, NVIDIA_DEVICES } from '../types/box'
import { loadBoxes } from '../redux/actions/boxes'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { CrossingLineEventTrigger } from '../types/crossingLine'
import { SpeedEstimationTrigger } from '../types/speedEstimation'
import {
  ERegionOfInterestType,
  RegionOfInterestEventTrigger
} from '../types/regionOfInterest'

const uuid = require('lodash-uuid')

interface IStreamContextualPageState {
  isSaveConfirmationDialogVisible: boolean
  isDeleteConfirmationDialogVisible: boolean
  isInvalidModelErrorVisible: boolean
  eventTriggerToDelete: ICoordinateBasedEventTrigger | null
  selectedModel: EBoxModel | null
  deviceType: DeviceType
}

class StreamContextualPage extends Component<any, IStreamContextualPageState> {
  constructor(props) {
    super(props)

    this.state = {
      isSaveConfirmationDialogVisible: false,
      isDeleteConfirmationDialogVisible: false,
      isInvalidModelErrorVisible: false,
      eventTriggerToDelete: null,
      selectedModel: this.props.selectedModel,
      deviceType: this.props.deviceType
    }

    this.onButtonClick = this.onButtonClick.bind(this)
    this.onToggleDirection = this.onToggleDirection.bind(this)
    this.onToggleSpeedEstimate = this.onToggleSpeedEstimate.bind(this)
    this.onValueChanged = this.onValueChanged.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onSelectEventTrigger = this.onSelectEventTrigger.bind(this)
    this.onMouseEnterRow = this.onMouseEnterRow.bind(this)
    this.onMouseLeaveTable = this.onMouseLeaveTable.bind(this)
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this)
    this.onSaveStreamConfig = this.onSaveStreamConfig.bind(this)
    this.onCloseDialog = this.onCloseDialog.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.warnUnsavedChanges = this.warnUnsavedChanges.bind(this)
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.warnUnsavedChanges)
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    // Prevent loading same data twice
    if (
      prevProps.match.params.id !== this.props.match.params.id ||
      prevProps.match.params.streamId !== this.props.match.params.streamId
    ) {
      this.loadData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.warnUnsavedChanges)
  }

  // stop and warn user for unsaved changed before leaving the page
  warnUnsavedChanges(e) {
    if (this.hasDataChanged) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  loadData(options?: { loadCameraFrame: boolean }) {
    const { id, streamId } = this.props.match.params

    // Merge default values with provided options
    const config = Object.assign(
      {},
      {
        loadCameraFrame: true
      },
      options
    )

    if (this.props.deviceType === DeviceType.UNSPECIFIED) {
      this.props.loadBoxes()
    }

    this.props.loadBoxStreams(id).then((streams) => {
      const currentstream = streams.response.entities.streams[streamId]
      let selectedModel = currentstream.model

      this.setState({
        selectedModel: selectedModel
      })
    })
    this.props.loadCameraConfig(id, streamId)
    this.props.loadStreamEventTriggers(id, streamId)
    if (config.loadCameraFrame) {
      this.props.loadCameraFrame(id, streamId)
    }
  }

  onButtonClick(event, type: EEventTriggerType, ...props) {
    event.preventDefault()
    this.props.addBoxEventTrigger(type, props)
  }

  onToggleDirection(event, eventTrigger) {
    if (eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled) {
      // make copy of speedtrigger the hold coordinates while swapping
      let speedTriggerObject = new SpeedEstimationTrigger(
        eventTrigger.speedEstimation
      )
      speedTriggerObject.coordinates = eventTrigger.coordinates
      if (eventTrigger.direction === eventTrigger.speedEstimation.direction) {
        eventTrigger.speedEstimation.coordinates.reverse()
      }

      this.props.updateStreamEventTrigger(eventTrigger, {
        coordinates: eventTrigger.speedEstimation.coordinates,
        speedEstimation: speedTriggerObject
      })
    } else {
      // To change the direction, the order of the points has to be reversed
      this.props.updateStreamEventTrigger(eventTrigger, {
        coordinates: eventTrigger.coordinates.reverse()
      })
    }
  }

  onToggleSpeedEstimate(event, eventTrigger, checked) {
    const crossingLineTrigger = eventTrigger as CrossingLineEventTrigger
    let speedEstimation = crossingLineTrigger.speedEstimation
    if (speedEstimation) {
      if (!checked) {
        if (
          angleSmaller180Degree(
            eventTrigger.coordinates,
            speedEstimation.coordinates
          )
        ) {
          eventTrigger.coordinates.reverse()
        }
      }
      speedEstimation.enabled = checked
    } else {
      speedEstimation = SpeedEstimationTrigger.default()
      let { point1x, point1y, point2x, point2y } = getParallelLine(eventTrigger)

      speedEstimation.coordinates = [
        {
          x: Math.min(Math.max(point1x, 0), 1),
          y: Math.min(Math.max(point1y, 0), 1)
        },
        {
          x: Math.min(Math.max(point2x, 0), 1),
          y: Math.min(Math.max(point2y, 0), 1)
        }
      ]
    }
    // switch main- and speedline to stay constant with the direction
    if (
      checked &&
      angleSmaller180Degree(
        eventTrigger.coordinates,
        speedEstimation.coordinates
      )
    ) {
      let tempCoordinates = [
        {
          x: speedEstimation.coordinates[0].x,
          y: speedEstimation.coordinates[0].y
        },
        {
          x: speedEstimation.coordinates[1].x,
          y: speedEstimation.coordinates[1].y
        }
      ]
      if (eventTrigger.direction === speedEstimation.direction) {
        speedEstimation.coordinates.reverse()
      }
      speedEstimation.coordinates = eventTrigger.coordinates
      eventTrigger.coordinates = tempCoordinates
    }
    this.props.updateStreamEventTrigger(eventTrigger, {
      coordinates: eventTrigger.coordinates,
      speedEstimation: speedEstimation
    })
  }

  correctValue(value) {
    if (value < 0) {
      return 0
    } else if (value > 1) {
      return 1
    } else {
      return value
    }
  }

  onDragEnd(event, currentEventTrigger, coordinates) {
    // Sort coordinates for polygons clockwise
    if (
      currentEventTrigger.objectType === EEventTriggerType.regionOfInterest ||
      currentEventTrigger.objectType === EEventTriggerType.virtualDoor
    ) {
      // 1. Determine reference point in the center
      const centerPoint = {
        x:
          coordinates.reduce((initial, point) => initial + point.x, 0) /
          coordinates.length,
        y:
          coordinates.reduce((initial, point) => initial + point.y, 0) /
          coordinates.length
      }

      coordinates = coordinates.sort((a, b) => {
        // 2. Calculate angles between points and reference point
        let aTanA = Math.atan2(a.y - centerPoint.y, a.x - centerPoint.x)
        let aTanB = Math.atan2(b.y - centerPoint.y, b.x - centerPoint.x)

        // 3. Determine sort order
        if (aTanA < aTanB) {
          return -1
        } else if (aTanA > aTanB) {
          return 1
        } else {
          return 0
        }
      })
    }

    // Convert coordinates in relative units
    const canvas = event.currentTarget.parent.canvas

    // Canvas width and height need to be correct by pixel ratio
    const canvasWidth = canvas.width / canvas.pixelRatio
    const canvasHeight = canvas.height / canvas.pixelRatio

    if (
      currentEventTrigger.speedEstimation &&
      currentEventTrigger.speedEstimation.enabled
    ) {
      const relativeMain = coordinates.slice(0, 2).map((coordinate) => ({
        x: this.correctValue(coordinate.x / canvasWidth),
        y: this.correctValue(coordinate.y / canvasHeight)
      }))
      const relativeSpeed = coordinates.slice(2, 4).map((coordinate) => ({
        x: this.correctValue(coordinate.x / canvasWidth),
        y: this.correctValue(coordinate.y / canvasHeight)
      }))
      Object.assign(currentEventTrigger.speedEstimation, {
        coordinates: relativeSpeed
      })
      this.props.updateStreamEventTrigger(currentEventTrigger, {
        coordinates: relativeMain,
        speedEstimation: currentEventTrigger.speedEstimation
      })
    } else {
      const relative = coordinates.map((coordinate) => ({
        x: this.correctValue(coordinate.x / canvasWidth),
        y: this.correctValue(coordinate.y / canvasHeight)
      }))

      this.props.updateStreamEventTrigger(currentEventTrigger, {
        coordinates: relative
      })
    }
  }

  onSelectEventTrigger(eventTrigger: ICoordinateBasedEventTrigger) {
    if (this.props.selectedEventTrigger === eventTrigger.localId) {
      return
    }

    this.props.setSelectedStreamEventTrigger(eventTrigger)
  }

  onMouseEnterRow(event, eventTrigger) {
    this.props.setHighlightedStreamEventTrigger(eventTrigger)
  }

  onMouseLeaveTable(event) {
    this.props.setHighlightedStreamEventTrigger(
      this.getEventTriggerById(this.props.selectedEventTrigger)
    )
  }

  getEventTriggerByType(type) {
    return this.props.eventTriggers.find(
      (eventTrigger) => eventTrigger.objectType === type
    )
  }

  getEventTriggerById(id) {
    return this.props.eventTriggers.find(
      (eventTrigger) => eventTrigger.localId === id
    )
  }

  checkValidModelChange(model, eventTriggers) {
    if (
      model !== EBoxModel.headDetectorRetailStandard &&
      model !== EBoxModel.personDetectorDemoStandardFast
    ) {
      return true
    }

    return eventTriggers.every((trigger) => {
      return (
        !(trigger instanceof AnprEventTrigger && trigger.enabled) &&
        !(
          trigger instanceof RegionOfInterestEventTrigger &&
          trigger.roiType === ERegionOfInterestType.parking
        )
      )
    })
  }

  onValueChanged(eventTrigger, changes) {
    if (eventTrigger && changes.eventTrigger) {
      if (
        eventTrigger.speedEstimation &&
        changes.eventTrigger.speedEstimation
      ) {
        //assigning the old unchanged values of speed-estimation to the as they get lost otherwise
        Object.keys(eventTrigger.speedEstimation).forEach((key) => {
          if (!(key in changes.eventTrigger.speedEstimation)) {
            changes.eventTrigger.speedEstimation[key] =
              eventTrigger.speedEstimation[key]
          }
        })
      }
      this.props.updateStreamEventTrigger(eventTrigger, changes.eventTrigger)
    }

    if (changes.box) {
      let valid = this.checkValidModelChange(
        changes.box.model,
        this.props.eventTriggers
      )
      if (!valid) {
        this.setState({
          isInvalidModelErrorVisible: true
        })
        return
      }
      this.setState({
        selectedModel: changes.box.model || undefined
      })
    }
  }

  onDeleteButtonClick(eventTrigger) {
    this.setState({
      isDeleteConfirmationDialogVisible: true,
      eventTriggerToDelete: eventTrigger
    })
  }

  onFormSubmit() {
    this.setState({
      isSaveConfirmationDialogVisible: true
    })
  }

  async onSaveStreamConfig() {
    if (this.props.isSubmitting) {
      return
    }

    this.setState({
      isSaveConfirmationDialogVisible: false
    })

    const boxId = this.props.match.params.id
    const streamId = this.props.match.params.streamId
    const { saveStreamEventTriggers, saveStream } = this.props

    const { selectedModel } = this.state

    const updatedStream = Object.assign({}, this.props.stream)
    updatedStream.model = selectedModel || undefined

    let eventTriggers = this.props.eventTriggers

    try {
      // The stream model needs to be saved first
      // in order for the backend to know what
      // to do with event triggers.
      await saveStream(boxId, streamId, updatedStream)
      await saveStreamEventTriggers(boxId, streamId, eventTriggers)
      this.loadData({ loadCameraFrame: false })

      notify({
        title: this.props.t('notification.eventTriggers.saved.title'),
        message: this.props.t('notification.eventTriggers.saved.message')
      })
    } catch (error) {}
  }

  onCloseDialog(event) {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }

    this.setState({
      isSaveConfirmationDialogVisible: false,
      isDeleteConfirmationDialogVisible: false,
      isInvalidModelErrorVisible: false,
      eventTriggerToDelete: null
    })
  }

  onDeleteTrigger(boxId, streamId, event) {
    if (this.state.eventTriggerToDelete) {
      this.props.deleteStreamEventTrigger(
        boxId,
        streamId,
        this.state.eventTriggerToDelete
      )
      this.onCloseDialog(event)
    }

    // disable ANPR if the last CL is removed
    let clTriggers = this.props.eventTriggers.filter((trigger) => {
      return trigger instanceof CrossingLineEventTrigger
    })
    let anprTrigger = this.props.eventTriggers.find((trigger) => {
      return trigger instanceof AnprEventTrigger
    })
    if (clTriggers.length === 1 && anprTrigger.enabled) {
      this.onValueChanged(anprTrigger, {
        eventTrigger: Object.assign({}, anprTrigger, { enabled: false })
      })
      this.props.saveStreamEventTriggers(boxId, streamId, [anprTrigger])
    }
  }

  get hasDataChanged(): boolean {
    const eventTriggers = this.props.eventTriggers || []
    const stream = this.props.stream
    if (stream !== undefined) {
      const hasStreamBeenUpdated = stream
        ? stream.model !== this.state.selectedModel
        : false

      const haveEventTriggersBeenUpdated = eventTriggers.some(
        (trigger) => trigger.hasChanged
      )

      return hasStreamBeenUpdated || haveEventTriggersBeenUpdated
    }
    return false
  }

  get emptyTableText(): string {
    return this.props.t('draw.dataTable.data.empty.default')
  }

  render() {
    const boxId = this.props.match.params.id
    const streamId = this.props.match.params.streamId
    const calibration = this.props.match.params.calibration
    const heatMapEventTrigger = (this.getEventTriggerByType(
      EEventTriggerType.heatMap
    ) as unknown) as HeatMapEventTrigger

    const anprEventTrigger = (this.getEventTriggerByType(
      EEventTriggerType.anpr
    ) as unknown) as AnprEventTrigger

    const {
      t,
      cameraFrame,
      highlightedEventTrigger,
      selectedEventTrigger,
      isSubmitting,
      loadCameraFrame,
      isLoadingCameraFrame,
      deviceType
    } = this.props

    let eventTriggers = this.props.eventTriggers
    const eventTrigger = this.getEventTriggerById(
      this.props.selectedEventTrigger
    )

    return (
      <div className="scc--boxcontextual-container">
        {this.props.sceneId && (
          <div className="scc--boxcontextual-container--sceneNote">
            <NavLink to={`/solutions/${this.props.sceneId}#streams`}>
              <ArrowLeftOutlined />
              &nbsp; {t('modal.configuration.returnSceneLink')}
            </NavLink>
          </div>
        )}
        <div className="bx--grid">
          <div className="bx--row">
            <div
              className="bx--col-sm-4 bx--col-md-4 bx--col-lg-4"
              style={{ overflow: 'auto' }}
            >
              <Prompt // stop and warn user for unsaved changed before leaving the page
                when={this.hasDataChanged}
                message={t('modal.configuration.warnUnsavedChanges')}
              />
              <NavLink to={`/boxes/${boxId}`} className="scc--close-button">
                <CloseOutline24 className="scc--fill-green" />
              </NavLink>
              <DrawContextForm
                heatMap={heatMapEventTrigger}
                anpr={anprEventTrigger}
                shouldShowAnpr={NVIDIA_DEVICES.has(deviceType)}
                eventTrigger={eventTrigger}
                onButtonClick={this.onButtonClick}
                onValueChanged={this.onValueChanged}
                onToggleDirection={this.onToggleDirection}
                onToggleSpeedEstimate={this.onToggleSpeedEstimate}
                model={this.state.selectedModel}
                onSubmit={this.onFormSubmit}
                hasDataChanged={this.hasDataChanged}
                isSubmitting={isSubmitting}
              />
            </div>
            <div className="bx--col-sm-12 bx--col-md-12 bx--col-lg-12 scc--flex--column">
              <DrawCanvas
                frame={cameraFrame}
                eventTriggers={eventTriggers}
                highlightedEventTrigger={highlightedEventTrigger}
                selectedEventTrigger={selectedEventTrigger}
                onDragEnd={this.onDragEnd}
                onSelectEventTrigger={this.onSelectEventTrigger}
                isEditable={true}
                isLoading={isLoadingCameraFrame}
                calibration={calibration}
                onRefreshButtonClick={(event) => {
                  loadCameraFrame(boxId, streamId, true, event.calibration)
                }}
              />

              <DrawContextTable
                eventTriggers={eventTriggers}
                selectedEventTrigger={selectedEventTrigger}
                onClickRow={this.onSelectEventTrigger}
                onDelete={this.onDeleteButtonClick}
                onMouseEnterRow={this.onMouseEnterRow}
                onMouseLeave={this.onMouseLeaveTable}
                emptyTableText={this.emptyTableText}
              />
            </div>
          </div>
        </div>
        <ConfirmationDialog
          modalHeading={t('draw.confirmation.header')}
          primaryButtonText={t('modal.configuration.primaryButton.title')}
          secondaryButtonText={t('modal.configuration.secondaryButton.title')}
          onRequestClose={this.onCloseDialog}
          onSecondarySubmit={this.onCloseDialog}
          onRequestSubmit={this.onSaveStreamConfig}
          open={this.state.isSaveConfirmationDialogVisible}
        >
          {t('draw.confirmation.text')}
        </ConfirmationDialog>
        <DeleteConfirmationDialog
          onRequestSubmit={(event) => {
            this.onDeleteTrigger(boxId, streamId, event)
          }}
          onSecondarySubmit={this.onCloseDialog}
          onRequestClose={this.onCloseDialog}
          open={this.state.isDeleteConfirmationDialogVisible}
        />
        <ConfirmationDialog
          modalHeading={t('draw.modelChange.header')}
          primaryButtonText={t('draw.modelChange.button')}
          onRequestSubmit={this.onCloseDialog}
          onRequestClose={this.onCloseDialog}
          open={this.state.isInvalidModelErrorVisible}
        >
          {t('draw.modelChange.text')}
        </ConfirmationDialog>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let currentStream = state.streams.byIds[ownProps.match.params.streamId]
  let currentBox = state.boxes.byIds[ownProps.match.params.id]
  let deviceType: DeviceType = DeviceType.UNSPECIFIED

  let sceneId: string | undefined
  let searchParam = new URLSearchParams(ownProps.location.search)
  let sceneParam = searchParam.has('sceneId') && searchParam.get('sceneId')
  if (sceneParam && uuid.isUuid(sceneParam)) {
    sceneId = sceneParam
  }

  if (currentBox) {
    deviceType = currentBox.type
  }
  return {
    cameraConfiguration:
      state.cameraConfigurations.byIds[ownProps.match.params.streamId],
    eventTriggers: state.eventTriggers.allIds.map(
      (id) => state.eventTriggers.byIds[id]
    ),
    //Note: we could rewrite eventTriggers to an object and filter explititly here? atm reading slices it correctly
    deviceType: deviceType,
    stream: currentStream,
    sceneId: sceneId,

    selectedEventTrigger: state.eventTriggers.selectedEventTrigger,
    highlightedEventTrigger: state.eventTriggers.highlightedEventTrigger,
    isSubmitting: state.eventTriggers.isSubmitting,
    cameraFrame: state.cameraFrames.byIds[ownProps.match.params.streamId],
    isLoadingCameraFrame:
      state.cameraFrames.loadingIds[ownProps.match.params.streamId]
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadCameraConfig,
    loadCameraFrame,
    loadStreamEventTriggers,
    addBoxEventTrigger,
    updateStreamEventTrigger,
    deleteStreamEventTrigger,
    setSelectedStreamEventTrigger,
    setHighlightedStreamEventTrigger,
    saveCameraConfig,
    saveStreamEventTriggers,
    loadBoxStreams,
    loadBoxes,
    saveStream,
    showErrorMessage
  })(withTranslation()(StreamContextualPage))
)

const getRotationDegreeBetween = (pt1, pt2, offset = 90) => {
  return (Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x) * 180) / Math.PI - offset
}

const positiveModulo = (n, m) => {
  return ((n % m) + m) % m
}

const angleSmaller180Degree = (line1, line2) => {
  return (
    positiveModulo(
      getRotationDegreeBetween(line1[0], line1[1], 0) -
        getRotationDegreeBetween(
          {
            x: (line1[0].x + line1[1].x) / 2,
            y: (line1[0].y + line1[1].y) / 2
          },
          {
            x: (line2[0].x + line2[1].x) / 2,
            y: (line2[0].y + line2[1].y) / 2
          },
          0
        ),
      360
    ) <= 180
  )
}

const getParallelLine = (eventTrigger) => {
  const coordinates1 = eventTrigger.coordinates[0]
  const coordinates2 = eventTrigger.coordinates[1]
  const dx = coordinates2.x - coordinates1.x
  const dy = coordinates2.y - coordinates1.y
  const length = Math.sqrt(dx * dx + dy * dy)
  let point1x, point1y, point2x, point2y
  if (
    angleSmaller180Degree(eventTrigger.coordinates, [
      {
        x: (coordinates1.x + coordinates2.x) / 2,
        y: (coordinates1.x + coordinates2.x) / 2
      },
      { x: 0.5, y: 0.5 }
    ])
  ) {
    point1x = coordinates1.x - (dy / length) * 0.2
    point1y = coordinates1.y + (dx / length) * 0.2
  } else {
    point1x = coordinates1.x + (dy / length) * 0.2
    point1y = coordinates1.y - (dx / length) * 0.2
  }
  point2x = point1x + dx
  point2y = point1y + dy
  return { point1x, point1y, point2x, point2y }
}
