import React, { useState, Fragment } from 'react'
import { DatePicker, Menu } from 'antd'
import moment from 'moment-timezone'
import ButtonDropdown from './ButtonDropdown'
import MemberDropdown from './MemberDropdown'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const allTimeString = 'from 2021-01-01 to now'
const thisYear = `from ${moment().startOf('year').format('YYYY-MM-DD')} to now`
const thisMonth = `from ${moment()
  .startOf('month')
  .format('YYYY-MM-DD')} to now`
const thisWeek = `from ${moment().startOf('week').format('YYYY-MM-DD')} to now`

const DateRanges = [
  { title: 'Custom', value: 'custom' },
  { title: 'All time', value: allTimeString },
  { value: 'Today' },
  { value: 'Yesterday' },
  { title: 'This week', value: thisWeek },
  { title: 'This month', value: thisMonth },
  { title: 'This year', value: thisYear },
  { title: 'Last 7 days', value: 'from 6 days ago to now' },
  { title: 'Last 30 days', value: 'from 29 days ago to now' },
  { title: 'Last 365 days', value: 'from 364 days ago to now' },
  { value: 'Last week' },
  { value: 'Last month' }
]

const Label = styled.div`
  color: var(--dark-04-color);
  line-height: 32px;
`

const SectionRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -8px;
  margin-bottom: -8px;

  && > * {
    margin-right: 8px !important;
    margin-bottom: 8px !important;
  }
`

const TimeGroup = ({
  members = [],
  availableMembers,
  addMemberName,
  updateMethods,
  showGranularities,
  timezone
}) => {
  const isCustomDateRange =
    Array.isArray(members[0]?.dateRange) &&
    members[0]?.dateRange !== allTimeString
  const isAllTime =
    !Array.isArray(members[0]?.dateRange) &&
    members[0]?.dateRange === allTimeString
  const [isRangePickerVisible, toggleRangePicker] = useState(false)
  const { t } = useTranslation(['datadiscovery'])

  let dateformat = 'YYYY-MM-DD HH:mm'
  if (!timezone || timezone === 'Etc/UTC') {
    dateformat += ' [(UTC)]'
  }

  function onDateRangeSelect(m, dateRange) {
    if (dateRange && !dateRange.some((d) => !d)) {
      updateMethods.update(m, {
        ...m,
        dateRange: dateRange.map((dateTime) =>
          timezone
            ? moment.tz(dateTime, timezone).utc().toISOString()
            : dateTime.utc().toISOString()
        )
      })
    }
  }

  const granularityMenu = (member, onClick) => (
    <Menu>
      {member.granularities.length ? (
        member.granularities.map((m) => (
          <Menu.Item key={m.title} onClick={() => onClick(m)}>
            {m.title}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No members found</Menu.Item>
      )}
    </Menu>
  )

  const dateRangeMenu = (onClick) => (
    <Menu>
      {DateRanges.map((m) => (
        <Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
          {m.title || m.value}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <SectionRow>
      {members.map((m, index) => (
        <Fragment key={index}>
          <Label>{t('explore.builder.timelabel')}</Label>

          <ButtonDropdown
            overlay={dateRangeMenu((dateRange) => {
              if (dateRange.value === 'custom') {
                toggleRangePicker(true)
              } else {
                updateMethods.update(m, {
                  ...m,
                  dateRange: dateRange.value
                })
                toggleRangePicker(false)
              }
            })}
          >
            {(() => {
              let range = m.dateRange || 'Today'
              if (isRangePickerVisible || isCustomDateRange) {
                range = 'custom'
              } else if (isAllTime) {
                range = allTimeString
              }
              const dateRange = DateRanges.find((item) => {
                return item.value === range || item.title === range
              })
              return dateRange.title || dateRange.value
            })()}
          </ButtonDropdown>

          {isRangePickerVisible || isCustomDateRange ? (
            <RangePicker
              format={dateformat}
              showTime={{ format: 'HH:mm' }}
              defaultValue={(isCustomDateRange
                ? members[0].dateRange
                : []
              ).map((date) =>
                timezone ? moment.tz(date, timezone) : moment(date).utc()
              )}
              onChange={(dateRange) => onDateRangeSelect(m, dateRange)}
            />
          ) : null}
          {showGranularities && (
            <>
              <Label> split by</Label>

              <ButtonDropdown
                overlay={granularityMenu(m.dimension, (granularity) =>
                  updateMethods.update(m, {
                    ...m,
                    granularity: granularity.name
                  })
                )}
              >
                {m.dimension.granularities.find(
                  (g) => g.name === m.granularity
                ) &&
                  m.dimension.granularities.find(
                    (g) => g.name === m.granularity
                  ).title}
              </ButtonDropdown>
            </>
          )}
        </Fragment>
      ))}

      {!members.length && (
        <MemberDropdown
          availableMembers={availableMembers}
          type="dashed"
          icon={<PlusOutlined />}
          onClick={(member) =>
            updateMethods.add({ dimension: member, granularity: 'day' })
          }
        >
          {addMemberName}
        </MemberDropdown>
      )}
    </SectionRow>
  )
}

export default TimeGroup
