import React from 'react'
import { Space, Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import GroupPage from './containers/GroupPage'
import { DefaultGroup, IGroup } from '../types/group'
import { uuid } from 'lodash-uuid'
import { DeleteConfirmationDialog } from '../components/DeviceConfigurationConfirmationDialog/DeleteConfirmationDialog'
import { deleteGroup, listGroups } from '../redux/actions/groups'
import { listScenes } from '../redux/actions/scenes'

const { TabPane } = Tabs

interface ISolutionApp {
  t: any
  deleteGroup: Function
  listGroups: Function
  listScenes: Function
  groups: IGroup[]
  newGroups: IGroup[]
  initialGroup: IGroup | null
}

class SolutionApp extends React.Component<ISolutionApp> {
  constructor(props) {
    super(props)
    this.rerenderCallback = this.rerenderCallback.bind(this)
  }

  state = {
    activeGroup: 'default',
    isDeleteConfirmationDialogVisible: false,
    deleteTargetKey: undefined
  }

  componentDidMount = () => {
    this.props.listGroups()
    this.props.listScenes()
  }

  onChange = (activeGroup) => {
    this.setState({ activeGroup })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  add = () => {
    const newId = uuid()
    this.props.newGroups.push({
      name: this.props.t('solutions.groups.newGroupName'),
      id: newId,
      new: true
    })
    this.setState({
      activeGroup: newId
    })
  }

  remove = (targetKey) => {
    this.setState({
      deleteTargetKey: targetKey
    })

    if (this.props.groups.find((group) => group.id === targetKey)) {
      this.setState({
        isDeleteConfirmationDialogVisible: true
      })
    }

    if (this.props.newGroups.find((group) => group.id === targetKey)) {
      const index = this.props.newGroups.findIndex(
        (group) => group.id === targetKey
      )
      this.props.newGroups.splice(index, 1)
      let newActiveGroup =
        index > 0
          ? this.props.newGroups[index - 1].id
          : this.props.groups.length > 0
          ? this.props.groups[this.props.groups.length - 1].id
          : 'default'
      this.setState({
        activeGroup: newActiveGroup
      })
    }
  }

  onSubmitDialog = () => {
    if (!this.state.deleteTargetKey) {
      return
    }
    this.deleteGroup(this.state.deleteTargetKey)
  }

  deleteGroup = async (deleteTargetKey) => {
    const index = this.props.groups.findIndex(
      (group) => group.id === deleteTargetKey
    )
    await this.props.deleteGroup(this.props.groups[index])
    this.props.groups.splice(index, 1)

    let newActiveGroup =
      index > 0 && this.props.groups.length > 0
        ? this.props.groups[index - 1].id
        : 'default'

    this.setState({
      activeGroup: newActiveGroup,
      isDeleteConfirmationDialogVisible: false,
      deleteTargetKey: undefined
    })
  }

  onCloseDialog = (event) => {
    this.setState({
      isDeleteConfirmationDialogVisible: false
    })
  }

  rerenderCallback = () => {
    this.forceUpdate()
  }

  render() {
    const { groups, newGroups } = this.props
    const { activeGroup } = this.state

    groups.sort((x, y) => {
      return x.name.localeCompare(y.name)
    })

    return (
      <>
        <div className="scc--boxcontextual-container">
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-sm-12 bx--col-md-16 bx--col-lg-16 scc--flex--column">
                <Space direction="vertical" size="middle">
                  <Tabs
                    tabPosition="top"
                    size="large"
                    type="editable-card"
                    activeKey={
                      this.props.initialGroup
                        ? this.props.initialGroup.id
                        : activeGroup
                    }
                    onChange={this.onChange}
                    onEdit={this.onEdit}
                  >
                    {[DefaultGroup, ...groups, ...newGroups].map((group) => (
                      <TabPane
                        tab={group.name}
                        key={group.id}
                        closable={group.id !== 'default'}
                      >
                        <GroupPage
                          group={group}
                          editable={group.id !== 'default'}
                          updateTabs={this.rerenderCallback}
                        />
                      </TabPane>
                    ))}
                  </Tabs>
                </Space>
              </div>
            </div>
          </div>
        </div>
        <DeleteConfirmationDialog
          onRequestSubmit={this.onSubmitDialog}
          onSecondarySubmit={this.onCloseDialog}
          onRequestClose={this.onCloseDialog}
          open={this.state.isDeleteConfirmationDialogVisible}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let selectedScene = state.scenes.byId[ownProps.match.params.id]
  return {
    groups: state.groups.all,
    initialGroup: selectedScene ? selectedScene.group : null,
    newGroups: []
  }
}

export default withRouter(
  connect(mapStateToProps, {
    deleteGroup,
    listGroups,
    listScenes
  })(withTranslation()(SolutionApp))
)
