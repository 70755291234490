import React from 'react'
import { Card, Menu, Button, Dropdown, Modal } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import {
  GET_DASHBOARD_ITEMS,
  GET_DASHBOARD_ITEMS_SCENE
} from '../graphql/queries'
import { DELETE_DASHBOARD_ITEM } from '../graphql/mutations'
import { MenuOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const DashboardItemDropdown = ({ item, showModalById, showModal, sceneId }) => {
  const { t } = useTranslation(['datadiscovery'])
  const history = useHistory()
  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      sceneId
        ? {
            query: GET_DASHBOARD_ITEMS_SCENE,
            variables: {
              sceneId: sceneId
            }
          }
        : {
            query: GET_DASHBOARD_ITEMS
          }
    ]
  })
  const dashboardItemDropdownMenu = (
    <Menu>
      <Menu.Item
        onClick={
          showModal
            ? () => {
                showModal(item)
              }
            : () => {
                history.push(`/data/explore?itemId=${item.id}`)
              }
        }
      >
        {t('widgets.dropdown.edit')}
      </Menu.Item>
      <Menu.Item onClick={() => showModalById(item.id)}>
        {t('widgets.dropdown.api')}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          Modal.confirm({
            title: t('widgets.dropdown.areyousure'),
            okText: t('widgets.dropdown.yes'),
            okType: 'danger',
            cancelText: t('widgets.dropdown.no'),

            onOk() {
              removeDashboardItem({
                variables: {
                  id: item.id
                }
              })
            }
          })
        }
      >
        {t('widgets.dropdown.delete')}
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown
      overlay={dashboardItemDropdownMenu}
      placement="bottomLeft"
      trigger={['click']}
    >
      <Button shape="circle" icon={<MenuOutlined />} />
    </Dropdown>
  )
}

const DashboardItem = ({
  item,
  children,
  title,
  showModalById,
  showModal,
  sceneId
}) => (
  <Card
    title={title}
    style={{
      height: '100%',
      width: '100%'
    }}
    extra={
      <DashboardItemDropdown
        item={item}
        showModalById={showModalById}
        sceneId={sceneId}
        showModal={showModal}
      />
    }
  >
    {children}
  </Card>
)

export default DashboardItem
