import '../components/SceneList/SceneList.scss'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { IScene } from '../../types/scene'
import SceneList from '../components/SceneList'
import { uuid } from 'lodash-uuid'
import { IGroup } from '../../types/group'

interface ISceneListView {
  t: any
  history: any
  group: IGroup
  scenes: IScene[]
  newScenes: IScene[]
  scenesLoading: boolean
}

class SceneListView extends Component<ISceneListView> {
  addScene = () => {
    this.props.history.push(`/solutions/${uuid()}/${this.props.group.id}`)
  }
  render() {
    const { t } = this.props
    return (
      <div className="scc--scenelist--container">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col">
              <Button
                className="scc--solutions--button"
                type="primary"
                size="large"
                style={{
                  width: 160
                }}
                onClick={this.addScene}
              >
                {t('solutions.scenes.add')}
              </Button>
            </div>
          </div>
          <SceneList
            scenes={this.props.scenes}
            scenesLoading={this.props.scenesLoading}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { scenesLoading: state.scenes.loading }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(SceneListView))
)
