import { IBoxStatus } from './boxStatus'

export interface IBox {
  id: string // uuid
  name?: string // Friendly name of the box
  tags?: [string?]
  statusId?: string // The uuid for the status item
  type: DeviceType
  mode?: string
  online?: boolean
  boxStatus?: IBoxStatus
}

export enum DeviceType {
  X64_NVIDIA = 'x64-nvidia',
  JETSON_NANO = 'jetson-nano',
  JETSON_NX = 'jetson-nx',
  JETSON_AGX = 'jetson-agx',
  ARM64_VSI = 'arm64-vsi',
  UNSPECIFIED = 'unspecified'
}

export const NVIDIA_DEVICES = new Set<DeviceType>()
NVIDIA_DEVICES.add(DeviceType.X64_NVIDIA)
NVIDIA_DEVICES.add(DeviceType.JETSON_NANO)
NVIDIA_DEVICES.add(DeviceType.JETSON_NX)
NVIDIA_DEVICES.add(DeviceType.JETSON_AGX)

export const DefaultBox: IBox = {
  id: '',
  name: undefined,
  tags: undefined,
  statusId: undefined,
  type: DeviceType.UNSPECIFIED,
  mode: undefined,
  online: undefined,
  boxStatus: undefined
}
