import { EConfigurationStatus } from './configurationStatus'

export enum EMqttConfigurationType {
  default = 'default',
  custom = 'custom'
}

export interface IMqttConfiguration {
  boxId?: string
  streamId?: string
  objectType?: string
  status?: EConfigurationStatus
  configurationType?: EMqttConfigurationType
  username: string
  password: string
  compression: boolean
  host: string
  port?: number
  path?: string
  topic?: string
}

export const DefaultMqttConfiguration: IMqttConfiguration = {
  objectType: 'MqttConfiguration',
  configurationType: EMqttConfigurationType.default,
  status: undefined,
  username: '',
  password: '',
  compression: false,
  host: '',
  port: undefined,
  path: undefined,
  topic: undefined
}
