import {
  ADD_BOX_EVENT_TRIGGER,
  BOX_EVENT_TRIGGERS_REQUEST,
  BOX_EVENT_TRIGGERS_REQUEST_FAILURE,
  BOX_EVENT_TRIGGERS_REQUEST_SUCCESS,
  DELETE_BOX_EVENT_TRIGGER_REQUEST,
  DELETE_BOX_EVENT_TRIGGER_REQUEST_FAILURE,
  DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
  RESET_BOX_EVENT_TRIGGERS,
  SAVE_BOX_EVENT_TRIGGER_REQUEST,
  SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE,
  SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
  SAVE_BOX_EVENT_TRIGGERS_REQUEST,
  SAVE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE,
  SAVE_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS,
  SET_HIGHLIGHTED_BOX_EVENT_TRIGGER,
  SET_SELECTED_BOX_EVENT_TRIGGER,
  UPDATE_BOX_EVENT_TRIGGER
} from './actionTypes'
import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'

import { CrossingLineEventTrigger } from '../../types/crossingLine'
import { RegionOfInterestEventTrigger } from '../../types/regionOfInterest'
import axios from 'axios'
import { VirtualDoorEventTrigger } from '../../types/virtualDoor'
import { OriginDestinationZone } from '../../types/originDestinationZone'

/**
 * ======================
 * EVENT TRIGGERS ACTIONS
 * ======================
 */

/**
 * Resets the box event triggers.
 */
export const resetBoxEventTriggers = () => ({
  type: RESET_BOX_EVENT_TRIGGERS
})

/**
 * Fetches all event triggers for a specific box from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 * @param boxId: string The uuid of the box to load the event triggers for.
 * @param streamId
 */
const fetchStreamEventTriggers = (boxId, streamId) => ({
  [CALL_API]: {
    types: [
      BOX_EVENT_TRIGGERS_REQUEST,
      BOX_EVENT_TRIGGERS_REQUEST_SUCCESS,
      BOX_EVENT_TRIGGERS_REQUEST_FAILURE
    ],
    endpoint: `boxes/${boxId}/${streamId}/eventTriggers`,
    schema: Schemas.EVENT_TRIGGER,
    id: streamId
  }
})

/**
 * Fetches all event triggers for a specific box from the API.
 * Relies on Redux Thunk middleware.
 */
export const loadStreamEventTriggers = (boxId, streamId) => (dispatch) => {
  if (!boxId || !streamId) {
    return null
  }
  return dispatch(fetchStreamEventTriggers(boxId, streamId)).then(
    (data) => data.response.entities.eventTriggers
  )
}

const saveStreamEventTrigger = (
  boxId: string,
  streamId: string,
  eventTrigger: any
) => {
  const transformedEventTrigger = eventTrigger.getRequestObject()
  const type = eventTrigger.objectType
  let endpoint
  if (eventTrigger.objectType === EEventTriggerType.heatMap) {
    endpoint = `boxes/${boxId}/${streamId}/eventTriggers/${type}`
  } else if (eventTrigger.objectType === EEventTriggerType.anpr) {
    endpoint = `boxes/${boxId}/${streamId}/eventTriggers/${type}`
  } else {
    endpoint = `boxes/${boxId}/${streamId}/eventTriggers/${type}/${eventTrigger.localId}`
  }

  return {
    [CALL_API]: {
      types: [
        SAVE_BOX_EVENT_TRIGGER_REQUEST,
        SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
        SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: transformedEventTrigger,
      endpoint: endpoint,
      schema: Schemas.EVENT_TRIGGER,
      responseClass: eventTrigger.constructor
    }
  }
}

/**
 * Saves a set of event triggers.
 * @param boxId The UUID of the box to save the event triggers to.
 * @param streamId
 * @param eventTriggers The set of event triggers to save.
 */
export const saveStreamEventTriggers = (
  boxId: string,
  streamId: string,
  eventTriggers: ICoordinateBasedEventTrigger[]
) => (dispatch) => {
  dispatch({
    type: SAVE_BOX_EVENT_TRIGGERS_REQUEST
  })

  // Save only triggers that have changed
  let dispatchedEventTriggers = eventTriggers
    .filter((trigger) => trigger.hasChanged)
    .map((eventTrigger) =>
      dispatch(saveStreamEventTrigger(boxId, streamId, eventTrigger))
    )

  return axios
    .all(dispatchedEventTriggers)
    .then(() =>
      dispatch({
        type: SAVE_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS
      })
    )
    .catch((error) => {
      dispatch({
        type: SAVE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE,
        error
      })
    })
}

export const deleteStreamEventTrigger = (
  boxId: string,
  streamId: string,
  eventTrigger: any
) => {
  const type = eventTrigger.objectType

  // Delete the event trigger locally if it has not yet been saved
  // The ID is only set if the object has been saved via API request
  if (!eventTrigger.id) {
    return {
      type: DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
      response: { id: eventTrigger.localId }
    }
  }

  return {
    [CALL_API]: {
      types: [
        DELETE_BOX_EVENT_TRIGGER_REQUEST,
        DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
        DELETE_BOX_EVENT_TRIGGER_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.DELETE,
      endpoint: `boxes/${boxId}/${streamId}/eventTriggers/${type}/${eventTrigger.id}`,
      schema: Schemas.EVENT_TRIGGER,
      id: eventTrigger.id
    }
  }
}

/**
 * Updates an event trigger locally.
 * @param eventTrigger: IEventTrigger The event trigger to update.
 * @param updates
 */
export const updateStreamEventTrigger = (
  eventTrigger: ICoordinateBasedEventTrigger,
  updates: Partial<ICoordinateBasedEventTrigger>
) => ({
  type: UPDATE_BOX_EVENT_TRIGGER,
  payload: { eventTrigger, updates }
})

/**
 * Sets the selected event trigger.
 * @param eventTrigger IEventTrigger The selected event trigger.
 */
export const setSelectedStreamEventTrigger = (
  eventTrigger: ICoordinateBasedEventTrigger
) => ({
  type: SET_SELECTED_BOX_EVENT_TRIGGER,
  payload: eventTrigger
})

/**
 * Sets the highlighted event trigger.
 * @param eventTrigger IEventTrigger The highlighted event trigger.
 */
export const setHighlightedStreamEventTrigger = (
  eventTrigger: ICoordinateBasedEventTrigger
) => ({
  type: SET_HIGHLIGHTED_BOX_EVENT_TRIGGER,
  payload: eventTrigger
})

/**
 * Adds a specific event trigger type.
 * @param type EEventTriggerType The type of event trigger to add.
 * @param props dict Properties for the default event.
 */
export const addBoxEventTrigger = (type: EEventTriggerType, props) => {
  let eventTrigger: ICoordinateBasedEventTrigger | null = null

  switch (type) {
    case EEventTriggerType.crossingLine:
      eventTrigger = CrossingLineEventTrigger.default()
      break
    case EEventTriggerType.regionOfInterest:
      eventTrigger = RegionOfInterestEventTrigger.default(...props)
      break
    case EEventTriggerType.virtualDoor:
      eventTrigger = VirtualDoorEventTrigger.default()
      break
    case EEventTriggerType.originDestinationZone:
      eventTrigger = OriginDestinationZone.default()
      break
    default:
      return null
  }

  eventTrigger.hasChanged = true

  return {
    type: ADD_BOX_EVENT_TRIGGER,
    payload: eventTrigger
  }
}
