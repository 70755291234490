import './index.scss'
import './i18n'

import * as serviceWorker from './serviceWorker'

import { Config } from './services/config'
import React from 'react'
import { ai } from './services/telemetry'
import configureStore from './redux/store/configureStore'
import { createBrowserHistory } from 'history'
import { render } from 'react-dom'
import Root from './containers/Root'
import { Helmet } from 'react-helmet'

const store = configureStore()

// Check if key for app insights is set
if (Config.INSTRUMENTATION_KEY && process.env.NODE_ENV === 'production') {
  const browserHistory = createBrowserHistory({ basename: '' })
  ai.initialize(Config.INSTRUMENTATION_KEY, browserHistory)
}

let title = 'Swarm Control Center'
let favicon = '/swarm-favicon.ico'
if (Config.CUSTOM_THEME && Config.CUSTOM_THEME.toUpperCase() === 'BERNARD') {
  title = 'Bernard Control Center'
  favicon = '/bernard-favicon.ico'
} else if (
  Config.CUSTOM_THEME &&
  Config.CUSTOM_THEME.toUpperCase() === 'SWISSTRAFFIC'
) {
  title = 'Swisstraffic Control Center'
  favicon = '/swisstraffic-favicon.ico'
}

render(
  <>
    <Helmet>
      <link rel="icon" href={favicon} />
      <title>{title}</title>
    </Helmet>
    <Root store={store} />
  </>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
