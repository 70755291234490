import React, { useState } from 'react'

import './SceneStreams.scss'
import { IScene } from '../../../types/scene'
import { useTranslation } from 'react-i18next'
import { Button, Col, Collapse, Row, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { streamInfos } from '../../../redux/actions/streams'
import { IStreamInfo } from '../../../types/stream'
import { NavLink } from 'react-router-dom'
import DrawCanvas from '../../../components/DrawCanvas'
import { ICoordinateBasedEventTrigger } from '../../../types/eventTrigger'
import { ICameraFrame } from '../../../types/cameraFrame'
import { loadCameraFrame } from '../../../redux/actions/cameraFrame'
import { DeleteOutlined } from '@ant-design/icons'
import { RemoveConfirmationDialog } from '../SceneConfigurationConfirmationDialog/RemoveConfirmationDialog'
import StreamSelection from '../Helper/StreamSelection'
import OperationalStatusCell from '../../../components/BoxList/cells/OperationalStatusCell'
import { ERuntimeState } from '../../../types/runtimeState'
import { EErrorReason } from '../../../types/errorReason'

interface ISceneStreams {
  scene: IScene
  allStreams: IStreamInfo[]
  saveScene: Function
  cameraFrames: ICameraFrame[]
  loadingIds: boolean[]
  loadCameraFrame: (
    boxId: string,
    streamId: string,
    forceRefresh: boolean,
    calibration?: boolean
  ) => void
}

const SceneStreams: React.FC<ISceneStreams> = (props) => {
  const { t } = useTranslation()
  const { Panel } = Collapse

  const [newStreams, setNewStreams] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [deleteSceneDetail, setDeleteSceneDetail] = useState('')

  const onStreamRemove = async () => {
    if (!deleteSceneDetail || deleteSceneDetail.length === 0) {
      return
    }
    await removeStream(deleteSceneDetail)
    setIsDeleteDialogVisible(false)
  }

  const onStreamsAdd = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const newStreamData: IStreamInfo[] = []
    newStreams.forEach((streamId: string) => {
      let newStream = props.allStreams.find((item) => {
        return item.streamId === streamId
      })
      newStream && newStreamData.push(newStream)
    })
    await addStreams(newStreamData)
    setNewStreams([])
    setIsSubmitting(false)
  }

  const addStreams = async (streams) => {
    const sceneData = Object.assign({}, props.scene)
    !sceneData.boxStreams && (sceneData.boxStreams = [])
    streams.forEach((stream) => {
      sceneData.boxStreams.push(stream)
    })
    await props.saveScene(sceneData)
  }

  const removeStream = async (streamId) => {
    const sceneData = Object.assign({}, props.scene)
    sceneData.boxStreams = sceneData.boxStreams.filter((item) => {
      return item.streamId !== streamId
    })
    await props.saveScene(sceneData)
  }

  let handleNewStreamsChange = (data) => {
    setNewStreams(data)
  }

  const onCloseDialog = (event) => {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }
    setIsDeleteDialogVisible(false)
  }

  let openKeys = []
  const onOpenCallback = (keys) => {
    keys.forEach((key) => {
      if (!(key in openKeys)) {
        let stream = props.allStreams.find((stream) => {
          return stream.streamId === key
        })
        stream &&
          props.loadCameraFrame(stream.boxId, stream.streamId, true, false)
      }
    })
  }

  let filteredStreams: IStreamInfo[] = props.allStreams.filter((stream) => {
    return (
      !props.scene.boxStreams ||
      !props.scene.boxStreams.find((boxStream) => {
        return boxStream.streamId === stream.streamId
      })
    )
  })

  props.scene.boxStreams &&
    props.scene.boxStreams.sort((x, y) => {
      if (!x.streamName) {
        return 1
      }
      if (!y.streamName) {
        return -1
      }
      return x.streamName.localeCompare(y.streamName)
    })

  return (
    <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent">
      <Row gutter={[16, 16]}>
        <Col flex="auto" className="scc--flex--column">
          <StreamSelection
            placeholder={t('solutions.scenes.streams.addPlaceholder')}
            streams={filteredStreams}
            newStreams={newStreams}
            handleNewStreamsChange={handleNewStreamsChange}
            multi={true}
          />
        </Col>
        <Col flex="100px">
          <Button type="primary" onClick={onStreamsAdd} disabled={isSubmitting}>
            {t('solutions.scenes.streams.add')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Collapse
            className="scc--solutions--stream--collapse scc--solutions--streams"
            onChange={onOpenCallback}
          >
            {props.scene.boxStreams &&
              props.scene.boxStreams.map((stream, streamIndex) => (
                <Panel // It would be way nicer to move this panel to a separate FC, unfortunately this is not easy because of the Collapse functionality
                  key={stream.streamId}
                  className="scc--solutions--stream--collapse-item"
                  collapsible={stream.readOnly ? 'disabled' : undefined}
                  showArrow={!stream.readOnly}
                  header={
                    stream.streamName && stream.streamName.length > 0
                      ? stream.streamName
                      : t('configuration.group.stream.streamname.placeholder') +
                        (streamIndex + 1)
                  }
                  extra={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <OperationalStatusCell
                        runtimeState={
                          stream.streamStatus && stream.streamStatus.state
                            ? stream.streamStatus.state
                            : ERuntimeState.unknown
                        }
                        key={stream.streamStatus?.state}
                        errorReason={
                          stream.streamStatus &&
                          stream.streamStatus.state === 'NOT_OPERATIONAL'
                            ? stream.streamStatus.errorReason
                            : EErrorReason.unknown
                        }
                      />
                      <Tooltip
                        title={t('solutions.scenes.streams.remove')}
                        placement="bottomRight"
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          className="scc--solutions--remove-stream-button"
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            setIsDeleteDialogVisible(true)
                            setDeleteSceneDetail(stream.streamId)
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                >
                  <div className="bx--col-lg-12 scc--boxdetail--canvas">
                    <DrawCanvas
                      frame={props.cameraFrames[stream.streamId]}
                      eventTriggers={[] as ICoordinateBasedEventTrigger[]}
                      highlightedEventTrigger={''}
                      selectedEventTrigger={''}
                      onSelectEventTrigger={function () {}}
                      isEditable={false}
                      isLoading={props.loadingIds[stream.streamId]}
                      calibration={false}
                      onRefreshButtonClick={(event) => {
                        props.loadCameraFrame(
                          stream.boxId,
                          stream.streamId,
                          true,
                          event.calibration
                        )
                      }}
                    />
                    {props.cameraFrames[stream.streamId] &&
                      !props.loadingIds[stream.streamId] && (
                        <NavLink
                          to={`/boxes/${stream.boxId}/${stream.streamId}/setup?sceneId=${props.scene.id}`}
                        >
                          <button className="bx--btn bx--btn--primary">
                            {t('camera.frame.buttonDraw')}
                          </button>
                        </NavLink>
                      )}
                  </div>
                </Panel>
              ))}
            {(!props.scene.boxStreams ||
              props.scene.boxStreams.length === 0) && (
              <p className="scc--solutions--streams-empty">
                {t('solutions.scenes.streams.empty')}
              </p>
            )}
          </Collapse>
        </Col>
      </Row>
      <RemoveConfirmationDialog
        onRequestClose={onCloseDialog}
        onRequestSubmit={onStreamRemove}
        open={isDeleteDialogVisible}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    allStreams: state.streams.all,
    loadingIds: state.cameraFrames.loadingIds,
    cameraFrames: state.cameraFrames.byIds
  }
}

export default connect(mapStateToProps, {
  listStreams: streamInfos,
  loadCameraFrame
})(SceneStreams)
