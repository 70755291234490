export interface IBaseObject {
  width: number
  height: number
}

export interface IRelativePoint {
  x: number // Must be between 0 and 1
  y: number // Must be between 0 and 1
}

export interface ICoordinates {
  x: number // Absolute point in pixels
  y: number // Absolute point in pixels
}

export interface IEventTrigger {
  objectType: EEventTriggerType
  typeShort: string
  localId: string
  hasChanged?: boolean
}

export interface ICoordinateBasedEventTrigger extends IEventTrigger {
  id: string
  name: string
  coordinates: IRelativePoint[]

  convertRelativePointToPixels(baseObject: IBaseObject): ICoordinates[]

  convertPixelsToRelativePoint(
    coordinates: ICoordinates[],
    baseObject: IBaseObject
  ): IRelativePoint[]

  isSelected: boolean
}

export enum EEventTriggerType {
  regionOfInterest = 'regionOfInterest',
  virtualDoor = 'virtualDoor',
  originDestinationZone = 'originDestinationZone',
  crossingLine = 'crossingLine',
  heatMap = 'heatMap',
  anpr = 'anpr'
}

export class EEventTriggerUtil {
  public static toString(type: EEventTriggerType) {
    switch (type) {
      case 'regionOfInterest':
        return 'ROI'
      case 'originDestinationZone':
        return 'OD'
      case 'crossingLine':
        return 'CL'
      case 'heatMap':
        return 'HM'
      case 'anpr':
        return 'ANPR'
      case 'virtualDoor':
        return 'VD'
      default:
        return ''
    }
  }
}

/**
 * Abstract class that all event triggers inherit from.
 * Provides shared methods and properties.
 */
export abstract class AbstractCoordinateBasedEventTrigger
  implements ICoordinateBasedEventTrigger {
  id: string = ''
  localId: string = ''
  name: string = ''
  coordinates: IRelativePoint[] = []
  objectType: EEventTriggerType = EEventTriggerType.crossingLine
  isSelected: boolean = false

  get typeShort(): string {
    return EEventTriggerUtil.toString(this.objectType)
  }

  protected constructor(props: ICoordinateBasedEventTrigger) {
    this.name = props.name || ''
    this.id = props.id
    this.localId = props.localId || props.id
    this.objectType = props.objectType

    if (!Array.isArray(props.coordinates)) {
      this.coordinates = AbstractCoordinateBasedEventTrigger.transformCoordinatesToArray(
        props.coordinates
      )
    } else {
      this.coordinates = props.coordinates
    }
  }

  convertRelativePointToPixels(baseObject: IBaseObject): ICoordinates[] {
    return this.coordinates.map((relativePoint) => ({
      x: baseObject.width * relativePoint.x,
      y: baseObject.height * relativePoint.y
    }))
  }

  convertPixelsToRelativePoint(
    coordinates: ICoordinates[],
    baseObject: IBaseObject
  ): IRelativePoint[] {
    return coordinates.map((absolutePoint) => ({
      x: absolutePoint.x / baseObject.width,
      y: absolutePoint.y / baseObject.height
    }))
  }

  getRequestObject() {
    let object: ICoordinateBasedEventTrigger = Object.assign(
      {},
      this,
      this.getCoordinatesForApi()
    )

    delete object.hasChanged

    // Make sure object has an id before saving it
    if (!object.id) {
      object.id = object.localId
    }

    return object
  }

  getCoordinatesForApi() {
    const response = { coordinates: {} }
    this.coordinates.forEach((coordinate, index) => {
      const character = String.fromCharCode(index + 65) // Use character code to map letters
      response.coordinates[`point${character}`] = {
        x: coordinate.x,
        y: coordinate.y
      }
    })

    return response
  }

  public static transformCoordinatesToArray(coordinates: Object) {
    return Object.values(coordinates)
  }
}
