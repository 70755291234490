import './StreamConfiguration.scss'

import {
  AccordionItem,
  Button,
  Form,
  FormGroup,
  Select,
  SelectItem,
  TextInput
} from 'carbon-components-react'
import React from 'react'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ECameraConfigurationType } from '../../types/camera_configuration'
import { NavLink } from 'react-router-dom'
import { EMqttConfigurationType } from '../../types/mqtt_configuration'
import { IStream, IStreamDetails } from '../../types/stream'
import { ICameraFrame } from '../../types/cameraFrame'
import DrawCanvas from '../DrawCanvas'
import { IBox } from '../../types/box'
import { ICoordinateBasedEventTrigger } from '../../types/eventTrigger'
import { Col, Row, Switch } from 'antd'
import { Button as AntdButton } from 'antd'
import OperationalStatusCell from '../BoxList/cells/OperationalStatusCell'
import { ERuntimeState } from '../../types/runtimeState'
import { CopyOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import { EErrorReason } from '../../types/errorReason'

interface IStreamConfigurationFormProps {
  box: IBox
  stream: IStream
  streamDetails?: IStreamDetails
  streamIndex: number

  handleMqttConfigTypeChanged(
    mqttConfigurationType: EMqttConfigurationType,
    streamID: string
  )

  handleMqttCompressionChanged(toggled: boolean, streamID: string)

  handleCameraConfigTypeChanged(
    cameraConfigurationType: ECameraConfigurationType,
    streamID: string
  )

  onStreamFormSubmit(
    boxId: string,
    streamId: string,
    streamDetails: IStreamDetails
  ): void

  onStreamDelete(boxId: string, streamId: string): void

  isSubmitting: boolean
  open: boolean
  isNew: boolean

  loadCameraFrame: (
    boxId: string,
    streamId: string,
    calibration: boolean
  ) => void
  cameraFrame?: ICameraFrame
  isLoadingCameraFrame: boolean
}

const StreamConfigurationForm: React.FC<IStreamConfigurationFormProps> = (
  props
) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()
  const mqttConfig = props.streamDetails?.mqttConfig
  const cameraConfig = props.streamDetails?.cameraConfig
  const stream = props.stream
  const streamIndex = props.streamIndex
  const isLoadingCameraFrame = props.isLoadingCameraFrame
  const loadCameraFrame = props.loadCameraFrame
  const cameraFrame = props.cameraFrame

  const onSubmit = (data, event) => {
    event.preventDefault()
    props.onStreamFormSubmit(props.box.id, stream.id, data)
  }

  const onDelete = (event) => {
    event.preventDefault()
    props.onStreamDelete(props.box.id, stream.id)
  }

  const handleMqttConfigurationTypeChange = (event) => {
    props.handleMqttConfigTypeChanged(event.target.value, stream.id)
  }

  const handleMqttCompressionChanged = (event) => {
    props.handleMqttCompressionChanged(event, stream.id)
  }

  const handleCameraConfigurationTypeChange = (event) => {
    props.handleCameraConfigTypeChanged(event.target.value, stream.id)
  }

  const titleNode = (stream: IStream, streamIndex: number) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>
          {/* eslint-disable-next-line eqeqeq */}
          {stream.name != undefined && stream.name.length > 0
            ? stream.name
            : t('configuration.group.stream.streamname.placeholder') +
              (streamIndex + 1)}
        </span>
        <OperationalStatusCell
          runtimeState={
            stream.streamStatus && stream.streamStatus.state
              ? stream.streamStatus.state
              : ERuntimeState.unknown
          }
          key={stream.streamStatus?.state}
          errorReason={
            stream.streamStatus &&
            stream.streamStatus.state === 'NOT_OPERATIONAL'
              ? stream.streamStatus.errorReason
              : EErrorReason.unknown
          }
        />
      </div>
    )
  }
  return (
    <AccordionItem
      onHeadingClick={(titleClickEvent) => {
        if (titleClickEvent.isOpen === true) {
          loadCameraFrame(props.box.id, stream.id, false)
        }
      }}
      className="scc--stream--accordion-item"
      title={titleNode(stream, streamIndex)}
      open={props.open}
    >
      <div className="scc--boxdetail--streamId">
        <span>
          {t('configuration.group.stream.streamId', { id: stream.id })}
        </span>
        <AntdButton
          icon={<CopyOutlined />}
          className="scc--boxdetail--copyid-button"
          onClick={() => {
            copy(stream.id)
          }}
        />
      </div>

      <div className="scc--boxdetail--canvas">
        <DrawCanvas
          frame={cameraFrame}
          eventTriggers={[] as ICoordinateBasedEventTrigger[]}
          highlightedEventTrigger={''}
          selectedEventTrigger={''}
          onSelectEventTrigger={function () {}}
          isEditable={false}
          isLoading={isLoadingCameraFrame}
          onRefreshButtonClick={(event) => {
            loadCameraFrame(props.box.id, stream.id, event.calibration)
          }}
        />
        {cameraFrame && !isLoadingCameraFrame && (
          <NavLink to={`/boxes/${props.box.id}/${stream.id}/setup`}>
            <button className="bx--btn bx--btn--primary">
              {t('camera.frame.buttonDraw')}
            </button>
          </NavLink>
        )}
      </div>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        name="stream-config-form"
        className="bx-grid"
      >
        <div className="bx--row">
          <FormGroup
            legendText={t('configuration.group.streamMeta.legendText')}
            className="scc--icon--legend scc--icon--meta bx--col-lg-16 scc--connection"
          >
            <FormLabelWithTooltip id="configuration.group.streamMeta.streamName" />
            <TextInput
              id={'configuration.group.streamMeta.streamName.' + stream.id}
              className="scc--formfield__value-set"
              disabled={false}
              invalidText={t('configuration.invalidText')}
              labelText={false}
              placeholder={t(
                'configuration.group.streamMeta.streamName.placeholder'
              )}
              name="streamMeta.name"
              defaultValue={stream.name}
              ref={register}
            />
          </FormGroup>
          {cameraConfig && (
            <FormGroup
              legendText={t('configuration.group.cameraConnection.legendText')}
              className="scc--icon--legend scc--icon--camera bx--col-lg-8 scc--connection"
            >
              <FormLabelWithTooltip id="configuration.group.cameraConnection.cameraConfigurationType" />
              <Select
                id={
                  'configuration.group.cameraConnection.cameraConfigurationType.' +
                  stream.id
                }
                className="scc--formfield__value-set"
                inline={false}
                labelText={false}
                invalid={errors['cameraConfig.configurationType'] !== undefined}
                invalidText={t('configuration.invalidText')}
                name="cameraConfig.configurationType"
                ref={register({ required: true })}
                onChange={handleCameraConfigurationTypeChange}
                defaultValue={cameraConfig.configurationType}
              >
                {Object.values(ECameraConfigurationType).map((item) => (
                  <SelectItem
                    key={item}
                    value={item}
                    text={t(
                      `configuration.group.cameraConnection.cameraConfigurationType.values.${item}`
                    )}
                  />
                ))}
              </Select>
              {cameraConfig.configurationType ===
                ECameraConfigurationType.rawCameraConfiguration && (
                <>
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.connectionString" />
                  <TextInput
                    id="configuration.group.cameraConnection.cameraConnection.connectionString"
                    className="scc--formfield__value-set"
                    disabled={false}
                    invalid={
                      errors['cameraConfig.rawConnectionURI'] !== undefined
                    }
                    invalidText={t('configuration.invalidText')}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.cameraConnection.connectionString.placeholder'
                    )}
                    name="cameraConfig.rawConnectionURI"
                    defaultValue={cameraConfig.rawConnectionURI}
                    ref={register({ required: true })}
                  />
                </>
              )}
              {cameraConfig?.configurationType ===
                ECameraConfigurationType.granularCameraConfiguration && (
                <>
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.cameraHost" />
                  <TextInput
                    id={
                      'configuration.group.cameraConnection.cameraHost.' +
                      stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['cameraConfig'] && errors['cameraConfig']['host']
                    }
                    invalidText={t(
                      'configuration.group.cameraConnection.cameraHost.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.cameraConnection.cameraHost.placeholder'
                    )}
                    name="cameraConfig.host"
                    defaultValue={cameraConfig ? cameraConfig.host : null}
                    ref={register({
                      required: true,
                      /*
                See https://regex101.com/r/mU6bE3/9
                This regex validates that the camera host is either
                - an IP address of the form X.X.X.X where X is numeric and 1-3 digits
                - or a valid hostname (host, host.tld, subdomain.host.tld, etc). In this case alphanumeric characters, . and - are allowed
                - or for testing purposes / hidden feature a local camera /dev/XXX is  supported
                 */
                      pattern: /^(\/dev\/\w+)$|^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})$|^((([a-zA-Z]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]))$/
                    })}
                  />
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.port" />
                  <TextInput
                    id={
                      'configuration.group.cameraConnection.port.' + stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['cameraConfig'] && errors['cameraConfig']['port']
                    }
                    invalidText={t(
                      'configuration.group.cameraConnection.port.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.cameraConnection.port.placeholder'
                    )}
                    name="cameraConfig.port"
                    defaultValue={cameraConfig ? cameraConfig.port : null}
                    ref={register({ pattern: /^\d+$/ })}
                  />
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.path" />
                  <TextInput
                    id={
                      'configuration.group.cameraConnection.path.' + stream.id
                    }
                    disabled={false}
                    invalid={errors['cameraConfig.path'] !== undefined}
                    invalidText={t('configuration.invalidText')}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.cameraConnection.path.placeholder'
                    )}
                    name="cameraConfig.path"
                    defaultValue={cameraConfig ? cameraConfig.path : null}
                    ref={register}
                  />
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.username" />
                  <TextInput
                    id={
                      'configuration.group.cameraConnection.username.' +
                      stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['cameraConfig'] &&
                      errors['cameraConfig']['username']
                    }
                    invalidText={t(
                      'configuration.group.cameraConnection.username.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.cameraConnection.username.placeholder'
                    )}
                    name="cameraConfig.username"
                    defaultValue={cameraConfig ? cameraConfig.username : null}
                    ref={register({ required: false, pattern: /^[^@]*$/ })}
                  />
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.password" />
                  <TextInput
                    id={
                      'configuration.group.cameraConnection.password.' +
                      stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['cameraConfig'] &&
                      errors['cameraConfig']['password']
                    }
                    invalidText={t(
                      'configuration.group.cameraConnection.password.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.cameraConnection.password.placeholder'
                    )}
                    autoComplete="new-password"
                    type="password"
                    name="cameraConfig.password"
                    defaultValue={cameraConfig ? cameraConfig.password : null}
                    ref={register({ required: false, pattern: /^[^@]*$/ })}
                  />
                </>
              )}
            </FormGroup>
          )}

          {mqttConfig && (
            <FormGroup
              legendText={t('configuration.group.mqttConnection.legendText')}
              className="scc--icon--legend scc--icon--connection bx--col-lg-8 scc--connection"
            >
              <FormLabelWithTooltip id="configuration.group.mqttConnection.mqttConfigurationType" />
              <Select
                id={
                  'configuration.group.mqttConnection.mqttConfigurationType.' +
                  stream.id
                }
                className="scc--formfield__value-set"
                inline={false}
                labelText={false}
                invalid={errors['mqttConfig.configurationType'] !== undefined}
                invalidText={t('configuration.invalidText')}
                name="mqttConfig.configurationType"
                ref={register({ required: true })}
                onChange={handleMqttConfigurationTypeChange}
                defaultValue={mqttConfig.configurationType}
              >
                {Object.values(EMqttConfigurationType).map((item) => (
                  <SelectItem
                    key={item}
                    value={item}
                    text={t(
                      `configuration.group.mqttConnection.mqttConfigurationType.values.${item}`
                    )}
                  />
                ))}
              </Select>
              {mqttConfig.configurationType ===
                EMqttConfigurationType.custom && (
                <>
                  <FormLabelWithTooltip id="configuration.group.mqttConnection.mqttBroker" />
                  <TextInput
                    id={
                      'configuration.group.mqttConnection.mqttBroker.' +
                      stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['mqttConfig'] && errors['mqttConfig']['host']
                    }
                    invalidText={t(
                      'configuration.group.mqttConnection.mqttBroker.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.mqttConnection.mqttBroker.placeholder'
                    )}
                    name="mqttConfig.host"
                    defaultValue={mqttConfig ? mqttConfig.host : null}
                    ref={register({ required: true })}
                  />
                  <FormLabelWithTooltip id="configuration.group.mqttConnection.port" />
                  <TextInput
                    id={'configuration.group.mqttConnection.port.' + stream.id}
                    disabled={false}
                    invalid={
                      errors['mqttConfig'] && errors['mqttConfig']['port']
                    }
                    invalidText={t(
                      'configuration.group.mqttConnection.port.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.mqttConnection.port.placeholder'
                    )}
                    name="mqttConfig.port"
                    defaultValue={mqttConfig ? mqttConfig.port : null}
                    ref={register({ pattern: /^\d+$/ })}
                  />
                  <FormLabelWithTooltip id="configuration.group.mqttConnection.topic" />
                  <TextInput
                    id={'configuration.group.mqttConnection.topic.' + stream.id}
                    disabled={false}
                    invalid={errors['mqttConfig.topic'] !== undefined}
                    invalidText={t('configuration.invalidText')}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.mqttConnection.topic.placeholder'
                    )}
                    name="mqttConfig.topic"
                    defaultValue={mqttConfig ? mqttConfig.topic : null}
                    ref={register}
                  />
                  <FormLabelWithTooltip id="configuration.group.mqttConnection.username" />
                  <TextInput
                    id={
                      'configuration.group.mqttConnection.username.' + stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['mqttConfig'] && errors['mqttConfig']['username']
                    }
                    invalidText={t(
                      'configuration.group.mqttConnection.username.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.mqttConnection.username.placeholder'
                    )}
                    name="mqttConfig.username"
                    defaultValue={mqttConfig ? mqttConfig.username : null}
                    ref={register({ required: false, pattern: /^[^@]*$/ })}
                  />
                  <FormLabelWithTooltip id="configuration.group.mqttConnection.password" />
                  <TextInput
                    id={
                      'configuration.group.mqttConnection.password.' + stream.id
                    }
                    disabled={false}
                    invalid={
                      errors['mqttConfig'] && errors['mqttConfig']['password']
                    }
                    invalidText={t(
                      'configuration.group.mqttConnection.password.invalidText'
                    )}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.mqttConnection.password.placeholder'
                    )}
                    autoComplete="new-password"
                    type="password"
                    name="mqttConfig.password"
                    defaultValue={mqttConfig ? mqttConfig.password : null}
                    ref={register({ required: false, pattern: /^[^@]*$/ })}
                  />
                </>
              )}
              <FormLabelWithTooltip id="configuration.group.mqttConnection.compression" />
              <Switch
                checkedChildren={t(
                  'configuration.group.mqttConnection.compression.toggleOn'
                )}
                unCheckedChildren={t(
                  'configuration.group.mqttConnection.compression.toggleOff'
                )}
                checked={mqttConfig ? mqttConfig.compression : false}
                onChange={handleMqttCompressionChanged}
              />
            </FormGroup>
          )}
        </div>
        <Row>
          <Col flex="auto">
            <Button
              disabled={props.isSubmitting}
              kind="primary"
              size="small"
              type="submit"
            >
              {t('configuration.saveStream')}
            </Button>
          </Col>
          <Col flex="none">
            {!props.isNew && (
              <AntdButton
                disabled={props.isSubmitting}
                size="large"
                className="scc--stream--delete-button"
                type="default"
                onClick={onDelete}
              >
                {t('configuration.deleteStream')}
              </AntdButton>
            )}
          </Col>
        </Row>
      </Form>
    </AccordionItem>
  )
}

export default StreamConfigurationForm
