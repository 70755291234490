// === ACTION TYPES ===

// GET /box
export const BOX_REQUEST = 'BOX_REQUEST'
export const BOX_REQUEST_SUCCESS = 'BOX_REQUEST_SUCCESS'
export const BOX_REQUEST_FAILURE = 'BOX_REQUEST_FAILURE'

// GET /status/${statusId}
export const STATUS_REQUEST = 'STATUS_REQUEST'
export const STATUS_REQUEST_SUCCESS = 'STATUS_REQUEST_SUCCESS'
export const STATUS_REQUEST_FAILURE = 'STATUS_REQUEST_FAILURE'

// GET /boxes
export const BOXES_REQUEST = 'BOXES_REQUEST'
export const BOXES_REQUEST_SUCCESS = 'BOXES_REQUEST_SUCCESS'
export const BOXES_REQUEST_FAILURE = 'BOXES_REQUEST_FAILURE'

// PUT /boxes/${boxid}/reboot
export const BOXES_REBOOT_REQUEST = 'BOXES_REBOOT_REQUEST'
export const BOXES_REBOOT_REQUEST_SUCCESS = 'BOXES_REBOOT_REQUEST_SUCCESS'
export const BOXES_REBOOT_REQUEST_FAILURE = 'BOXES_REBOOT_REQUEST_FAILURE'

// GET /boxes/${boxid}/streams
export const STREAMS_REQUEST = 'STREAMS_REQUEST'
export const STREAMS_REQUEST_FAILURE = 'STREAMS_REQUEST_FAILURE'
export const STREAMS_REQUEST_SUCCESS = 'STREAMS_REQUEST_SUCCESS'

// PUT /boxes/${boxid}/${streamid}
export const SAVE_STREAM_REQUEST = 'SAVE_STREAM_REQUEST'
export const SAVE_STREAM_REQUEST_FAILURE = 'SAVE_STREAM_REQUEST_FAILURE'
export const SAVE_STREAM_REQUEST_SUCCESS = 'SAVE_STREAM_REQUEST_SUCCESS'

// DELETE /boxes/${boxid}/${streamid}
export const DELETE_STREAM_REQUEST = 'DELETE_STREAM_REQUEST'
export const DELETE_STREAM_REQUEST_FAILURE = 'DELETE_STREAM_REQUEST_FAILURE'
export const DELETE_STREAM_REQUEST_SUCCESS = 'DELETE_STREAM_REQUEST_SUCCESS'

// GET /streamInfos
export const STREAMS_INFO_REQUEST = 'STREAMS_INFO_REQUEST'
export const STREAMS_INFO_REQUEST_FAILURE = 'STREAMS_INFO_REQUEST_FAILURE'
export const STREAMS_INFO_REQUEST_SUCCESS = 'STREAMS_INFO_REQUEST_SUCCESS'

export const STREAMS_DETAILS_REQUEST = 'STREAMS_DETAILS_REQUEST'
export const RESET_STREAM_DETAILS = 'RESET_STREAM_DETAILS'
export const STREAMS_DETAILS_REQUEST_FAILURE = 'STREAMS_DETAILS_REQUEST_FAILURE'
export const STREAMS_DETAILS_REQUEST_SUCCESS = 'STREAMS_DETAILS_REQUEST_SUCCESS'

export const SAVE_STREAMS_DETAILS_REQUEST = 'SAVE_STREAMS_DETAILS_REQUEST'
export const SAVE_STREAMS_DETAILS_REQUEST_SUCCESS =
  'SAVE_STREAMS_DETAILS_REQUEST_SUCCESS'
export const SAVE_STREAMS_DETAILS_REQUEST_FAILURE =
  'SAVE_STREAMS_DETAILS_REQUEST_FAILURE'

// POST/PUT /boxes
export const SAVE_BOX_REQUEST = 'SAVE_BOX_REQUEST'
export const SAVE_BOX_REQUEST_SUCCESS = 'SAVE_BOX_REQUEST_SUCCESS'
export const SAVE_BOX_REQUEST_FAILURE = 'SAVE_BOX_REQUEST_FAILURE'

// GET /boxes/${boxid}/getFrame
export const GET_CAMERA_FRAME = 'GET_CAMERA_FRAME'
export const GET_CAMERA_FRAME_SUCCESS = 'GET_CAMERA_FRAME_SUCCESS'
export const GET_CAMERA_FRAME_FAILURE = 'GET_CAMERA_FRAME_FAILURE'
export const RESET_CAMERA_FRAME = 'RESET_CAMERA_FRAME'

// GET /boxes/${boxid}/${streamId}/configurations/camera
export const CAMERA_CONFIG_REQUEST = 'CAMERA_CONFIG_REQUEST'
export const CAMERA_CONFIG_REQUEST_SUCCESS = 'CAMERA_CONFIG_REQUEST_SUCCESS'
export const CAMERA_CONFIG_REQUEST_FAILURE = 'CAMERA_CONFIG_REQUEST_FAILURE'

// GET /boxes/${id}/eventTriggers
export const BOX_EVENT_TRIGGERS_REQUEST = 'BOX_EVENT_TRIGGERS_REQUEST'
export const BOX_EVENT_TRIGGERS_REQUEST_SUCCESS =
  'BOX_EVENT_TRIGGERS_REQUEST_SUCCESS'
export const BOX_EVENT_TRIGGERS_REQUEST_FAILURE =
  'BOX_EVENT_TRIGGERS_REQUEST_FAILURE'
export const RESET_BOX_EVENT_TRIGGERS = 'RESET_EVENT_TRIGGERS'

// PUT /boxes/${id}/eventTriggers
export const SAVE_BOX_EVENT_TRIGGER_REQUEST = 'SAVE_BOX_EVENT_TRIGGER_REQUEST'
export const SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS =
  'SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS'
export const SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE =
  'SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE'

export const SAVE_BOX_EVENT_TRIGGERS_REQUEST = 'SAVE_BOX_EVENT_TRIGGERS_REQUEST'
export const SAVE_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS =
  'SAVE_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS'
export const SAVE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE =
  'SAVE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE'

// BOX EVENT TRIGGERS
export const UPDATE_BOX_EVENT_TRIGGER = 'UPDATE_BOX_EVENT_TRIGGER'
export const SET_SELECTED_BOX_EVENT_TRIGGER = 'SET_SELECTED_BOX_EVENT_TRIGGER'
export const SET_HIGHLIGHTED_BOX_EVENT_TRIGGER =
  'SET_HIGHLIGHTED_BOX_EVENT_TRIGGER'
export const ADD_BOX_EVENT_TRIGGER = 'ADD_BOX_EVENT_TRIGGER'
export const DELETE_BOX_EVENT_TRIGGER = 'DELETE_BOX_EVENT_TRIGGER'

// DELETE /boxes/${id}/eventTriggers/${triggerId}
export const DELETE_BOX_EVENT_TRIGGER_REQUEST =
  'DELETE_BOX_EVENT_TRIGGER_REQUEST'
export const DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS =
  'DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS'
export const DELETE_BOX_EVENT_TRIGGER_REQUEST_FAILURE =
  'DELETE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE'

// GET /configurations/mqtt/${uuid}
export const MQTT_CONFIG_REQUEST = 'MQTT_CONFIG_REQUEST'
export const MQTT_CONFIG_REQUEST_SUCCESS = 'MQTT_CONFIG_REQUEST_SUCCESS'
export const MQTT_CONFIG_REQUEST_FAILURE = 'MQTT_CONFIG_REQUEST_FAILURE'

// POST/PUT /configurations/camera
export const SAVE_CAMERA_CONFIG_REQUEST = 'SAVE_CAMERA_CONFIG_REQUEST'
export const SAVE_CAMERA_CONFIG_REQUEST_SUCCESS =
  'SAVE_CAMERA_CONFIG_REQUEST_SUCCESS'
export const SAVE_CAMERA_CONFIG_REQUEST_FAILURE =
  'SAVE_CAMERA_CONFIG_REQUEST_FAILURE'

// POST/PUT /configurations/mqtt
export const SAVE_MQTT_CONFIG_REQUEST = 'SAVE_MQTT_CONFIG_REQUEST'
export const SAVE_MQTT_CONFIG_REQUEST_SUCCESS =
  'SAVE_MQTT_CONFIG_REQUEST_SUCCESS'
export const SAVE_MQTT_CONFIG_REQUEST_FAILURE =
  'SAVE_MQTT_CONFIG_REQUEST_FAILURE'

export const BOX_DETAILS_REQUEST = 'BOX_DETAILS_REQUEST'
export const BOX_DETAILS_REQUEST_SUCCESS = 'BOX_DETAILS_REQUEST_SUCCESS'
export const BOX_DETAILS_REQUEST_FAILURE = 'BOX_DETAILS_REQUEST_FAILURE'
export const RESET_BOX_DETAILS = 'RESET_BOX_DETAILS'

// All box configurations have been saved
export const SAVE_BOX_DETAILS_REQUEST = 'SAVE_BOX_DETAILS_REQUEST'
export const SAVE_BOX_DETAILS_REQUEST_SUCCESS =
  'SAVE_BOX_DETAILS_REQUEST_SUCCESS'
export const SAVE_BOX_DETAILS_REQUEST_FAILURE =
  'SAVE_BOX_DETAILS_REQUEST_FAILURE'

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'

// PUT /solutions/groups/${groupId}
export const SAVE_GROUP_REQUEST = 'SAVE_GROUP_REQUEST'
export const SAVE_GROUP_REQUEST_FAILURE = 'SAVE_GROUP_REQUEST_FAILURE'
export const SAVE_GROUP_REQUEST_SUCCESS = 'SAVE_GROUP_REQUEST_SUCCESS'

// DELETE /solutions/groups/${groupId}
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST'
export const DELETE_GROUP_REQUEST_FAILURE = 'DELETE_GROUP_REQUEST_FAILURE'
export const DELETE_GROUP_REQUEST_SUCCESS = 'DELETE_GROUP_REQUEST_SUCCESS'

// GET /solutions/groups/${groupId}
export const LIST_GROUPS_REQUEST = 'LIST_GROUPS_REQUEST'
export const LIST_GROUPS_REQUEST_FAILURE = 'LIST_GROUPS_REQUEST_FAILURE'
export const LIST_GROUPS_REQUEST_SUCCESS = 'LIST_GROUPS_REQUEST_SUCCESS'

// PUT /solutions/scenes/${sceneId}
export const SAVE_SCENE_REQUEST = 'SAVE_SCENE_REQUEST'
export const SAVE_SCENE_REQUEST_FAILURE = 'SAVE_SCENE_REQUEST_FAILURE'
export const SAVE_SCENE_REQUEST_SUCCESS = 'SAVE_SCENE_REQUEST_SUCCESS'

// DELETE /solutions/scenes/${sceneId}
export const DELETE_SCENE_REQUEST = 'DELETE_SCENE_REQUEST'
export const DELETE_SCENE_REQUEST_FAILURE = 'DELETE_SCENE_REQUEST_FAILURE'
export const DELETE_SCENE_REQUEST_SUCCESS = 'DELETE_SCENE_REQUEST_SUCCESS'

// GET /solutions/scenes
export const LIST_SCENES_REQUEST = 'LIST_SCENES_REQUEST'
export const LIST_SCENES_REQUEST_FAILURE = 'LIST_SCENES_REQUEST_FAILURE'
export const LIST_SCENES_REQUEST_SUCCESS = 'LIST_SCENES_REQUEST_SUCCESS'

// GET /solutions/groups/${groupId}/scenes
export const LIST_SCENES_GROUP_REQUEST = 'LIST_SCENES_GROUP_REQUEST'
export const LIST_SCENES_GROUP_REQUEST_FAILURE =
  'LIST_SCENES_GROUP_REQUEST_FAILURE'
export const LIST_SCENES_GROUP_REQUEST_SUCCESS =
  'LIST_SCENES_GROUP_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/crossinglines
export const LIST_SCENES_CLINES_REQUEST = 'LIST_SCENES_CLINES_REQUEST'
export const LIST_SCENES_CLINES_REQUEST_FAILURE =
  'LIST_SCENES_CLINES_REQUEST_FAILURE'
export const LIST_SCENES_CLINES_REQUEST_SUCCESS =
  'LIST_SCENES_CLINES_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/speedlines
export const LIST_SCENES_SPEEDLINES_REQUEST = 'LIST_SCENES_SPEEDLINES_REQUEST'
export const LIST_SCENES_SPEEDLINES_REQUEST_FAILURE =
  'LIST_SCENES_SPEEDLINES_REQUEST_FAILURE'
export const LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS =
  'LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/regions
export const LIST_SCENES_REGIONS_REQUEST = 'LIST_SCENES_REGIONS_REQUEST'
export const LIST_SCENES_REGIONS_REQUEST_FAILURE =
  'LIST_SCENES_REGIONS_REQUEST_FAILURE'
export const LIST_SCENES_REGIONS_REQUEST_SUCCESS =
  'LIST_SCENES_REGIONS_REQUEST_SUCCESS'

// GET /solutions/scenes/anprimages
export const GET_ANPRIMAGE_REQUEST = 'GET_ANPRIMAGE_REQUEST'
export const GET_ANPRIMAGE_REQUEST_FAILURE = 'GET_ANPRIMAGE_REQUEST_FAILURE'
export const GET_ANPRIMAGE_REQUEST_SUCCESS = 'GET_ANPRIMAGE_REQUEST_SUCCESS'
