import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch } from 'antd'
import FormLabelWithTooltip from '../../../components/FormLabelWithToolTip'

const SpeedToggle = ({ measures, handleSpeedToggleChange }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormLabelWithTooltip id="solutions.scenes.overview.add.data.speed" />

      <p>{t(`solutions.scenes.overview.add.data.speed.includeSpeedAverage`)}</p>
      <Switch
        onClick={handleSpeedToggleChange}
        checked={
          !!measures.find((entry) => {
            return entry.name === 'CrossingEvents.averageSpeed'
          })
        }
        checkedChildren={t(`solutions.scenes.overview.add.data.speed.toggleOn`)}
        unCheckedChildren={t(
          `solutions.scenes.overview.add.data.speed.toggleOff`
        )}
      ></Switch>
    </>
  )
}

export default SpeedToggle
