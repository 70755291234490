/* eslint-disable jsx-a11y/role-supports-aria-props */
import './AccountInfo.scss'

import React, { createRef, useEffect, useState } from 'react'

import account from '../../theme/icons/account.svg'

import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'

interface IAccountInfoProps {
  children?
}

const AccountInfo: React.FC<IAccountInfoProps> = (props) => {
  let [isExpanded, setIsExpanded] = useState(false)
  const instance = useMsal().instance
  const userAccount = useMsal().accounts[0]

  const node = createRef<HTMLDivElement>()
  const { t } = useTranslation()

  // Use hook to add an event listener for closing the
  // the overlay when the user clicks outside of the
  // component
  useEffect(() => {
    function handleClick(event) {
      if (node && node.current && node.current.contains(event.target)) {
        return
      }

      setIsExpanded(false)
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [node])

  function toggleMenu() {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="scc--account__info" ref={node}>
      {userAccount && (
        <ul className="bx--header__menu-bar">
          <li className="bx--header__submenu">
            <a
              href="#account"
              aria-expanded={isExpanded}
              aria-haspopup="menu"
              className="bx--header__menu-item bx--header__menu-title last"
              role="menuitem"
              aria-label="Account Info"
              onClick={toggleMenu}
            >
              <span className="scc--account__name">
                {userAccount.name || userAccount.username}
              </span>
              <img className="scc--account__icon" src={account} alt="Account" />
            </a>
            <ul
              aria-label="Account Info"
              className="bx--header__menu"
              role="menu"
            >
              <li role="none">
                <a
                  href="#logout"
                  className="bx--header__menu-item"
                  role="menuitem"
                  onClick={async () => {
                    await instance.logout()
                  }}
                >
                  <span>{t('accountInfo.logout')}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      )}
    </div>
  )
}

export default AccountInfo
