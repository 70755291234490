import './ErrorDialog.scss'

import { Accordion, AccordionItem } from 'carbon-components-react'

import { Modal } from 'carbon-components-react'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface IErrorDialogProps {
  error: any
  state: any
}

const ErrorDialog: React.FC<IErrorDialogProps> = (props) => {
  const { t } = useTranslation()
  const { error, state } = props

  if (!error) {
    return null
  }

  let errorMessage = t('general.error.defaultMessage')

  if (error.response) {
    switch (error.response.status) {
      case 401:
        errorMessage = t('general.error.notAuthorized')
        break
    }
  } else if (error.message) {
    errorMessage = error.message
  }

  return (
    <Modal
      className="scc--error-dialog"
      modalHeading={t('general.error.title')}
      aria-label="Error Dialog"
      open={!!error}
      passiveModal={true}
      danger={true}
      size="sm"
      {...props}
    >
      {error && <p>{errorMessage}</p>}

      <div
        className="scc--error-details"
        style={{
          display: process.env.NODE_ENV === 'development' ? 'block' : 'none'
        }}
      >
        <Accordion>
          <AccordionItem title={t('general.error.details.errorTitle')}>
            {JSON.stringify(error)}
          </AccordionItem>
          {state && (
            <AccordionItem title={t('general.error.details.storeTitle')}>
              {JSON.stringify(state)}
            </AccordionItem>
          )}
        </Accordion>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  state: state,
  error: state.error
})

export default connect(mapStateToProps, {})(ErrorDialog)
