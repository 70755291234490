import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

const MeasurementSelection = ({
  availableMeasurements,
  measures,
  allMeasures,
  handleFieldChange,
  multi
}) => {
  const { t } = useTranslation()
  const { Option } = Select

  const attributes = {}
  multi && (attributes['mode'] = 'multiple')

  return (
    <Select
      optionFilterProp="label"
      allowClear
      value={measures}
      onChange={handleFieldChange}
      key="input"
      placeholder={t('solutions.scenes.overview.add.data.selectMeasurement')}
      style={{ width: '100%' }}
      {...attributes}
    >
      {availableMeasurements.map((measureName) => {
        let measure = allMeasures.find((item) => {
          return item.name === measureName
        })
        return (
          measure && (
            <Option label={measure.title} value={measureName} key={measureName}>
              {measure.shortTitle}
            </Option>
          )
        )
      })}
    </Select>
  )
}

export default MeasurementSelection
