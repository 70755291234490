import React, { Component } from 'react'
import { ECameraConfigurationType } from '../types/camera_configuration'
import { EMqttConfigurationType } from '../types/mqtt_configuration'
import { connect } from 'react-redux'
import { loadCameraFrame } from '../redux/actions/cameraFrame'
import {
  loadStreamDetails,
  saveStreamDetails
} from '../redux/actions/streamDetails'
import { withTranslation } from 'react-i18next'
import StreamConfigurationForm from '../components/StreamConfigurationForm'
import { Accordion } from 'carbon-components-react'
import {
  DefaultStream,
  DefaultStreamDetails,
  IStream,
  IStreamDetails
} from '../types/stream'
import { DeviceType, IBox } from '../types/box'
import _ from 'lodash'
import { ICameraFrame } from '../types/cameraFrame'
import { StreamConfigurationConfirmationDialog } from '../components/StreamConfigurationConfirmationDialog/StreamConfigurationConfirmationDialog'
import { notify } from '../services/notify'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Button, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { DeleteConfirmationDialog } from '../components/StreamConfigurationConfirmationDialog/DeleteConfirmationDialog'
import { deleteStream } from '../redux/actions/streams'
import { Config } from '../services/config'

interface IStreamConfigurationContainerProps {
  streams: [IStream]
  streamdetails: IStreamDetails[]
  box: IBox
  isSubmitting: boolean
  loadStreamDetails: (boxId: string, streamId: string) => Promise<void>
  saveStreamDetails: (boxId: string, streamId: string, data) => Promise<void>
  deleteStream: (boxId: string, streamId: string) => Promise<void>

  loadCameraFrame: (
    boxId: string,
    streamId: string,
    forceRefresh: boolean,
    calibration?: boolean
  ) => void
  resetCameraFrame: Function
  cameraFrames: ICameraFrame[]
  loadingIds: boolean[]
  t: any
}

interface IStreamConfigurationContainerState {
  isConfirmationDialogVisible: boolean
  isDeleteDialogVisible: boolean
  isLoading: boolean
  updatedStreamDetails: IStreamDetails
  deleteStreamDetails
  streamdetails: IStreamDetails[]
  newStreams: IStream[]
}

class StreamConfigurationContainer extends Component<
  IStreamConfigurationContainerProps,
  IStreamConfigurationContainerState
> {
  state

  constructor(props) {
    super(props)
    this.state = {
      isConfirmationDialogVisible: false,
      isDeleteDialogVisible: false,
      isLoading: true,
      streamdetails: [],
      updatedStreamDetails: DefaultStreamDetails,
      newStreams: []
    }
    this.handleStreamConfigurationFormSubmit = this.handleStreamConfigurationFormSubmit.bind(
      this
    )
    this.handleStreamDelete = this.handleStreamDelete.bind(this)
    this.loadCameraFrameForStream = this.loadCameraFrameForStream.bind(this)
    this.saveStreamConfig = this.saveStreamConfig.bind(this)
    this.deleteStreamConfig = this.deleteStreamConfig.bind(this)
    this.addNewStream = this.addNewStream.bind(this)
    this.isNewStream = this.isNewStream.bind(this)
    this.maxStreamsReached = this.maxStreamsReached.bind(this)
    this.handleCameraConfigTypeChanged = this.handleCameraConfigTypeChanged.bind(
      this
    )
    this.handleMqttConfigTypeChanged = this.handleMqttConfigTypeChanged.bind(
      this
    )
    this.handleMqttCompressionChanged = this.handleMqttCompressionChanged.bind(
      this
    )
    this.onCloseDialog = this.onCloseDialog.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        prevProps.streams.filter((x) => !this.isNewStream(x.id)),
        this.props.streams.filter((x) => !this.isNewStream(x.id))
      )
    ) {
      this.loadData()
    }
  }

  loadData() {
    Promise.all(
      this.props.streams.map((stream) =>
        this.props.loadStreamDetails(this.props.box.id, stream.id)
      )
    ).then(
      (_) => {
        this.setState({
          isLoading: false,
          streamdetails: this.props.streamdetails // pull state from redux
        })
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log('Error loading streamdetails ' + error)
      }
    )
  }

  handleMqttCompressionChanged(compressionToggled: boolean, streamId: string) {
    let streamConfig = this.state.streamdetails[streamId]
    streamConfig.mqttConfig.compression = compressionToggled
    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  handleMqttConfigTypeChanged(
    mqttConfigurationType: EMqttConfigurationType,
    streamId: string
  ) {
    let streamConfig = this.state.streamdetails[streamId]
    streamConfig.mqttConfig.configurationType = mqttConfigurationType
    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  handleCameraConfigTypeChanged(
    cameraConfigurationType: ECameraConfigurationType,
    streamId: string
  ) {
    let streamConfig = this.state.streamdetails[streamId]
    streamConfig.cameraConfig.configurationType = cameraConfigurationType
    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  loadCameraFrameForStream(
    boxId: string,
    streamId: string,
    calibration: boolean
  ) {
    if (!this.state.streamdetails[streamId]) {
      return
    }
    let cameraConfig = this.state.streamdetails[streamId].cameraConfig
    if (
      cameraConfig.host ||
      cameraConfig.rawConnectionURI ||
      cameraConfig.configurationType ===
        ECameraConfigurationType.usbCameraConfiguration
    ) {
      this.props.loadCameraFrame(boxId, streamId, false, calibration)
    }
  }

  handleStreamConfigurationFormSubmit(
    boxId: string,
    streamId: string,
    streamDetails: IStreamDetails
  ) {
    let updateDetail = streamDetails
    updateDetail.streamId = streamId

    this.state.streamdetails[streamId] &&
      (updateDetail.mqttConfig.compression = this.state.streamdetails[
        streamId
      ].mqttConfig.compression)
    this.setState({
      isConfirmationDialogVisible: true,
      updatedStreamDetails: updateDetail
    })
  }

  handleStreamDelete(boxId: string, streamId: string) {
    this.setState({
      deleteStreamDetails: { boxId: boxId, streamId: streamId },
      isDeleteDialogVisible: true
    })
  }

  deleteStreamConfig() {
    this.props
      .deleteStream(
        this.state.deleteStreamDetails.boxId,
        this.state.deleteStreamDetails.streamId
      )
      .then(() => {
        notify({
          title: this.props.t('notification.configuration.saved.title'),
          message: this.props.t('notification.configuration.saved.message')
        })
      })

    this.setState({
      isDeleteDialogVisible: false
    })
  }

  saveStreamConfig() {
    this.props
      .saveStreamDetails(
        this.props.box.id,
        this.state.updatedStreamDetails.streamId,
        this.state.updatedStreamDetails
      )
      .then((data) => {
        notify({
          title: this.props.t('notification.configuration.saved.title'),
          message: this.props.t('notification.configuration.saved.message')
        })

        if (this.isNewStream(this.state.updatedStreamDetails.streamId)) {
          this.setState({
            newStreams: this.state.newStreams.filter(
              (stream) => stream.id !== this.state.updatedStreamDetails.streamId
            )
          })
          this.loadData()
        }
        // Reload frame
        this.props.loadCameraFrame(
          this.props.box.id,
          this.state.updatedStreamDetails.streamId,
          true,
          false
        )
      })

    this.setState({
      isConfirmationDialogVisible: false
    })
  }

  onCloseDialog(event) {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }

    this.setState({
      isConfirmationDialogVisible: false,
      isDeleteDialogVisible: false
    })
  }

  isNewStream(streamId) {
    return !!this.state.newStreams.find(
      (newStream) => newStream.id === streamId
    )
  }

  maxStreamsReached() {
    let device = this.props.box.type
    if (!device || device === DeviceType.UNSPECIFIED) {
      return false
    }

    let numStreams = this.props.streams.length
    let maxStreams =
      Config['MAX_STREAMS_PER_DEVICE_' + device.toUpperCase().replace('-', '_')]
    return numStreams >= maxStreams
  }

  addNewStream() {
    let id = uuidv4()
    let newStreams = this.state.newStreams
    newStreams.push(
      Object.assign({}, DefaultStream, {
        id: id,
        new: true
      })
    )
    let streamDetails = DefaultStreamDetails
    streamDetails.streamId = id
    let updatedStreamDetails = Object.assign({}, this.state.streamdetails, {})
    updatedStreamDetails[id] = streamDetails
    this.setState({
      streamdetails: updatedStreamDetails
    })
  }

  render() {
    const streams = this.props.streams
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    streams.sort(function (lhs, rhs) {
      // eslint-disable-next-line eqeqeq
      if (lhs.name == undefined) {
        return 1
      }
      // eslint-disable-next-line eqeqeq
      if (rhs.name == undefined) {
        return -1
      }
      return collator.compare(lhs.name, rhs.name)
    })
    this.state.newStreams.forEach((newStream) => {
      if (!streams.find((stream) => stream.id === newStream.id)) {
        streams.push(newStream)
      }
    })
    const isLoading = this.state.isLoading
    let enableMaxStreamsTooltip = this.maxStreamsReached()
      ? {}
      : {
          visible: false
        }
    return (
      <>
        <Scrollbars autoHide={true}>
          {!isLoading && (
            <Accordion className="scc--stream--accordion">
              {streams.map((stream, streamIndex) => (
                <StreamConfigurationForm
                  box={this.props.box}
                  key={stream.id}
                  streamIndex={streamIndex}
                  stream={stream}
                  cameraFrame={this.props.cameraFrames[stream.id]}
                  loadCameraFrame={this.loadCameraFrameForStream}
                  isLoadingCameraFrame={this.props.loadingIds[stream.id]}
                  streamDetails={this.state.streamdetails[stream.id]}
                  handleMqttCompressionChanged={
                    this.handleMqttCompressionChanged
                  }
                  handleMqttConfigTypeChanged={this.handleMqttConfigTypeChanged}
                  handleCameraConfigTypeChanged={
                    this.handleCameraConfigTypeChanged
                  }
                  onStreamFormSubmit={this.handleStreamConfigurationFormSubmit}
                  onStreamDelete={this.handleStreamDelete}
                  isSubmitting={this.props.isSubmitting}
                  open={this.isNewStream(stream.id)}
                  isNew={this.isNewStream(stream.id)}
                />
              ))}
            </Accordion>
          )}
          <Tooltip
            placement="bottomLeft"
            title={this.props.t('configuration.maxStreamWarning')}
            {...enableMaxStreamsTooltip}
          >
            <Button
              className="scc--stream--add-button"
              type="primary"
              size="large"
              onClick={this.addNewStream}
              disabled={this.maxStreamsReached()}
            >
              {this.props.t('configuration.addStream')}
            </Button>
          </Tooltip>
        </Scrollbars>
        <StreamConfigurationConfirmationDialog
          onCloseDialog={this.onCloseDialog}
          onRequestSubmit={this.saveStreamConfig}
          isVisible={this.state.isConfirmationDialogVisible}
        />
        <DeleteConfirmationDialog
          onRequestClose={this.onCloseDialog}
          onRequestSubmit={this.deleteStreamConfig}
          open={this.state.isDeleteDialogVisible}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    streams: state.streams.allIds.map((id) => state.streams.byIds[id]),
    isSubmitting: state.boxDetails.isSubmitting,
    streamdetails: state.streamDetails.byIds,
    cameraFrames: state.cameraFrames.byIds,
    loadingIds: state.cameraFrames.loadingIds
  }
}

export default withTranslation()(
  connect(mapStateToProps, {
    saveStreamDetails,
    loadCameraFrame,
    loadStreamDetails,
    deleteStream
  })(StreamConfigurationContainer)
)
