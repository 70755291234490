import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError
} from '@apollo/client'
import { Config } from '../../services/config'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getIdToken } from '../../services/ApiTokenProvider'
import { Observable } from 'apollo-client/util/Observable'

const httpLink = createHttpLink({
  uri: Config.API_ROOT + 'graphql'
})

// cached storage for the user token
let token
const authLink = setContext((_, { headers }) => {
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    }
  }

  return getIdToken().then((idToken) => {
    token = idToken
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    }
  })
})

const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return
        }
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => {
        subscriber.error(err)
      }
    )
  })
}

// @ts-ignore
// https://github.com/apollographql/apollo-link/issues/646
const resetToken = onError(({ networkError, operation, forward }) => {
  if (!networkError) return
  if (
    (networkError.name === 'ServerError' &&
      (networkError as ServerError).statusCode === 401) ||
    (networkError.name === 'ServerParseError' &&
      (networkError as ServerParseError).statusCode === 401)
  ) {
    const oldHeaders = operation.getContext().headers
    const promise = getIdToken()
    return promiseToObservable(promise).flatMap((newToken) => {
      token = newToken
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: newToken
        }
      })
      // retry the request, returning the new observable
      return forward(operation)
    })
  }
})

const link = resetToken.concat(authLink).concat(httpLink)

const apollo = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: link
  })
}

export default apollo
