import {
  CAMERA_CONFIG_REQUEST_FAILURE,
  CAMERA_CONFIG_REQUEST_SUCCESS,
  SAVE_CAMERA_CONFIG_REQUEST,
  SAVE_CAMERA_CONFIG_REQUEST_FAILURE,
  SAVE_CAMERA_CONFIG_REQUEST_SUCCESS
} from '../actions/actionTypes'

import union from 'lodash/union'
import { ICameraConfiguration } from '../../types/camera_configuration'

const initialState = {
  allIds: [],
  byIds: {},
  isSubmitting: false
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case CAMERA_CONFIG_REQUEST_SUCCESS:
      return {
        ...state,
        allIds: union(state.allIds, [action.response.result]),
        byIds: Object.assign(
          {},
          state.byIds,
          action.response.entities.configurations
        )
      }

    case CAMERA_CONFIG_REQUEST_FAILURE:
      return Object.assign({}, state, { errorMessage: action.error })

    case SAVE_CAMERA_CONFIG_REQUEST:
      return {
        ...state,
        isSubmitting: true
      }

    case SAVE_CAMERA_CONFIG_REQUEST_SUCCESS:
      let resultConfig: ICameraConfiguration = Object.values(
        action.response.entities.configurations
      )[0] as ICameraConfiguration
      let streamid = resultConfig.streamId
      if (streamid !== undefined) {
        let resultObject = {}
        resultObject[streamid] = resultConfig
        return {
          ...state,
          isSubmitting: false,
          allIds: union(state.allIds, [resultConfig.streamId]),
          byIds: Object.assign({}, state.byIds, resultObject)
        }
      } else {
        return {
          ...state,
          isSubmitting: false
        }
      }

    case SAVE_CAMERA_CONFIG_REQUEST_FAILURE:
      return {
        ...state,
        isSubmitting: false
      }

    default:
      return state
  }
}
export default fn
