import { ICoordinateBasedEventTrigger } from '../types/eventTrigger'
import { NavLink, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import {
  deleteStreamEventTrigger,
  setHighlightedStreamEventTrigger,
  setSelectedStreamEventTrigger
} from '../redux/actions/eventTriggers'
import { loadBoxDetails, resetBoxDetails } from '../redux/actions/boxDetails'
import { loadBoxStreams } from '../redux/actions/streams'

import { resetBoxEventTriggers } from '../redux/actions/eventTriggers'

import BoxDetailsHeader from '../components/BoxDetailsHeader'
import { CloseOutline24 } from '@carbon/icons-react'
import { IBox } from '../types/box'
import { IBoxDetails } from '../types/boxDetails'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import StreamConfigurationContainer from './StreamConfigurationContainer'
import { IStream } from '../types/stream'

interface IBoxDetailPageProps {
  children?
  match: any
  history: any
  box: IBox
  eventTriggers: ICoordinateBasedEventTrigger[]
  boxDetails: IBoxDetails
  streams: IStream
  updateBoxEventTrigger: any
  addBoxEventTrigger: (EEventTriggerType) => void
  deleteStreamEventTrigger: Function
  setSelectedBoxEventTrigger: (IEventTrigger) => void
  setHighlightedBoxEventTrigger: (IEventTrigger) => void
  highlightedEventTrigger: string
  selectedEventTrigger: string
  resetBoxDetails: Function
  loadBoxDetails: (string) => void
  loadBoxStreams: (string) => void
  resetBoxEventTriggers: Function
  t: any
  i18n: any
  tReady: any
}

interface IBoxDetailPageState {
  isDeleteConfirmationDialogVisible: boolean
  eventTriggerToDelete: ICoordinateBasedEventTrigger | null
}

class BoxDetailPage extends Component<
  IBoxDetailPageProps,
  IBoxDetailPageState
> {
  constructor(props) {
    super(props)

    this.state = {
      isDeleteConfirmationDialogVisible: false,
      eventTriggerToDelete: null
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    // Prevent loading same data twice
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadData()
    }
  }

  loadData() {
    const { id } = this.props.match.params

    this.props.resetBoxDetails()
    this.props.resetBoxEventTriggers()

    if (id) {
      this.props.loadBoxDetails(id)
      this.props.loadBoxStreams(id)
    }
  }

  render() {
    const containerClassNames = this.props.box
      ? 'scc--boxdetail--container shown'
      : 'scc--boxdetail--container'

    const { box, streams } = this.props

    return (
      <div className={containerClassNames}>
        <NavLink to="/boxes" className="scc--close-button">
          <CloseOutline24 className="scc--fill-green" />
        </NavLink>
        <div className="scc--deviceconfig-container">
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-sm-16 bx--col-md-16 bx--col-lg-16 scc--device-config--triggers scc--flex--column">
                {this.props.box && (
                  <>
                    <BoxDetailsHeader box={box} />
                    <StreamConfigurationContainer box={box} streams={streams} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    box: state.boxes.byIds[ownProps.match.params.id],
    boxDetails: state.boxDetails,
    streams: state.streams,
    selectedEventTrigger: state.eventTriggers.selectedEventTrigger,
    highlightedEventTrigger: state.eventTriggers.highlightedEventTrigger
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadBoxDetails,
    loadBoxStreams,
    deleteStreamEventTrigger,
    resetBoxDetails,
    resetBoxEventTriggers,
    setSelectedBoxEventTrigger: setSelectedStreamEventTrigger,
    setHighlightedBoxEventTrigger: setHighlightedStreamEventTrigger
  })(withTranslation()(BoxDetailPage))
)
