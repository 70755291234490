import './SceneConfiguration.scss'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { IScene } from '../../../types/scene'
import {
  Button,
  Form,
  FormGroup,
  Select,
  SelectItem,
  TextArea,
  TextInput
} from 'carbon-components-react'
import FormLabelWithTooltip from '../../../components/FormLabelWithToolTip'
import { useForm } from 'react-hook-form'
import { Button as AntdButton, Col, Row } from 'antd'
import { ESolutionType } from '../../../types/solution'
import ParkingConfig from './ParkingConfig'
import { DefaultGroup, IGroup } from '../../../types/group'

interface ISceneConfig {
  scene: IScene
  allGroups: IGroup[]
  onSceneFormSubmit: Function
  onSceneDelete: Function
}

const JOINT_COORDINATES_FORMAT_REGEX = /(-?\d\d*[\\.,]\d*)[\\.,]\s*(-?\d\d*[\\.,]\d*)/
const API_NUMBER_SIZE_LIMIT = 13 // the backend API has an upper bound on decimals, therefore we limit the input

const SceneConfiguration: React.FC<ISceneConfig> = ({
  scene,
  allGroups,
  onSceneFormSubmit,
  onSceneDelete
}) => {
  const { t } = useTranslation()
  const form = useForm()
  const { register, handleSubmit, errors, reset } = form

  useEffect(() => {
    // Reset the form
    reset(scene)
  }, [scene, scene.id, reset])

  const [latitude, setLatitude] = useState(
    scene.location ? scene.location.latitude : ''
  )
  const [longitude, setLongitude] = useState(
    scene.location ? scene.location.longitude : ''
  )
  const [solutionType, setSolutionType] = useState(scene.solutionType)
  const [group, setGroup] = useState(
    scene.group ? scene.group.id : DefaultGroup.id
  )

  let longitudeInput
  const [invalidLatitude, setInvalidLatitude] = useState(false)
  const [invalidLongitude, setInvalidLongitude] = useState(false)

  const onSubmit = (data, event) => {
    event.preventDefault()
    data = data.sceneConfig

    if (invalidLongitude || invalidLatitude) {
      return
    }

    if (data.location && data.location.latitude && data.location.longitude) {
      let latitude = parseFloat(data.location.latitude).toString()
      let longitude = parseFloat(data.location.longitude).toString()
      setLatitude(latitude)
      setLongitude(longitude)
      data.location.latitude = latitude
      data.location.longitude = longitude
    } else {
      delete data.location
      setLatitude('')
      setLongitude('')
    }
    onSceneFormSubmit(data)
  }

  const handleOnDelete = (event) => {
    event.preventDefault()
    onSceneDelete()
  }

  let testAndSetLocation = (newValue) => {
    if (JOINT_COORDINATES_FORMAT_REGEX.test(newValue)) {
      let split = newValue.split(',')
      let latitude = split[0].trim()
      let longitude = split[1].trim()
      if (split.length === 4) {
        latitude = [split[0], split[1]].join('.').trim()
        longitude = [split[2], split[3]].join('.').trim()
      }
      testAndSetLatitude(latitude)
      testAndSetLongitude(longitude)
      longitudeInput.focus()
      return true
    }
    return false
  }

  let testAndSetLatitude = (newValue) => {
    newValue = newValue.replace(',', '.')
    let floatValue = parseFloat(newValue)
    if (
      newValue.length > 0 &&
      (isNaN(floatValue) || floatValue <= -90 || floatValue >= 90)
    ) {
      setInvalidLatitude(true)
    } else {
      setInvalidLatitude(false)
    }
    setLatitude(newValue.substring(0, API_NUMBER_SIZE_LIMIT))
  }

  let testAndSetLongitude = (newValue) => {
    newValue = newValue.replace(',', '.')
    let floatValue = parseFloat(newValue)
    if (
      newValue.length > 0 &&
      (isNaN(floatValue) || floatValue <= -180 || floatValue >= 180)
    ) {
      setInvalidLongitude(true)
    } else {
      setInvalidLongitude(false)
    }
    setLongitude(newValue.substring(0, API_NUMBER_SIZE_LIMIT))
  }

  let handleLatitudeChange = (event) => {
    let newValue = event.target.value
    !testAndSetLocation(newValue) && testAndSetLatitude(newValue)
  }

  let handleLongitudeChange = (event) => {
    let newValue = event.target.value
    !testAndSetLocation(newValue) && testAndSetLongitude(newValue)
  }

  let handleSolutionTypeChange = (event) => {
    setSolutionType(event.target.value)
  }
  let handleGroupTypeChange = (event) => {
    setGroup(event.target.value)
  }

  return (
    <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent">
      <Row>
        <Col span={12}>
          <Form onSubmit={handleSubmit(onSubmit)} name="scene-config-form">
            <FormGroup
              legendText={t('solutions.scenes.config.generalGroup')}
              className="scc--icon--legend scc--icon--doves scc--connection"
            >
              <FormLabelWithTooltip id="solutions.scenes.config.formName" />
              <TextInput
                id={'solutions.scenes.config.name'}
                labelText={false}
                invalid={errors['sceneConfig.name'] !== undefined}
                placeholder={t('solutions.scenes.config.formName.placeholder')}
                name="sceneConfig.name"
                defaultValue={scene.name}
                ref={register({ required: true })}
              />
              <FormLabelWithTooltip id="solutions.scenes.config.formSolutionType" />
              <Select
                id={'solutions.scenes.config.solution'}
                className="scc--formfield__value-set"
                labelText={false}
                invalid={errors['sceneConfig.solutionType'] !== undefined}
                name="sceneConfig.solutionType"
                ref={register({ required: true })}
                value={solutionType}
                onChange={handleSolutionTypeChange}
                disabled={!scene.new}
              >
                {Object.values(ESolutionType).map((item) => (
                  <SelectItem
                    key={item}
                    value={item}
                    text={t(
                      `solutions.scenes.config.formSolutionType.values.${item.toString()}`
                    )}
                  />
                ))}
              </Select>
              <FormLabelWithTooltip id="solutions.scenes.config.formDescription" />
              <TextArea
                id={'solutions.scenes.config.description'}
                className="bx--text-input"
                labelText={false}
                placeholder={t(
                  'solutions.scenes.config.formDescription.placeholder'
                )}
                name="sceneConfig.description"
                defaultValue={scene.description}
                ref={register({ required: false })}
                maxLength={255}
              />
              <FormLabelWithTooltip id="solutions.scenes.config.formGroup" />
              <Select
                id={'solutions.scenes.config.group'}
                className="scc--formfield__value-set"
                labelText={false}
                name="sceneConfig.group.id"
                ref={register({ required: false })}
                value={group}
                onChange={handleGroupTypeChange}
              >
                {Object.values([DefaultGroup, ...allGroups]).map((item) => (
                  <SelectItem key={item.id} value={item.id} text={item.name} />
                ))}
              </Select>
              <FormLabelWithTooltip id="solutions.scenes.config.formLocation" />
              <div className="bx--row">
                <div className="bx--col-lg-8">
                  <TextInput
                    id={'solutions.scenes.config.location.lat'}
                    labelText={false}
                    placeholder={t(
                      'solutions.scenes.config.formLocation.placeholder'
                    )}
                    name="sceneConfig.location.latitude"
                    value={latitude}
                    onChange={handleLatitudeChange}
                    ref={register({ required: false })}
                    helperText={t(
                      'solutions.scenes.config.formLocation.latitude'
                    )}
                    invalid={invalidLatitude}
                  />
                </div>
                <div className="bx--col-lg-8">
                  <TextInput
                    id={'solutions.scenes.config.location.lng'}
                    labelText={false}
                    placeholder={t(
                      'solutions.scenes.config.formLocation.placeholder'
                    )}
                    name="sceneConfig.location.longitude"
                    value={longitude}
                    onChange={handleLongitudeChange}
                    ref={(e) => {
                      register(e, { required: false })
                      longitudeInput = e
                    }}
                    helperText={t(
                      'solutions.scenes.config.formLocation.longitude'
                    )}
                    invalid={invalidLongitude}
                  />
                </div>
              </div>
              <div className="bx--row scc--solutions-scene-timezone">
                <div className="bx--col-lg-16">
                  <FormLabelWithTooltip id="solutions.scenes.config.formTimezone" />
                </div>
                <div className="bx--col-lg-8">
                  <TextInput
                    id={'solutions.scenes.config.timezone'}
                    labelText={false}
                    invalid={errors['sceneConfig.name'] !== undefined}
                    placeholder={t(
                      'solutions.scenes.config.formTimezone.placeholder'
                    )}
                    name="sceneConfig.timezone"
                    value={
                      scene.location &&
                      scene.location.timezone &&
                      scene.location.timezone.length > 0
                        ? scene.location.timezone
                        : t('solutions.scenes.config.formTimezone.default')
                    }
                    disabled
                  />
                </div>
              </div>
            </FormGroup>
            {solutionType === ESolutionType.PARKING && (
              <ParkingConfig scene={scene} parentForm={form} />
            )}
            <Button className="scc--solutions--button" type="submit">
              {t('solutions.scenes.config.saveButton')}
            </Button>
            {!scene.new ? (
              <AntdButton
                className="scc--solutions--button scc--solutions--delete-scene-button"
                size="large"
                onClick={handleOnDelete}
              >
                {t('solutions.scenes.config.deleteButton')}
              </AntdButton>
            ) : (
              <AntdButton
                className="scc--solutions--button scc--solutions--cancel-scene-button"
                size="large"
                href="/solutions"
              >
                {t('solutions.scenes.config.cancelButton')}
              </AntdButton>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default SceneConfiguration
