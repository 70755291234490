import './SceneOverview.scss'
import React, { useReducer, useState } from 'react'

import { IScene } from '../../../types/scene'
import { ApolloProvider } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import cubejs, { CubejsApi } from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import { Config } from '../../../services/config'
import { getIdToken } from '../../../services/ApiTokenProvider'
import apollo from '../../../datadiscovery/graphql/client'
import DashboardPage from '../../../datadiscovery/pages/DashboardPage'
import { Button, Col, Layout, Row, Tooltip } from 'antd'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { FullscreenOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import TimeGroup from '../../../datadiscovery/components/QueryBuilder/TimeGroup'
import CreateWidgetModal from './CreateWidgetModal'
import { IDashboardItem } from '../../../types/dashboard'
import Scrollbars from 'react-custom-scrollbars-2'

interface ISceneOverview {
  scene: IScene
}

const API_URL = Config.DATA_API

export const getApiUrl = () => {
  return `${API_URL}/cubejs-api/v1`
}

const cubejsApi: CubejsApi = cubejs(async () => await getIdToken(), {
  apiUrl: getApiUrl()
})

const client = apollo()

const LOCAL_STORAGE_TIME_KEY = 'globalTimeFilter'
const TIME_FILTER_DEFAULT = 'from 6 days ago to now'

const SceneOverview: React.FC<ISceneOverview> = (props) => {
  const { t } = useTranslation()
  const handle = useFullScreenHandle()

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [widget, setWidget] = useState<IDashboardItem | undefined>()

  let timeFilterFromStorage: string | string[] | null = localStorage.getItem(
    `${LOCAL_STORAGE_TIME_KEY}_${props.scene?.id}`
  )
  if (timeFilterFromStorage && timeFilterFromStorage.split(',').length === 2) {
    // is custom date range
    timeFilterFromStorage = timeFilterFromStorage.split(',')
  }

  const [timeFilter, setTimeFilter] = useState(
    timeFilterFromStorage || TIME_FILTER_DEFAULT
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, rerenderCallback] = useReducer((x) => x + 1, 0) // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate

  const updateMethods = {
    update: (member, newValue) => {
      localStorage.setItem(
        `${LOCAL_STORAGE_TIME_KEY}_${props.scene.id}`,
        newValue.dateRange
      )
      setTimeFilter(newValue.dateRange)
    }
  }

  const onWidgetAdd = (event) => {
    event.preventDefault()
    showModal()
  }

  const showModal = (item?) => {
    setWidget(item)
    setShowCreateModal(true)
  }

  if (!props.scene.boxStreams || !props.scene.boxStreams.length) {
    return (
      <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent scc--scenedetail--emptyoverview">
        <p>{t('solutions.scenes.streams.empty')}</p>
        <Link to="#streams">
          {t('solutions.scenes.overview.emptyStreamsLink')}
        </Link>
      </div>
    )
  }

  return (
    <>
      <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent">
        <Row>
          <Col flex="auto" className="scc--flex--column">
            <div className="scc--solutions--overview-timebox">
              <TimeGroup
                members={[
                  {
                    dateRange: timeFilter
                  }
                ]}
                availableMembers={[]}
                addMemberName={null}
                updateMethods={updateMethods}
                showGranularities={false}
                timezone={props.scene.location?.timezone}
              />
            </div>
          </Col>
          <Col flex="100px">
            <Button
              className="scc--solutions--add-widget-button"
              type="primary"
              onClick={onWidgetAdd}
            >
              {t('solutions.scenes.overview.add.button')}
            </Button>
          </Col>
          <Col flex="50px">
            <Tooltip
              placement="bottomLeft"
              title={t('solutions.scenes.overview.tooltipFullscreen')}
            >
              <FullscreenOutlined
                className="scc--solutions--scenedetail--fullscreen"
                onClick={handle.enter}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>

      <CubeProvider cubejsApi={cubejsApi}>
        <ApolloProvider client={client}>
          <FullScreen handle={handle}>
            {handle.active ? ( // in fullscreen mode, we need to add scrollbars here
              <Scrollbars autoHide={true}>
                {getDashboard(props, timeFilter, showModal)}
              </Scrollbars>
            ) : (
              getDashboard(props, timeFilter, showModal)
            )}
          </FullScreen>
          <CreateWidgetModal
            key={widget ? widget!.id : Math.random()}
            show={showCreateModal}
            widget={widget}
            setWidget={setWidget}
            setShow={setShowCreateModal}
            scene={props.scene}
            timeFilter={timeFilter}
            rerenderCallback={rerenderCallback}
          />
        </ApolloProvider>
      </CubeProvider>
    </>
  )
}

const getDashboard = (props, timeFilter, showModal) => {
  return (
    <Layout style={{ minHeight: '100vh', height: 'auto' }}>
      {props.scene && (
        <DashboardPage
          scene={props.scene}
          overrideTimeRange={timeFilter}
          showModal={showModal}
        />
      )}
    </Layout>
  )
}

export default SceneOverview
