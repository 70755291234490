import React, { useState } from 'react'
import { Alert, Button, Empty, Result } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import {
  GET_DASHBOARD_ITEMS,
  GET_DASHBOARD_ITEMS_SCENE
} from '../graphql/queries'
import ChartRenderer from '../components/ChartRenderer'
import Dashboard from '../components/Dashboard'
import DashboardItem from '../components/DashboardItem'
import { BugOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { log } from '../../services/log'
import EasyAPICallModal from '../components/EasyAPICallModal'
import LoadingAnimation from '../../components/LoadingAnimation'
import { IScene } from '../../types/scene'

const deserializeItem = (i) => {
  let layout
  try {
    layout = JSON.parse(i.layout)
  } catch (e) {
    layout = {}
  }
  let visState
  try {
    visState = JSON.parse(i.vizState)
  } catch (e) {
    log.error(e)
    return
  }
  return {
    ...i,
    layout: layout || {},
    vizState: visState
  }
}

const determineSize = (
  persistedSize: number | undefined,
  minSize: number
): number => {
  if (persistedSize && persistedSize > minSize) {
    return persistedSize
  }
  return minSize
}

const defaultLayout = (i) => {
  if (i.vizState.chartType === 'table') {
    return {
      x: determineSize(i.layout.x, 0),
      y: determineSize(i.layout.y, 0),
      w: determineSize(i.layout.w, 6),
      h: determineSize(i.layout.h, 14),
      minW: 6,
      minH: 14
    }
  } else if (i.vizState.chartType === 'number') {
    return {
      x: determineSize(i.layout.x, 0),
      y: determineSize(i.layout.y, 0),
      w: determineSize(i.layout.w, 6),
      h: determineSize(i.layout.h, 4),
      minW: 6,
      minH: 4
    }
  } else if (i.vizState.chartType === 'occupancymap') {
    return {
      x: determineSize(i.layout.x, 0),
      y: determineSize(i.layout.y, 0),
      w: determineSize(i.layout.w, 9),
      h: determineSize(i.layout.h, 9),
      minW: 9,
      minH: 9
    }
  } else {
    return {
      x: determineSize(i.layout.x, 0),
      y: determineSize(i.layout.y, 0),
      w: determineSize(i.layout.w, 6),
      h: determineSize(i.layout.h, 9),
      minW: 6,
      minH: 9
    }
  }
}

interface IDashboardPage {
  scene?: IScene
  overrideTimeRange?: any
  showModal?: any
}

const DashboardPage: React.FC<IDashboardPage> = (props) => {
  const { t } = useTranslation(['datadiscovery'])
  const history = useHistory()
  const query = props.scene ? GET_DASHBOARD_ITEMS_SCENE : GET_DASHBOARD_ITEMS
  const options = props.scene
    ? {
        variables: {
          sceneId: props.scene!.id
        },
        skip: !props.scene.id
      }
    : {}
  const { loading, error, data } = useQuery(query, options)
  const [modalState, setModalState] = useState(-1)

  if (loading) {
    return <LoadingAnimation />
  }

  if (error) {
    return (
      <Alert
        message="Error occured while loading your query"
        description={error.toString()}
        type="error"
      />
    )
  }

  const ErrorFallback = () => {
    return (
      <Result
        title="Could not load widget"
        icon={<BugOutlined />}
        subTitle="Sorry, something went wrong."
      />
    )
  }

  const dashboardItem = (item) => (
    <div key={item.id} data-grid={defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        item={item}
        title={item.name}
        showModalById={setModalState}
        showModal={props.showModal}
        sceneId={props.scene?.id}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ChartRenderer
            vizState={item.vizState}
            overrideTimeRange={props.overrideTimeRange}
            overrideTimeZone={props.scene?.location?.timezone}
            widgetTitle={item.name}
          />
          <EasyAPICallModal
            vizState={item.vizState}
            itemId={item.id}
            showState={[modalState, setModalState]}
          />
        </ErrorBoundary>
      </DashboardItem>
    </div>
  )

  const NoContent = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12
      }}
    >
      <Empty description={<p>{t('explore.emptycanvas')}</p>}>
        <Button
          type="primary"
          size="large"
          onClick={
            props.showModal
              ? (event) => {
                  event.preventDefault()
                  props.showModal()
                }
              : (event) => {
                  event.preventDefault()
                  history.push('/data/explore')
                }
          }
        >
          {t('explore.addwidget')}
        </Button>
      </Empty>
    </div>
  )

  const dashboardItems =
    (data && data.dashboardItems) || (data && data.dashboardItemsForScene)

  return !data || !dashboardItems || dashboardItems.length ? (
    <Dashboard
      dashboardItems={dashboardItems}
      modalActive={modalState !== -1}
      sceneId={props.scene?.id}
    >
      {dashboardItems
        .map(deserializeItem)
        .filter((item) => item !== undefined)
        .map(dashboardItem)}
    </Dashboard>
  ) : (
    <NoContent />
  )
}

export default DashboardPage
