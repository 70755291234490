import './DrawContextTable.scss'

import { Delete16, Edit16 } from '@carbon/icons-react'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import React from 'react'

import Spacer from '../Spacer'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { CopyOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import { Button } from 'antd'

interface IDrawContextTableProps {
  eventTriggers: ICoordinateBasedEventTrigger[]
  selectedEventTrigger: string
  onClickRow: (IEventTrigger) => void
  onMouseEnterRow: (MouseEvent, IEventTrigger) => void
  onMouseLeave: (MouseEvent) => void
  onDelete: (IEventTrigger) => void
  emptyTableText: string
}

const DrawContextTable: React.FC<IDrawContextTableProps> = ({
  eventTriggers,
  selectedEventTrigger,
  onClickRow,
  onDelete,
  onMouseEnterRow,
  onMouseLeave,
  children,
  emptyTableText
}) => {
  const { t } = useTranslation()
  const headers = [
    { key: 'name', header: t('draw.dataTable.headers.name') },
    { key: 'type', header: t('draw.dataTable.headers.type') },
    { key: 'uuid', header: t('draw.dataTable.headers.uuid') },
    { key: 'action', header: t('draw.dataTable.headers.action') }
  ]
  eventTriggers = eventTriggers.filter((e) =>
    [
      EEventTriggerType.crossingLine,
      EEventTriggerType.regionOfInterest,
      EEventTriggerType.virtualDoor,
      EEventTriggerType.originDestinationZone
    ].includes(e.objectType)
  )

  if (process.env.NODE_ENV === 'development') {
    headers.splice(1, 0, { key: 'coords', header: 'Coordinates' })
  }

  return (
    <Scrollbars autoHide={true}>
      <div className="scc--boxcontextual--table">
        <table
          className="bx--data-table bx--data-table--no-border scc--draw--table"
          onMouseLeave={onMouseLeave}
        >
          <TableHeader headers={headers} />
          <tbody>
            {!eventTriggers ||
              (eventTriggers.length === 0 && (
                <tr>
                  <td colSpan={headers.length}>{emptyTableText}</td>
                </tr>
              ))}

            {eventTriggers &&
              eventTriggers.map((eventTrigger, index) => (
                <tr
                  key={`${eventTrigger.localId}_${index}`}
                  className={
                    eventTrigger.localId === selectedEventTrigger
                      ? 'scc--selected'
                      : undefined
                  }
                  id={eventTrigger.localId}
                  onClick={(event) => {
                    onClickRow(eventTrigger)
                  }}
                  onMouseEnter={(event) => {
                    onMouseEnterRow(event, eventTrigger)
                  }}
                >
                  <td>
                    <div className="scc--nowrap">
                      {eventTrigger.name ||
                        t('draw.dataTable.data.name.undefined') +
                          ' ' +
                          eventTrigger.typeShort}
                    </div>
                  </td>
                  {process.env.NODE_ENV === 'development' && (
                    <td>
                      {eventTrigger.coordinates.map((c, i) => {
                        return (
                          <div className="scc--centered" key={i}>
                            <div>
                              x{i + 1}: {c.x.toFixed(3)}
                            </div>
                            <div>
                              y{i + 1}: {c.y.toFixed(3)}
                            </div>
                          </div>
                        )
                      })}
                    </td>
                  )}
                  <td>
                    <div className="scc--centered">
                      {EventTriggerIcon({
                        eventTrigger,
                        t
                      })}
                    </div>
                  </td>
                  <td>
                    <div className="scc--centered scc--nowrap">
                      {eventTrigger.id && (
                        <Button
                          icon={<CopyOutlined />}
                          className="scc--boxcontextual--table--copyid-button"
                          onClick={() => {
                            copy(eventTrigger.id)
                          }}
                        />
                      )}
                      <span className="scc--boxcontextual--triggerId">
                        {eventTrigger.id}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="scc--centered">
                      <button
                        className="scc--table--action"
                        title={t('draw.dataTable.actions.edit')}
                      >
                        <Edit16 />
                      </button>
                      <Spacer width={10} />
                      <button
                        className="scc--table--action"
                        title={t('draw.dataTable.actions.delete')}
                        onClick={(event) => {
                          event.stopPropagation()
                          onDelete(eventTrigger)
                        }}
                      >
                        <Delete16 />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {children}
    </Scrollbars>
  )
}

const EventTriggerIcon = ({ eventTrigger, t }) => {
  const type = eventTrigger.typeShort

  return (
    <i
      className={`scc--icon scc--icon--${type.toLowerCase()}`}
      title={t(`draw.icon.${type.toLowerCase()}`)}
    >
      {type}
    </i>
  )
}

const TableHeader = ({ headers }) => (
  <thead>
    <tr>
      {headers.map((header, index) => {
        let className = 'scc--centered'
        if (index === 0) {
          className = ''
        } else if (header.key === 'type' || header.key === 'action') {
          className += ' bx--table-header-width100'
        }
        return (
          <th key={header.key} scope="col" className={className}>
            <span className="bx--table-header-label">{header.header}</span>
          </th>
        )
      })}
    </tr>
  </thead>
)

export default DrawContextTable
